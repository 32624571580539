<template>
  <div>
    <v-icon v-if="active" color="green">mdi-check</v-icon>
    <v-icon v-else color="red">mdi-close</v-icon>
  </div>
</template>

<script>
export default {
  name: 'BooleanLabel',
  data() {
    return {};
  },
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {},
};
</script>
