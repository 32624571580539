<template>
  <v-row class="main-row-study_user">
    <v-col col="12" class="main-col">
      <h2>Users</h2>
      <p>Select all the users that have access to this study</p>
      <div>
        <v-data-table
        :headers="headers"
        :items="studyUsers"
        disable-pagination
        hide-default-footer>
          <template v-slot:item.first_name="{ item }">
            <div>
              {{ item.first_name }}, {{ item.last_name }}
            </div>
          </template>
          <template v-slot:item.is_selected="{ item }">
            <div>
              <v-checkbox
                v-model="item.is_selected"
              ></v-checkbox>
            </div>
          </template>
        </v-data-table>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { fileServices } from '@/services/fileServices';

export default {
  name: 'StudyUser',
  props: {
    studyId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  components: {
  },
  data() {
    return {
      headers: [
        { text: 'Name', value: 'first_name' },
        { text: 'Email', value: 'email' },
        { text: 'Role', value: 'role' },
        { text: 'Is selected', value: 'is_selected' },
      ],
      studyUsers: [],
    };
  },
  mounted() {
    this.getStudyUserList();
  },
  methods: {
    getStudyUserList() {
      this.studyUsers = [];
      fileServices.getStudyUserList(this.studyId === undefined ? null : this.studyId)
        .then((response) => { this.studyUsers = response.data; })
        .catch((error) => { console.log('error getStudyUserList', error); });
    },
    /** Return only the selected users */
    filterUsersSelected() {
      return this.studyUsers.filter((usr) => usr.is_selected === true);
    },
  },
  computed: {},
};
</script>
