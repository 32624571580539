import { coreServices } from '@/services/coreServices';

export const dashboardServices = {
  getDataKpi(studyId) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/dashboards/get-data-kpi/`;
    const params = { studyId: studyId };
    return coreServices.get(url, coreServices.getRequestHeader(), params);
  },
  /**
   * Get values for the KPI cards
   * @param {object} params Filters for the KPI cards
   * @return axios object
   */
  getKpiDashboardValues(params) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/dashboards/kpis/`;
    return coreServices.get(url, coreServices.getRequestHeader(), params);
  },
  getDataDashboard(params) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/dashboards/data-dashboard/`;
    return coreServices.get(url, coreServices.getRequestHeader(), params);
  },
};
