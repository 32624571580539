<template>
  <div class="main-container">
    <app-header />
    <nav-bar />
    <div class="content">
      <overlay-loader :loading="loading"></overlay-loader>
      <div>
        <v-alert v-if="alertSuccess" class="mt-4" type="success">Successfully created</v-alert>
        <v-alert v-if="alert" type="error" transition="scale-transition">{{error}}</v-alert>
        <v-card>
          <v-toolbar class="mt-12 mb-12" color="primary" light extended>
            <v-container class="mt-6">
              <v-row>
                <v-col cols="12" md="2">
                  <span style="font-size: 20px;" class="white--text">Studies</span>
                </v-col>
                <v-col cols="7" md="7">
                  <v-text-field
                    color="white"
                    dark
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    hide-details
                    underlined>
                  </v-text-field>
                </v-col>
                <v-col cols="5" md="3">
                  <v-btn block outlined rounded class="white--text" style="width: 100%;" @click="exportToXls">
                    <v-icon>mdi-cloud-download</v-icon>
                    <span class="btn-span-style">Download table</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <template v-slot:extension>
              <v-btn
                class="btn-style"
                fab
                color="white"
                bottom
                right
                absolute
                @click="createStudyDialog = true"
                v-if="roleManager.isAnyAdmin()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
          </v-toolbar>
          <v-data-table :headers="headers" :items="studies" :search="search">
            <template v-slot:item.country_names="{ item }">
              <span>{{ item.country_names.replaceAll(',', ', ') }}</span>
            </template>
            <template v-slot:item.site_names="{ item }">
              <span>{{ item.site_names.replaceAll(',', ', ') }}</span>
            </template>
            <template v-slot:item.name="{ item }">
              <div>
                <v-icon
                  class="edit-study-icon"
                  x-large
                  color="yellow darken-2"
                  v-model="updateStudyDialog"
                  @click="updateDialog(item.id)"
                  v-if="roleManager.isAnyAdmin()">
                  mdi-pencil
                </v-icon>
                <v-icon
                  class="mr-2 delete-study-icon"
                  x-large
                  color="red"
                  v-model="deleteStudyDialog"
                  @click="deleteDialog(item.id)"
                  :disabled="!item.is_deletable"
                  v-if="roleManager.isAnyAdmin()">
                  mdi-delete
                </v-icon>
                <router-link :to="'/studies/'+ item.id">{{ item.name }}</router-link>
                <router-view/>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </div>
        <v-dialog v-model="createStudyDialog" max-width="900" v-show="roleManager.isAnyAdmin()">
          <create-study
          v-show="createStudyDialog === true"
          @cancel="createStudyDialog = false"
          @confirm="createStudyDialog = false"
          @startLoading="loading = true"
          @endLoading="loading = false"
          @newStudyCreate="newStudiesList"
          @errorStudyCreate="showErrorAlert"/>
        </v-dialog>
        <v-dialog v-model="updateStudyDialog" max-width="900" :retain-focus="false" v-if="roleManager.isAnyAdmin()">
          <v-card class="dialog-style">
            <v-card-title>
              <span class="text-h5">Study Config</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="studyToUpdate.name" label="Study Name*" underlined required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      v-model="studyToUpdate.countries"
                      :items="studyCountries"
                      item-text="name"
                      return-object
                      label="Study country*"
                      required
                      underlined
                      multiple></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      v-model="studyToUpdate.sites"
                      return-object
                      :items="studySiteList"
                      item-text="name"
                      underlined
                      label="Study site*"
                      multiple
                      required></v-autocomplete>
                  </v-col>
                </v-row>
                <study-user :studyId="studyToUpdate.id" ref="studyUserComp" :key="studyToUpdate.id"></study-user>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined rounded @click="updateStudyDialog=false" color="error">Cancel</v-btn>
              <v-btn outlined rounded :disabled="updateStudyFormValid" @click="updateStudy()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteStudyDialog" max-width="900" v-if="roleManager.isAnyAdmin()">
          <v-card>
            <v-card-title class="headline">Are you sure to delete study: "{{ studyModel.name }}"?</v-card-title>
            <v-card-text>
              <v-text-field
                v-model="delete_study_comment"
                label="Add a commentary"
                underlined
                clearable></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined rounded @click="deleteStudyDialog=false" color="error">Cancel</v-btn>
              <v-btn outlined rounded @click="deleteStudy(studyModel.id)" color="primary">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
  </div>
</template>
<script>
import ExcelManager from '@/store/ExcelManager';
import OverlayLoader from '@/components/generic/OverlayLoader.vue';
import StudyUser from '@/components/study/StudyUser.vue';
import AppHeader from '@/components/generic/AppHeader.vue';
import NavBar from '@/components/generic/NavBar.vue';
import { fileServices } from '../services/fileServices';
import CreateStudy from '@/components/study/CreateStudy.vue';
import { roleManager } from '@/store/roleManager';

export default {
  name: 'Study',
  components: {
    AppHeader,
    NavBar,
    CreateStudy,
    OverlayLoader,
    StudyUser,
  },
  data() {
    return {
      roleManager,
      error: '',
      loading: false,
      alert: false,
      alertSuccess: false,
      createStudyDialog: false,
      search: '',
      studies: [],
      updateStudyDialog: false,
      deleteStudyDialog: false,
      studyModel: '',
      studyCountryModel: [],
      studySiteModel: '',
      studyToUpdate: {
        id: '',
        name: '',
        countries: [],
        country_names: '',
        sites: [],
        sites_name: '',
      },
      studySites: [],
      studyCountries: [],
      delete_study_comment: null,
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    updateStudyFormValid() {
      return !(
        'name' in this.studyToUpdate
        && this.studyToUpdate.name !== ''
        && this.studyToUpdate.name !== null
        && 'sites' in this.studyToUpdate
        && this.studyToUpdate.sites !== ''
        && this.studyToUpdate.sites !== null
        && 'countries' in this.studyToUpdate
        && this.studyToUpdate.countries !== ''
        && this.studyToUpdate.countries !== null
        && this.studyToUpdate.countries.length > 0
      );
    },
    headers() {
      const items = [
        { text: ' ', value: 'actions', sortable: false },
        { text: 'Study', value: 'name' },
        { text: 'Study countries', value: 'country_names' },
        { text: 'Study sites', value: 'site_names' },
      ];
      if (roleManager.isStaff()) {
        items.push({ text: 'Licence', value: 'licence.name' });
      }
      return items;
    },
    studySiteList() {
      if (!this.studyToUpdate.countries) return [];
      const countryIds = this.studyToUpdate.countries.map((item) => item.id);
      return this.studySites.filter(
        (item) => countryIds.includes(item.study_country.id) && item.study.id === this.studyToUpdate.id,
      );
    },
  },
  methods: {
    /** Show error alert with a message */
    showErrorAlert(msg) {
      this.alert = true;
      this.loading = false;
      this.error = msg;
      setTimeout(() => {
        this.alert = false;
      }, 3000);
    },
    getData() {
      this.loading = true;
      // get all the sites and all the countries
      fileServices.getStudyList()
        .then((response) => { this.studies = response.data; this.loading = false; })
        .catch((error) => { console.log('error study list:', error); this.loading = false; });
      fileServices.getStudyCountryList()
        .then((response) => { this.studyCountries = response.data; })
        .catch((error) => { console.log('error study country:', error); });
      fileServices.getStudySites(null, null)
        .then((response) => { this.studySites = response.data; })
        .catch((error) => { console.log('error study sites:', error); });
    },
    newStudiesList(newStudyCreate) {
      this.loading = true;
      this.alertSuccess = true;
      this.studies.push(newStudyCreate);
      this.loading = false;
      this.alertSuccess = false;
    },
    exportToXls() {
      this.loading = true;
      const data = this.studies.map((item) => {
        // eslint-disable-next-line
        item.site_name = item.site ? item.site.name : '';
        return item;
      });
      const cols = [
        { header: 'country_names', key: 'country_names', width: 20 },
        { header: 'name', key: 'name', width: 20 },
        { header: 'site_name', key: 'site_name', width: 20 },
      ];
      ExcelManager.exportExcel(data, cols, 'Studies.xlsx');
      this.loading = false;
    },
    updateDialog(studyId) {
      this.updateStudyDialog = true;
      this.studyToUpdate = { ...this.studies.find((study) => study.id === studyId) };
      // get users
      if ('studyUserComp' in this.$refs) {
        // this.$refs.studyUserComp.studyId = this.studyToUpdate.id;
        // this.$refs.studyUserComp.getStudyUserList();
      }
    },
    updateStudy() {
      const data = { ...this.studyToUpdate };
      data.sites = this.studyToUpdate.sites.map((s) => s.id);
      data.licence = this.studyToUpdate.licence.id;
      data.study_country = this.studyToUpdate.countries.map((c) => c.id);
      this.loading = true;
      fileServices.updateStudy(this.studyToUpdate.id, data)
        .then(() => {
          this.alertSuccess = true;
          this.updateStudyDialog = false;
          this.sendStudyUsers(this.studyToUpdate.id);
        }).catch((error) => {
          this.loading = false;
          this.alertSuccess = false;
          const k = Object.keys(error.response.data)[0];
          const msg = error.response.data[k][0];
          this.showErrorAlert(msg);
        });
    },
    /** Return only the selected users */
    filterUsersSelected() {
      return this.$refs.studyUserComp.studyUsers.filter((usr) => usr.is_selected === true);
    },
    /** Send users that have access to the study */
    sendStudyUsers(studyId) {
      const data = {
        users: this.filterUsersSelected().map((s) => s.id),
      };
      fileServices.updateStudyUser(studyId, data).then(() => {
        this.studyToUpdate = {};
        this.loading = false;
        this.alertSuccess = false;
        this.getData();
      }).catch((error) => {
        this.loading = false;
        this.alertSuccess = false;
        console.log(error);
      });
    },
    deleteDialog(studyId) {
      this.deleteStudyDialog = true;
      this.studyModel = { ...this.studies.find((study) => study.id === studyId) };
    },
    deleteStudy(studyId) {
      this.loading = true;
      fileServices.deleteStudy(studyId, this.delete_study_comment)
        .then(() => {
          this.studies = this.studies.filter((study) => study.id !== studyId);
          this.deleteStudyDialog = false;
          this.delete_study_comment = null;
          this.loading = false;
        })
        .catch((error) => { console.log('Delete study error', error); this.loading = false; });
    },
  },
};
</script>
<style scoped>
  .doc{
    margin-left:  50px;
    padding-top: 80px;
    padding-bottom: 40px
  }
  .title_doc{
    margin-top: 20px;
    margin-left: 50px;
  }
  .study-button {
    margin-left: 50px;
    margin-top: 20px;
    border-radius: 20px !important;
  }
  th.text-start {
    font-size: 16px !important;
    color: black !important;
  }
  .btn-span-style {
    font-size: 12px !important;
    padding: 16px;
    border-radius: 20px !important;
  }
  button {
    font: unset !important;
  }
  .v-btn:not(.v-btn--round).v-size--small {
    border-radius: 20px !important;
  }
  .edit-study-icon {
    font-size: 20px !important;
  }
  .delete-study-icon {
    font-size: 20px !important;
  }
</style>
