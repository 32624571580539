import Cookies from 'js-cookie';
import { coreServices } from '@/services/coreServices';

export const accountServices = {
  /*
    Auth
  */
  async Login(data) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/users/login/`;
    return coreServices.post(url, data, '');
  },
  logout() {
    const url = `${process.env.VUE_APP_BASE_URL}/api/users/logout/`;
    return coreServices.post(url, Cookies.get('Authorization'));
  },
  setNewPasswordUpdated(formData) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/users/update-expired-password/`;
    const headers = coreServices.getRequestHeader();
    delete headers.Authorization;
    return coreServices.post(url, formData, headers);
  },
  resetPassword(formData) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/users/forgot-password/`;
    return coreServices.post(url, formData, '');
  },
  setNewPassword(formData) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/users/forgot-password/confirm/`;
    const headers = coreServices.getRequestHeader();
    delete headers.Authorization;
    return coreServices.post(url, formData, headers);
  },
  changePassword(formData) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/users/update-password/`;
    return coreServices.post(url, formData, coreServices.getRequestHeader());
  },
  /*
    Accounts
  */
  /** Get list of users */
  getUsers() {
    const url = `${process.env.VUE_APP_BASE_URL}/api/users/`;
    return coreServices.get(url, coreServices.getRequestHeader(), {});
  },
  /** Create a new user
   * @param {Object} data dictionary of the user
   */
  createUser(data) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/users/`;
    return coreServices.post(url, data, coreServices.getRequestHeader());
  },
  /** Delete user
   * @param {int} userId User id to delete
   */
  deleteUser(userId) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/users/${userId}/`;
    return coreServices.delete(url, coreServices.getRequestHeader());
  },
  /** Update a user
   * @param {Object} data dictionary of the user
   * @param {int} userId User id to update
   */
  updateUser(userId, data) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/users/${userId}/`;
    return coreServices.put(url, data, coreServices.getRequestHeader());
  },
  getUserHistory() {
    const url = `${process.env.VUE_APP_BASE_URL}/api/users/history/`;
    return coreServices.get(url, coreServices.getRequestHeader(), {});
  },
  getUserApprovers(studyId) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/users/approvers/?study_id=${studyId}`;
    return coreServices.get(url, coreServices.getRequestHeader(), {});
  },
  /** Get my user information */
  checkUserData() {
    const url = `${process.env.VUE_APP_BASE_URL}/api/users/me/`;
    return coreServices.get(url, coreServices.getRequestHeader(), {});
  },
  updateUsername(userId, data) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/users/${userId}/update-username/`;
    return coreServices.put(url, data, coreServices.getRequestHeader());
  },
  /*
    Licences
  */
  getMyLicence() {
    const url = `${process.env.VUE_APP_BASE_URL}/api/users/licences/me/`;
    return coreServices.get(url, coreServices.getRequestHeader(), {});
  },
  getLicences() {
    const url = `${process.env.VUE_APP_BASE_URL}/api/users/licences/`;
    return coreServices.get(url, coreServices.getRequestHeader(), {});
  },
  createNewLicence(data) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/users/licences/`;
    return coreServices.post(url, data, coreServices.getRequestHeader());
  },
  deleteLicence(licenceId) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/users/licences/${licenceId}/`;
    return coreServices.delete(url, coreServices.getRequestHeader());
  },
  updateLicence(licenceId, data) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/users/licences/${licenceId}/`;
    return coreServices.put(url, data, coreServices.getRequestHeader());
  },
  /*
    Account-App documentation
  */
  getDocumentationProfile() {
    const url = `${process.env.VUE_APP_BASE_URL}/api/users/documentation/`;
    return coreServices.get(url, coreServices.getRequestHeader(), {});
  },
  getDocumentationProfileUrl(documentId) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/users/documentation/${documentId}/url/`;
    return coreServices.get(url, coreServices.getRequestHeader(), {});
  },
};
