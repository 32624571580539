<template>
    <div class="main-container">
        <app-header />
        <nav-bar />
        <div class="content">
          <overlay-loader :loading="loading"></overlay-loader>
          <v-card>
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="2" class="pt-5"><span style="font-size: 20px;">Documents / Studies</span></v-col>
                  <v-col cols="12" md="4">
                    <study-filter
                    v-if="documentos.length > 0"
                    :studyNameList="documentos"
                    @selectedStudy="sendStudyValueToParent"
                    ></study-filter>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    underlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" class="pt-5">
                    <v-btn block outlined rounded style="width: 100%;"  @click="exportToXls">
                      <v-icon>mdi-cloud-download</v-icon>
                      <span class="btn-span-style">Download table</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
              <v-data-table :headers="headers" :items="getDocumentsFiltered" :search="search">
                <template v-slot:item.historic_timestamp_format="{ item }">
                  <span>{{ item.historic_timestamp_format.toLocaleString('es-ES') }}</span>
                </template>
                <template v-slot:item.status_name="{ item }">
                  <data-status-label v-if="item.status_name !== null"
                  :status_id="0" :status_name="item.status_name"></data-status-label>
                </template>
                <template v-slot:item.qc_issue_history="{ item }">
                  {{ issueNumbersToNames(item.qc_issue_history) }}
                </template>
                <template v-slot:item.qc_issue_type="{ item }">
                  {{ issueNumbersToNames(item.qc_issue_type) }}
                </template>
                <template v-slot:item.last_user="{ item }">
                  <span v-if="item.last_user
                  && item.last_user.includes('@anonymize.com')"><i>User deleted</i></span>
                  <span v-else>{{item.last_user}}</span>
                </template>
                <template v-slot:item.approver_user="{ item }">
                  <span v-if="item.approver_user
                  && item.approver_user.includes('@anonymize.com')"><i>User deleted</i></span>
                  <span v-else>{{item.approver_user}}</span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </div>
    </div>
</template>
<script>
import DataStatusLabel from '@/components/generic/DataStatusLabel.vue';
import OverlayLoader from '@/components/generic/OverlayLoader.vue';
import AppHeader from '@/components/generic/AppHeader.vue';
import NavBar from '@/components/generic/NavBar.vue';
import StudyFilter from '../components/StudyFilter.vue';
import { Constants } from '../store/constants';
import { MainIssues } from '@/utils/parseIssues';
import { fileServices } from '../services/fileServices';
import ExcelManager from '@/store/ExcelManager';

export default {
  name: 'AuditTrial',
  components: {
    AppHeader,
    NavBar,
    OverlayLoader,
    StudyFilter,
    DataStatusLabel,
  },
  data() {
    return {
      Constants,
      MainIssues,
      search: '',
      loading: false,
      headers: [
        { text: 'File ID', value: 'data' },
        { text: 'File name', value: 'title' },
        { text: 'Study', value: 'study_name' },
        { text: 'Event description', value: 'event_description' },
        { text: 'Status', value: 'status_name' },
        { text: 'Modifier', value: 'last_user' },
        { text: 'Approver', value: 'approver_user' },
        { text: 'QC issue history', value: 'qc_issue_history' },
        { text: 'QC issue type', value: 'qc_issue_type' },
        { text: 'Comments', value: 'commentary' },
        { text: 'Last modification', value: 'historic_timestamp_format' },
      ],
      documentos: [],
      selectedStudy: '',
    };
  },
  created() {
    this.loading = true;
  },
  mounted() {
    this.getHistoric();
  },
  computed: {
    getDocumentsFiltered() {
      if (this.selectedStudy.length > 0) {
        const documentsFilteredWithStudy = this.documentos.filter(
          (document) => this.selectedStudy.includes(document.study_name),
        );
        return documentsFilteredWithStudy;
      }
      return this.documentos;
    },
  },
  methods: {
    sendStudyValueToParent(selectedStudy) {
      this.selectedStudy = selectedStudy;
    },
    getHistoric() {
      fileServices.getHistoric(-1)
        .then((response) => {
          this.documentos = response.data.map((s) => (
            {
              historic_timestamp_format: new Date(s.historic_timestamp.toLocaleString('es-ES', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })),
              ...s,
            }
          ));
          this.documentos.sort((a, b) => !a.historic_timestamp_format - b.historic_timestamp_format);
          this.loading = false;
        })
        .catch((error) => {
          console.log('error folder:', error);
          this.loading = false;
        });
    },
    issueNumbersToNames(issues) {
      if (issues === '' || issues === undefined || issues === null) return '';
      const items = [];
      issues.forEach((element) => {
        items.push(
          // eslint-disable-next-line
          `${element}: ${this.MainIssues.issueNumberToName(parseInt(element))}`,
        );
      });
      return items.join(' ');
    },
    exportToXls() {
      this.loading = true;
      const cols = [
        { header: 'Data ID', key: 'data', width: 30 },
        { header: 'Data name', key: 'title', width: 30 },
        { header: 'Study', key: 'study_name', width: 30 },
        { header: 'Study site', key: 'study_site_name', width: 30 },
        { header: 'Study country', key: 'study_country_name', width: 30 },
        { header: 'Event description', key: 'event_description', width: 30 },
        { header: 'Status', key: 'status_name', width: 30 },
        { header: 'Last_user', key: 'last_user', width: 30 },
        { header: 'Approver', key: 'approver_user', width: 30 },
        { header: 'QI issue history', key: 'qi_issue_history', width: 30 },
        { header: 'QI issue type', key: 'qi_issue_type', width: 30 },
        { header: 'QI issue comments', key: 'qi_issue_comments', width: 30 },
        { header: 'Comments', key: 'commentary', width: 30 },
        { header: 'Timestamp', key: 'timestamp', width: 30 },
      ];
      const data = this.documentos.map((doc) => {
        // eslint-disable-next-line
        doc.qi_issue_history = this.issueNumbersToNames(doc.qc_issue_history);
        // eslint-disable-next-line
        doc.qi_issue_type = this.issueNumbersToNames(doc.qc_issue_type);
        // eslint-disable-next-line
        doc.timestamp = doc.historic_timestamp_format.toLocaleString('es-ES', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
        return doc;
      });
      ExcelManager.exportExcel(data, cols, 'AuditTrial.xlsx');
      this.loading = false;
    },
  },
};
</script>
<style scoped>
  .doc {
    margin-left:  50px;
    padding-top: 80px;
    padding-bottom: 40px;
  }
  .title_doc {
    margin-top: 20px;
    margin-left: 50px;
  }
</style>
