export function getLanguages() {
  return [
    'Afrikaans',
    'Algerian',
    'Arabic',
    'Aramaic',
    'Armenian',
    'Bengali',
    'Berber',
    'Bosnian',
    'Brazilian',
    'Bulgarian',
    'Bulgarian',
    'Burmese',
    'Cantonese',
    'Catalan',
    'Corsica',
    'Creole',
    'Croatian',
    'Cypriot',
    'Czech',
    'Danish',
    'Dutch',
    'Egyptian',
    'English',
    'Esperanto',
    'Estonian',
    'Finn',
    'Finnish',
    'Flemish',
    'French',
    'Georgian',
    'German',
    'Greek',
    'Gypsy',
    'Hawaiian',
    'Hebrew',
    'Hindi',
    'Hungarian',
    'Icelandic',
    'Indonesian',
    'Indonesian',
    'Inuit',
    'Irish',
    'Italian',
    'Japanese',
    'Javanese',
    'Korean',
    'Latin',
    'Lithuanian',
    'Malay',
    'Malayalam',
    'Mandarin',
    'Nepalese',
    'Norwegian',
    'Panjabi',
    'Polish',
    'Portuguese',
    'Romanian',
    'Russian',
    'Sanskrit',
    'Scottish',
    'Serbian',
    'Sign language',
    'Slovak',
    'Slovene',
    'Spanish',
    'Swedish',
    'Tagalog',
    'Tahitian',
    'Tamil',
    'Telugu',
    'Thai',
    'Tibetan',
    'Turkish',
    'Ukrainian',
    'Vietnamese',
    'Welsh',
    'Wu',
  ];
}
