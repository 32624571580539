import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export default {
  name: 'ExcelManager',
  async exportExcel(items, columns, fileName) {
    const options = {
      filename: fileName, // 'streamed-workbook.xlsx',
      useStyles: true,
      useSharedStrings: true,
    };
    if (columns === null) {
      // eslint-disable-next-line
      columns = [];
      if (items.length > 0) {
        Object.keys(items[0]).forEach((item) => {
          columns.push({ header: item, key: item, width: 20 });
        });
      }
    }
    const workbook = new ExcelJS.Workbook(options);
    // workbook.creator = 'LAB';
    // workbook.lastModifiedBy = 'LAB';
    workbook.created = new Date();
    workbook.modified = new Date();
    // workbook.lastPrinted = new Date(2016, 9, 27);

    // Force workbook calculation on load
    // workbook.calcProperties.fullCalcOnLoad = true;

    const worksheet = workbook.addWorksheet('Page');

    // worksheet.autoFilter = 'A1:C1';

    worksheet.columns = columns; /* [
      { header: 'Id', key: 'id', width: 10 },
      { header: 'Name', key: 'name', width: 32 },
      // eslint-disable-next-line
      { header: 'D.O.B.', key: 'DOB', width: 10, outlineLevel: 1 },
    ]; */

    // Add a row by contiguous Array (assign to columns A, B & C)
    // eslint-disable-next-line
    items.forEach((item) => {
      const row = {};
      columns.forEach((col) => {
        row[col.key] = item[col.key];
      });
      worksheet.addRow(row);
    });

    // for (let index = 0; index < items.length; index++) {
    //   const item = {};
    //   columns.forEach((col) => {
    //     item[col.key] = it
    //   });
    //   // eslint-disable-next-line
    //   for (let colIndex = 0; colIndex < columns.length; colIndex++) {
    //     item['']
    //   }
    //   worksheet.addRow([index, 'Tiago', new Date()]);
    // }

    // merge a range of cells
    // worksheet.mergeCells('A4:B5');

    // Insert a row by contiguous Array (assign to columns A, B & C)
    // worksheet.insertRow(1, [3, 'Sam', new Date()]);

    // const fileName = 'sample.xlsx';
    // await workbook.xlsx.write(fileName);
    /* workbook.xlsx.writeFile(fileName).then(() => {
      console.log('WRITE');
    }); */
    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), fileName);
  },
};
