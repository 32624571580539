var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-container"},[_c('app-header'),_c('nav-bar'),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Inbox")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.notifications,"loading":_vm.loadingNotifications,"loading-text":"Loading notifications...","show-select":_vm.selectAvailable,"item-selectable":"selectable","single-select":false,"item-key":"file_id"},scopedSlots:_vm._u([{key:"item.is_urgent",fn:function(ref){
var item = ref.item;
return [_c('boolean-label',{attrs:{"active":item.is_urgent}})]}},{key:"item.status_id",fn:function(ref){
var item = ref.item;
return [_c('data-status-label',{attrs:{"status_id":item.status_id}})]}},{key:"item.doc_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getDate(item.doc_date)))])]}},{key:"item.urgent",fn:function(ref){
var item = ref.item;
return [_c('boolean-label',{attrs:{"active":item.urgent}})]}},{key:"item.file_id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"tab-router-link",attrs:{"to":'/library/edit/'+item.file_id}},[_vm._v(" "+_vm._s(item.file_id)+" ")])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"tab-router-link",attrs:{"to":'/library/edit/'+item.file_id}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.user_email",fn:function(ref){
var item = ref.item;
return [(item.user_email.includes('@anonymize.com'))?_c('span',[_c('i',[_vm._v("User deleted")])]):_c('span',[_vm._v(_vm._s(item.user_email))])]}},{key:"item.last_historic_doc_last_user_email",fn:function(ref){
var item = ref.item;
return [(item.last_historic_doc_last_user_email
                && item.last_historic_doc_last_user_email.includes('@anonymize.com'))?_c('span',[_c('i',[_vm._v("User deleted")])]):_c('span',[_vm._v(_vm._s(item.last_historic_doc_last_user_email))])]}},{key:"item.approver_user_email",fn:function(ref){
                var item = ref.item;
return [(item.approver_user_email && item.approver_user_email.includes('@anonymize.com'))?_c('span',[_c('i',[_vm._v("User deleted")])]):_c('span',[_vm._v(_vm._s(item.approver_user_email))])]}}]),model:{value:(_vm.notificationsSelected),callback:function ($$v) {_vm.notificationsSelected=$$v},expression:"notificationsSelected"}})],1),(_vm.selectAvailable)?_c('v-card-actions',[_c('v-row',[_c('div',{staticClass:"col text-right"},[_c('v-btn',{attrs:{"disabled":_vm.notificationsSelected.length == 0,"outlined":"","rounded":""},on:{"click":function($event){_vm.approverUsersDialog = !_vm.approverUsersDialog}}},[_vm._v("Send to other approver")])],1)])],1):_vm._e()],1)],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","max-width":"900px"},model:{value:(_vm.approverUsersDialog),callback:function ($$v) {_vm.approverUsersDialog=$$v},expression:"approverUsersDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Send for approval")]),_c('v-card-text',[_c('div',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.notificationsSelectedHeaders,"items":_vm.notificationsSelected},scopedSlots:_vm._u([{key:"item.new_approver_user_id",fn:function(ref){
                var item = ref.item;
return [_c('v-autocomplete',{staticClass:"mb-2",attrs:{"items":_vm.approverUsers.filter(
                  function (aitem) { return aitem.id !== item.user_id && aitem.studies.includes(item.study_id); }),"item-text":"email","item-value":"id","hide-details":"","label":"Select approver","underlined":""},model:{value:(item.new_approver_user_id),callback:function ($$v) {_vm.$set(item, "new_approver_user_id", $$v)},expression:"item.new_approver_user_id"}})]}}])})],1),_c('div',[_c('v-text-field',{attrs:{"required":"","label":"Commentary*","underlined":"","clearable":""},model:{value:(_vm.commentary),callback:function ($$v) {_vm.commentary=$$v},expression:"commentary"}})],1)]),_c('v-card-actions',{staticClass:"text-right",staticStyle:{"margin-bottom":"10px"}},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","rounded":"","color":"error"},on:{"click":function($event){_vm.approverUsersDialog=false}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"disabled":_vm.approverUsersInvalid,"outlined":"","rounded":""},on:{"click":function($event){return _vm.passApproveToOther()}}},[_vm._v(" Send ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }