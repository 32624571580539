<template>
  <div class="kpi-component-content">
    <v-container fluid>
      <v-row>
        <v-col>
          <v-select
            v-model="studySelected"
            :items="studies"
            item-text="name"
            item-value="id"
            label="Select study"
            prepend-inner-icon="mdi-filter-variant"
            underlinded
            id="select-dasboard-home"
            class="select-study-dashboard"
            @change="getDataKpi()"
          >
          </v-select>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-card id="card-total-files" class="home-kpi-style text-center">
            <v-row id="subheader-total-files" class="text-center grey white--text font-weight-bold">
              <v-col>Total Files</v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row id="title-total-files" class="text-center">
              <v-col><span class="w-100"><strong>{{ result.total_files }}</strong></span></v-col>
            </v-row>
          </v-card>
          <v-card id="card-files" class="home-kpi-style">
            <v-row id="row-urgent-nonurget"
            class="justify-space-around tfe-secondary-border-color tfe-secondary-bg m-0 text-center">
              <v-col id="subheader-non-urgent-files" class="font-weight-bold white--text">
                Non urgent
              </v-col>
              <v-col id="subheader-urgent-files" class="font-weight-bold white--text">
                Urgent
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="text-center">
              <v-col id="card-title-files-no-urgent">
                <span class="w-100"><strong>{{ result.no_urgent_files }}</strong></span>
              </v-col>
                <v-divider vertical></v-divider>
              <v-col id="card-title-files-urgent">
                <span class="w-100"><strong>{{ result.urgent_files }}</strong></span>
              </v-col>
            </v-row>
          </v-card>
          <v-card id="card-file-approved" class="home-kpi-style text-center">
            <v-row class="justify-space-around tfe-green-border-color tfe-green-bg">
              <v-col id="subheader-non-urgent-approved" class="col font-weight-bold white--text">
                Non urgent
                <br/>
                approved
              </v-col>
              <v-col id="subheader-urgent-approved" class="col font-weight-bold white--text">
                Urgent
                <br>
                approved
              </v-col>
            </v-row>
            <v-row class="text-center">
              <v-col id="title-non-urgent-approved">
                <span class="w-100"><strong>{{ result.approved_files }}</strong></span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col id="title-urgent-approved">
                <span class="w-100"><strong>{{ result.urgent_approved }}</strong></span>
              </v-col>
            </v-row>
          </v-card>
          <v-card id="card-files-rejected" class="home-kpi-style">
            <v-row class="justify-space-around tfe-red-border-color tfe-red-bg text-center">
              <v-col id="subheader-non-urgent-rejected" class="font-weight-bold white--text">
                Non urgent
                <br/>
                rejected
              </v-col>
              <v-col id="subheader-urgent-rejected" class="font-weight-bold white--text">
                Urgent
                <br>
                rejected
              </v-col>
            </v-row>
            <v-row class="text-center">
              <v-col id="title-non-urgent-rejected">
                <span class="w-100"><strong>{{ result.no_approved_files }}</strong></span>
              </v-col>
              <v-divider class="mx-4" vertical></v-divider>
              <v-col id="title-urgent-rejected">
                <span class="w-100"><strong>{{ result.urgent_no_approved }}</strong></span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { dashboardServices } from '@/services/dashboardServices';
import { fileServices } from '@/services/fileServices';

export default {
  name: 'KpiComponent',
  data() {
    return {
      studies: [],
      studySelected: 0,
      result: {},
    };
  },
  created() {
    this.getStudies();
    this.getDataKpi();
  },
  methods: {
    getStudies() {
      fileServices.getStudies().then((response) => { this.studies = response.data; });
    },
    getDataKpi() {
      dashboardServices.getDataKpi(this.studySelected)
        .then((response) => {
          this.result = response.data;
        });
    },
  },
};
</script>
