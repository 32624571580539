<template lang="">
  <div class="edit-div">
    <overlay-loader :loading="loading"></overlay-loader>
    <v-alert v-if="alertSuccess" class="mt-4" type="success">Successfully changed</v-alert>
    <v-alert v-if="alert" class="mt-4" type="error" transition="scale-transition">Error changing password</v-alert>
    <div class="split left tfe-bg-gradient">
      <img
        alt="Tmfy logo"
        src="../assets/img/logo_white.png"
        width="120px"
        class="mx-auto d-block mt-2 mb-6">
      <v-col cols="8" offset-md="2">
        <v-row>
          <v-avatar color="white" class="change-font mx-auto" size="100">
            <v-icon color="secondary" size="50">mdi-email</v-icon>
          </v-avatar>
        </v-row>
        <v-row class="justify-center">
          <span class="set-pass-title">Set new password</span>
        </v-row>
        <v-row class="justify-center">
          <span class="signin-text text-center" style="color:white">
            Your new password must be different to previously used password
          </span>
        </v-row>
        <v-row class="justify-center align-baseline mt-12">
          <v-icon color="white">mdi-account</v-icon>
          <v-text-field
            v-model="email"
            label="Email*"
            type="email"
            color="white"
            dark
            class="form-input pl-3 custom-placeholer-color custom-label-color"
            placeholder="Email"
            underlined
            required>
          </v-text-field>
        </v-row>
        <v-row class="justify-center align-baseline">
          <v-icon color="white">mdi-lock</v-icon>
          <v-text-field
            v-model="newPassword"
            label="New password*"
            type="password"
            color="white"
            dark
            class="form-input pl-3 custom-placeholer-color custom-label-color"
            :rules="[passwordRules]"
            placeholder="Password"
            underlined
            required>
          </v-text-field>
        </v-row>
        <v-row class="justify-center align-baseline">
          <v-icon color="white">mdi-folder-key</v-icon>
          <v-text-field
            v-model="repeatedPassword"
            label="Repeat new password*"
            type="password"
            class="form-input pl-3 custom-placeholer-color custom-label-color"
            placeholder="Password"
            color="white"
            dark
            underlined
            required>
          </v-text-field>
        </v-row>
        <v-row class="justify-center">
          <v-btn
            v-if="!alertSuccess"
            @click="savePassword"
            :disabled="formValid"
            color="white"
            block
            dark
            outlined
            rounded>
            Change my password
          </v-btn>
        </v-row>
      </v-col>
    </div>
    <div class="split right">
      <v-row class="centered">
        <v-col>
          <v-list-item-title class="text-h6 mb-12">Password must contain:</v-list-item-title>
          <v-list-item>
            <v-icon @change="colorLenRules" :color="colorLength">mdi-check-bold</v-icon>{{ lengthRule }}
          </v-list-item>
          <br>
          <v-list-item>
            <v-icon @change="colorUpperRules" :color="colorUpper">mdi-check-bold</v-icon>{{ upperRule }}
            </v-list-item>
            <br>
          <v-list-item>
            <v-icon @change="colorLowerRules" :color="colorLower">mdi-check-bold</v-icon>{{ lowerRule }}
            </v-list-item>
            <br>
          <v-list-item>
            <v-icon @change="colorNumRules" :color="colorNumber">mdi-check-bold</v-icon>{{ numberRule }}
            </v-list-item>
            <br>
          <v-list-item>
            <v-icon @change="colorCharRules" :color="colorCharacter">mdi-check-bold</v-icon>{{ characterRule }}
          </v-list-item>
          <br>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import OverlayLoader from '@/components/generic/OverlayLoader.vue';
import { PasswordChecker } from '@/utils/passwordUtilities';
import { accountServices } from '@/services/accountServices';

export default {
  name: 'ForgotPasswordConfirm',
  components: {
    OverlayLoader,
  },
  data() {
    return {
      PasswordChecker,
      token: '',
      email: '',
      newPassword: '',
      repeatedPassword: '',
      alert: false,
      alertSuccess: false,
      loading: false,
      lengthRule: 'At least 8 characters.',
      upperRule: 'At least 1 upper case letter (A..Z)',
      lowerRule: 'At least 1 lower case letter (a..z)',
      numberRule: 'At least 1 number (0...9)',
      characterRule: 'At least 1 special character (/..-)',
      colorLength: 'grey',
      colorUpper: 'grey',
      colorLower: 'grey',
      colorNumber: 'grey',
      colorCharacter: 'grey',
    };
  },
  created() {
    this.token = this.$route.params.token;
  },
  computed: {
    passwordRules() {
      return this.passwordStrong();
    },
    colorLenRules() {
      return this.passwordLen();
    },
    colorUpperRules() {
      return this.passwordUpper();
    },
    colorLowerRules() {
      return this.passwordLower();
    },
    colorNumRules() {
      return this.passwordNum();
    },
    colorCharRules() {
      return this.passwordChar();
    },
    formValid() {
      const validPassword = this.passwordStrong(this.newPassword);
      return !((validPassword)
    && (this.newPassword === this.repeatedPassword)
    && (this.newPassword !== ''
    && this.repeatedPassword !== ''));
    },
  },
  methods: {
    passwordStrong() {
      return PasswordChecker.passwordStrong(this.newPassword);
    },
    passwordLen() {
      const result = PasswordChecker.passwordLength(this.newPassword);
      this.colorLength = result ? 'green' : '';
      return result;
    },
    passwordUpper() {
      const result = PasswordChecker.passwordUpper(this.newPassword);
      this.colorUpper = result ? 'green' : '';
      return result;
    },
    passwordLower() {
      const result = PasswordChecker.passwordLower(this.newPassword);
      this.colorLower = result ? 'green' : '';
      return result;
    },
    passwordNum() {
      const result = PasswordChecker.passwordNum(this.newPassword);
      this.colorNumber = result ? 'green' : '';
      return result;
    },
    passwordChar() {
      const result = PasswordChecker.passwordChar(this.newPassword);
      this.colorCharacter = result ? 'green' : '';
      return result;
    },
    savePassword() {
      this.loading = true;
      const data = {
        email: this.email,
        token: this.token,
        new_password: this.newPassword,
        new_password2: this.repeatedPassword,
      };
      accountServices.setNewPassword(data)
        .then(() => {
          this.loading = false;
          this.alertSuccess = true;
          setTimeout(() => {
            this.newPassword = '';
            this.repeatedPassword = '';
            this.$router.push('/login');
            this.alertSuccess = false;
          }, 3000);
        })
        .catch(() => {
          this.alert = true;
          this.loading = false;
          setTimeout(() => {
            this.alert = false;
          }, 3000);
        });
    },
  },
};
</script>
<style scoped>
  .set-pass-title {
    color:white;
    font-size: 32px;
    margin-top: 2rem;
  }
  .custom-placeholer-color input::placeholder {
    color: white !important;
    opacity: 1;
  }
  .custom-label-color .v-label {
    color: white;
    opacity: 1;
  }
  .left {
    left: 0;
  }
  .right {
    right: 0;
    background-color: white;
  }
  .split {
    height: 100%;
    width: 50%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding-top: 20px;
  }
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
</style>
