<template lang="">
  <div>
    <v-alert v-if="alert" :type="typeAlert" transition="scale-transition">{{error}}</v-alert>
    <v-expansion-panels rounded id="documentation-profile-expasion-panels">
      <v-expansion-panel id="documentation-profile-expasion-panel">
        <v-expansion-panel-header id="documentation-profile-expasion-header" class="title-password">
          Documentation and instructions
        </v-expansion-panel-header>
        <v-expansion-panel-content id="documentation-profile-expasion-content">
          <v-row>
            <v-col>
              <div>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details></v-text-field>
              </div>
              <div>
                <v-data-table :headers="headers" :items="documentsList" :search="search" @click:row="tableRowClick"
                :loading="loading" loading-text="Loading documents...">
                  <template v-slot:item.name="{ item }">
                    <span class="h-cursor tfe-primary-color">
                      <v-icon color="primary">mdi-eye-outline</v-icon> {{ item.name }}</span>
                  </template>
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog v-model="showDocumentDialog" max-width="900" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title><span class="text-h5">Preview</span></v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <object
                  id="documentDialog"
                  v-bind:data="fileView"
                  type="application/pdf"
                  width=100%
                  height="100%">
                </object>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="showDocumentDialog=false" color="error">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { accountServices } from '@/services/accountServices';
import { roleManager } from '@/store/roleManager';

export default {
  name: 'DocumentationProfile',
  components: {},
  data() {
    return {
      roleManager,
      search: '',
      error: '',
      typeAlert: 'success',
      alert: false,
      loading: false,
      documentsList: [],
      showDocumentDialog: false,
      fileView: '',
    };
  },
  mounted() {
    this.getDocumentation();
  },
  computed: {
    headers() {
      const items = [
        { text: 'Name', value: 'name' },
        { text: 'Type', value: 'documentation_type' },
      ];
      if (roleManager.isAdmin()) {
        items.push({ text: 'Role', value: 'role' });
      }
      return items;
    },
  },
  methods: {
    getDocumentation() {
      this.loading = true;
      accountServices.getDocumentationProfile()
        .then((response) => {
          this.loading = false;
          this.documentsList = response.data;
        })
        .catch(() => {
          this.alert = true;
          this.loading = false;
          this.error = 'Error loading documents';
          this.typeAlert = 'error';
          setTimeout(() => {
            this.alert = false;
          }, 3000);
        });
    },
    tableRowClick(item) {
      this.getDocumentationProfileUrl(item.id);
    },
    getDocumentationProfileUrl(documentId) {
      const usrid = localStorage.getItem('user_id');
      const url = process.env.VUE_APP_BASE_URL;
      this.fileView = `${url}/api/users/documentation/${documentId}/check-file/?usrid=${usrid}`;
      this.showDocumentDialog = true;
      // this.loading = true;
      // accountServices.getDocumentationProfileUrl(documentId)
      //   .then((response) => {
      //     this.loading = false;
      //     window.open(response.data.url, '_blank');
      //   })
      //   .catch((err) => {
      //     this.alert = true;
      //     this.loading = false;
      //     this.error = 'Error loading document content';
      //     this.typeAlert = 'error';
      //     console.log(err);
      //     setTimeout(() => {
      //       this.alert = false;
      //     }, 3000);
      //   });
    },
  },
};
</script>
<style scoped>
  #documentDialog {
    min-height: 500px;
  }
  .title-password {
    font-size: 20px !important;
  }
  .v-expansion-panel {
    border-radius: 16px !important;
  }
  .v-expansion-panels {
    border-radius: 16px !important;
  }
</style>
