import { coreServices } from '@/services/coreServices';

export const milestonesService = {
  getMilestones() {
    const url = `${process.env.VUE_APP_BASE_URL}/api/milestones/`;
    return coreServices.get(url, coreServices.getRequestHeader());
  },
  getMyMilestonesAndStudyExpectedDocuments(studyId) {
    const dom = process.env.VUE_APP_BASE_URL;
    const url = `${dom}/api/milestones/${studyId}/milestones/study-milestones-and-expected-documents/`;
    return coreServices.get(url, coreServices.getRequestHeader());
  },
  updateMilestones(studyId, data) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/milestones/${studyId}/milestones/`;
    return coreServices.post(url, data, coreServices.getRequestHeader());
  },
  updateExpectedDocuments(studyId, data) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/milestones/${studyId}/update-my-expected-documents/`;
    return coreServices.post(url, data, coreServices.getRequestHeader());
  },
  closeStudyMilestone(studyId, studyMilestoneId) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/milestones/${studyId}/milestones/${studyMilestoneId}/close/`;
    return coreServices.patch(url, {}, coreServices.getRequestHeader());
  },
  openStudyMilestone(studyId, studyMilestoneId) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/milestones/${studyId}/milestones/${studyMilestoneId}/open/`;
    return coreServices.patch(url, {}, coreServices.getRequestHeader());
  },
  updateStudyMilestoneStatus(studyId, studyMilestoneId, isClosing) {
    if (isClosing) return this.closeStudyMilestone(studyId, studyMilestoneId);
    return this.openStudyMilestone(studyId, studyMilestoneId);
  },
  getSingleReport(studyId, mileId) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/milestones/${studyId}/milestones/${mileId}/report/`;
    return coreServices.get(url, coreServices.getRequestHeader());
  },
  getReport(studyId) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/milestones/${studyId}/milestones/report/`;
    return coreServices.get(url, coreServices.getRequestHeader());
  },
};
