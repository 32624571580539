var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-container"},[_c('app-header'),_c('nav-bar'),_c('div',{staticClass:"content"},[_c('overlay-loader',{attrs:{"loading":_vm.loading}}),_c('v-card',[_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pt-5",attrs:{"cols":"12","md":"2"}},[_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v("Documents / Studies")])]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[(_vm.documentos.length > 0)?_c('study-filter',{attrs:{"studyNameList":_vm.documentos},on:{"selectedStudy":_vm.sendStudyValueToParent}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","underlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"pt-5",attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"block":"","outlined":"","rounded":""},on:{"click":_vm.exportToXls}},[_c('v-icon',[_vm._v("mdi-cloud-download")]),_c('span',{staticClass:"btn-span-style"},[_vm._v("Download table")])],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getDocumentsFiltered,"search":_vm.search},scopedSlots:_vm._u([{key:"item.historic_timestamp_format",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.historic_timestamp_format.toLocaleString('es-ES')))])]}},{key:"item.status_name",fn:function(ref){
var item = ref.item;
return [(item.status_name !== null)?_c('data-status-label',{attrs:{"status_id":0,"status_name":item.status_name}}):_vm._e()]}},{key:"item.qc_issue_history",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.issueNumbersToNames(item.qc_issue_history))+" ")]}},{key:"item.qc_issue_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.issueNumbersToNames(item.qc_issue_type))+" ")]}},{key:"item.last_user",fn:function(ref){
var item = ref.item;
return [(item.last_user
              && item.last_user.includes('@anonymize.com'))?_c('span',[_c('i',[_vm._v("User deleted")])]):_c('span',[_vm._v(_vm._s(item.last_user))])]}},{key:"item.approver_user",fn:function(ref){
              var item = ref.item;
return [(item.approver_user
              && item.approver_user.includes('@anonymize.com'))?_c('span',[_c('i',[_vm._v("User deleted")])]):_c('span',[_vm._v(_vm._s(item.approver_user))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }