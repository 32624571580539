<template>
  <div id="preview-div" v-if="filesEncoded.length > 0" >
    <v-row>
      <v-col v-for="(file, index) in filesEncoded" :key="index" sm="6">
        <v-col class="pa-2">
          <canvas :id="'canvas '+ index" class="the-canvas"></canvas>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import PDFJSWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';
import 'pdfjs-dist/web/pdf_viewer.css';

pdfjsLib.GlobalWorkerOptions.workerSrc = PDFJSWorker;
export default {
  name: 'PdfViewer',
  props: {
    filesEncoded: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  mounted() {
    if (this.filesEncoded.length > 0) {
      for (let i = 0; i < this.filesEncoded.length; i += 1) {
        const file = this.filesEncoded[i];
        this.getPdf(file, i);
      }
    }
  },
  methods: {
    async getPdf(file, canvasIndex) {
      const loadingTask = pdfjsLib.getDocument(
        { data: atob(file) },
      );
      loadingTask.promise.then((pdf) => {
        const pageNumber = 1;
        pdf.getPage(pageNumber).then((page) => {
          const scale = 0.7;
          const viewport = page.getViewport({ scale: scale });
          const canvas = document.getElementById(`canvas ${canvasIndex}`);
          const context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          page.render(renderContext);
        });
      });
    },
  },
};
</script>

<style scoped>
.the-canvas {
  border: 1px solid black;
  direction: ltr;
}
</style>
