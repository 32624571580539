<template lang="">
  <div class="main-container">
    <app-header />
    <nav-bar />
    <v-alert v-if="alert" :type="typeAlert" transition="scale-transition">{{error}}</v-alert>
    <br/>
    <div class="content">
      <v-card>
        <v-toolbar class="mb-12" color="primary">
          <v-toolbar-title class="white--text mx-12">
            <v-card-title class="title_doc">List of users</v-card-title>
          </v-toolbar-title>
          <slot>
            <v-btn class="mr-5" fab bottom right absolute @click="newUser()">
              <v-icon>mdi-account-plus</v-icon>
            </v-btn>
          </slot>
          <slot>
            <v-btn
              v-if="!showUsers"
              style="margin-left: 45%"
              fab
              bottom
              absolute
              @click="showElementsInToolbar('users')">
              <v-icon>mdi-arrow-down-circle-outline</v-icon>
            </v-btn>
            <v-btn
              v-if="showUsers"
              style="margin-left: 45%;"
              fab
              bottom
              absolute
              @click="showElementsInToolbar('users')">
              <v-icon>mdi-arrow-up-circle-outline</v-icon>
            </v-btn>
          </slot>
        </v-toolbar>
        <v-row v-if="showUsers">
          <v-col cols="12">
            <v-data-table :headers="userHeadersRoleFiltered" :items="users" :search="userSearch" dense>
              <template v-slot:item.is_active="{ item }">
                <v-checkbox v-model="item.is_active" disabled class="mt-0" hide-details single-line></v-checkbox>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn x-small class="ma-1" color="accent" outlined rounded @click="updateUser(item)">
                Update <v-icon small dark>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
              x-small class="ma-2" color="error"
              outlined rounded v-model="deleteUserDialog" @click="deleteDialog(item)">
                Delete <v-icon small dark>mdi-delete</v-icon>
              </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
      <v-card>
        <v-toolbar class="mt-12 mb-12" color="primary" density="compact">
          <v-toolbar-title class="white--text mx-12">
            <v-card-title class="mt-5 title_doc">List of study coutries</v-card-title>
          </v-toolbar-title>
          <slot>
            <v-btn class="mr-5" fab bottom right absolute @click="newStudyCountry()">
              <v-icon>mdi-flag-plus</v-icon>
            </v-btn>
          </slot>
          <slot>
            <v-btn
                v-if="!showStudyCountries"
                style="margin-left: 45%"
                fab
                bottom
                absolute
                @click="showElementsInToolbar('studyCountries')">
                <v-icon>mdi-arrow-down-circle-outline</v-icon>
              </v-btn>
              <v-btn
                v-if="showStudyCountries"
                style="margin-left: 45%;"
                fab
                bottom
                absolute
                @click="showElementsInToolbar('studyCountries')">
                  <v-icon>mdi-arrow-up-circle-outline</v-icon>
              </v-btn>
          </slot>
        </v-toolbar>
        <v-row v-if=showStudyCountries>
          <v-col cols="12">
            <v-data-table :headers="studyCountryHeadersRoleFiltered" :items="countries" :search="studyCountrySearch"
            dense>
              <template v-slot:item.actions="{ item }">
                <v-btn x-small rounded class="ma-1" outlined color="accent"
                @click="updateStudyCountry(item)">
                  Update <v-icon small outlined right>mdi-pencil</v-icon>
                </v-btn>
                <v-btn x-small class="ma-2" rounded outlined color="error" :disabled="!item.is_deletable"
                @click="showDeleteStudyCountry(item)">
                  Delete <v-icon small outlined dark right>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
      <v-card>
        <v-toolbar class="mt-12 mb-12" color="primary">
          <v-toolbar-title class="white--text mx-12">
            <v-card-title class="mt-5 title_doc">List of sites</v-card-title>
          </v-toolbar-title>
          <slot>
            <v-btn class="mr-5" fab bottom right absolute @click="newStudySite()">
              <v-icon>mdi-map-marker-plus</v-icon>
            </v-btn>
          </slot>
          <slot>
            <v-btn
                v-if="!showStudySites"
                style="margin-left: 45%"
                fab
                bottom
                absolute
                @click="showElementsInToolbar('studySites')">
                <v-icon>mdi-arrow-down-circle-outline</v-icon>
              </v-btn>
              <v-btn
                v-if="showStudySites"
                style="margin-left: 45%;"
                fab
                bottom
                absolute
                @click="showElementsInToolbar('studySites')">
                <v-icon>mdi-arrow-up-circle-outline</v-icon>
              </v-btn>
          </slot>
        </v-toolbar>
        <div v-if="showStudySites">
          <v-col cols="12">
            <v-data-table :headers="siteHeadersRoleFiltered" :items="sites" :search="siteSearch" dense>
              <template v-slot:item.actions="{ item }">
                <v-btn x-small class="ma-1" color="accent" outlined rounded @click="showUpdateStudySite(item)">
                  Update <v-icon small dark right>mdi-pencil</v-icon>
                </v-btn>
                <v-btn x-small class="ma-2" color="error" outlined :disabled="!item.is_deletable" rounded
                  @click="showDeleteStudySite(item)">
                  Delete <v-icon small dark right>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </div>
      </v-card>
      <v-card v-if="roleManager.isStaff()">
        <v-toolbar class="mt-12 mb-12" color="primary" light>
          <v-toolbar-title class="white--text mx-12">
            <v-card-title class="mt-5 title_doc">List of licences</v-card-title>
          </v-toolbar-title>
          <slot>
            <v-btn class="mr-5" fab color="white" bottom right absolute @click="createNewLicence()">
              <v-icon>mdi-lock-plus</v-icon>
            </v-btn>
          </slot>
          <slot>
            <v-btn v-if="!showLicences" style="margin-left: 45%" fab bottom absolute
              @click="showElementsInToolbar('licences')">
              <v-icon>mdi-arrow-down-circle-outline</v-icon>
              </v-btn>
              <v-btn v-if="showLicences" style="margin-left: 45%;" fab bottom absolute
                @click="showElementsInToolbar('licences')">
                <v-icon>mdi-arrow-up-circle-outline</v-icon>
              </v-btn>
          </slot>
        </v-toolbar>
          <div v-if="showLicences">
            <v-col cols="12">
              <v-data-table :headers="licenceHeaders" :items="availableLicenses" :search="licenceSearch" dense>
                <template v-slot:item.is_active="{ item }">
                  <v-checkbox v-model="item.is_active" disabled hide-details class="mt-0"></v-checkbox>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn x-small class="ma-1" color="accent" outlined rounded @click="showUpdateLicence(item)">
                    Update <v-icon small dark right>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn x-small class="ma-2" color="error" outlined rounded @click="showDeleteLicence(item)">
                    Delete <v-icon small dark right>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </div>
      </v-card>
      <br>
      <br>
    </div>
    <overlay-loader :loading="loading"></overlay-loader>
    <v-dialog v-model="updateUserDialog" max-width="900" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title>
          <span class="text-h5">User Profile</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field underlined v-model="userSelected.email" label="E-mail*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="userSelected.first_name"
                  underlined
                  label="First name"
                  hint="example of helper text only on focus">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="userSelected.last_name"
                  label="Last name*"
                  hint="example of persistent helper text"
                  persistent-hint
                  underlined
                  required>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" v-show="roleManager.isStaff()">
                <v-select
                  v-model="userSelected.licence"
                  :items="availableLicenses"
                  label="License"
                  item-text="name"
                  item-value="id"
                  underlined>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="userSelected.role"
                  :items="roleManager.roleList()"
                  label="Role*"
                  underlined
                  required>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox v-model="userSelected.is_active" label="User Active*" required></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="updateUserDialog=false" color="error">Close</v-btn>
          <v-btn outlined rounded @click="saveDataUser()" color="primary">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteUserDialog" max-width="900" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title class="text-h6 justify-center">
        Are you sure to delete {{currentUser.email}} user?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="deleteUserDialog=false" color="error">No</v-btn>
          <v-btn outlined rounded @click="deleteUser(currentUser.id, currentUser.email)">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newUserDialog" max-width="900" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title>
          <span class="text-h5">User Profile</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="userCreated.email"
                  label="E-mail*"
                  type="email"
                  required
                  underlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="userCreated.first_name"
                  label="First name"
                  hint="example of helper text only on focus"
                  underlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="userCreated.last_name"
                  label="Last name*"
                  hint="example of persistent helper text"
                  underlined
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="userCreated.pass"
                  label="Password*"
                  type="password"
                  underlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" v-if="roleManager.isStaff()">
                <v-select
                  v-model="userCreated.licence_id"
                  :items="availableLicenses"
                  label="License"
                  item-text="name"
                  underlined
                  item-value="id"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="userCreated.role"
                  :items="roleManager.roleList()"
                  label="Role*"
                  required
                  underlined
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-checkbox
                  v-model="userCreated.is_active"
                  label="User Active*"
                  required
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="newUserDialog=false" color="error">Close</v-btn>
          <v-btn outlined rounded :disabled="userCreatedFormValid" @click="createUser()" color="primary">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newStudyCountryDialog" max-width="900" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title>
          <span class="text-h5">Study Country Profile</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-text-field
                v-model="nameStudyCountry"
                label="Name*"
                type="text"
                underlined
                required
              ></v-text-field>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="newStudyCountryDialog = false" color="error">Close</v-btn>
          <v-btn outlined rounded :disabled="formStudyCountryValid" @click="createStudyCountry()" color="primary">
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updateStudyCountryDialog" max-width="900" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title>
          <span class="text-h5">Study Country Profile</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="selectedCountry.name" underlined label="Name*" required></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="updateStudyCountryDialog = false" color="error">Close</v-btn>
          <v-btn outlined rounded @click="saveDataStudyCountry()" color="primary">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteStudyCountryDialog" max-width="900" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title class="text-h6 justify-center">
          Are you sure to delete {{ selectedCountry.name }}?
        </v-card-title>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined rounded @click="deleteStudyCountryDialog=false" color="error">No</v-btn>
        <v-btn outlined rounded @click="deleteStudyCountry()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newStudySiteDialog" max-width="900" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title>
          <span class="text-h5">Study Site Profile</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="studySiteCreated.name"
                  label="Name*"
                  type="text"
                  required
                  underlined
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="studySiteCreated.study_country"
                  underlined
                  :items="countries"
                  item-text="name"
                  item-value="id"
                  label="Country Name*"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="studySiteCreated.study"
                  underlined
                  :items="studies"
                  item-text="name"
                  item-value="id"
                  label="Study Name"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="newStudySiteDialog = false" color="error">Close</v-btn>
          <v-btn outlined rounded :disabled="formStudySiteValid" @click="createStudySite()" color="primary">
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updateStudySiteDialog" max-width="900" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title>
          <span class="text-h5">Study Site Profile</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="selectedSite.name"
                  label="Name*"
                  type="text"
                  required
                  underlined></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  underlined
                  v-model="selectedSite.study_country"
                  item-text="name"
                  item-value="id"
                  :items="countries"
                  label="Country Name*"
                  required></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="selectedSite.study"
                  :items="studies"
                  underlined
                  item-text="name"
                  item-value="id"
                  label="Study Name"></v-select>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="updateStudySiteDialog=false" color="error">Close</v-btn>
          <v-btn outlined rounded @click="saveDataStudySite()" color="primary">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteStudySiteDialog" max-width="900" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title class="text-h6 justify-center">Are you sure to delete {{ selectedSite.name }}?</v-card-title>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined rounded @click="deleteStudySiteDialog=false" color="error">No</v-btn>
        <v-btn outlined rounded @click="deleteStudySite()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newLicenceDialog" max-width="900" :retain-focus="false" v-if="roleManager.isStaff()">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add license</span>
        </v-card-title>
        <v-card-text>
          <form>
            <v-container fluid>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-text-field v-model="newLicence.name" label="License name*"
                  hide-details required underlined></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pt-1">
                  <v-checkbox v-model="newLicence.is_active" hide-details label="Licence active" required></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pt-6">
                  <h3 class="tfe-black-color">KPI parameters</h3>
                </v-col>
                <v-col cols="12">
                  <img src="../assets/img/kpi_diagram_blank.png" class="mw-100">
                </v-col>
                <v-col cols="12" class="pt-0"><h3>Pre-Tmfy</h3></v-col>
                <v-col cols="12" class="pt-0">
                  <v-text-field v-model="newLicence.kpi_pre_tmfy" type="number" min="1" label="Pre-tmfy (days)*"
                  :rules="[licenceRules.kpiOverZero]" required underlined></v-text-field>
                </v-col>
                <v-col cols="12" class="pb  -0"><h3>Management</h3></v-col>
                <v-col cols="12" sm="6" class="pt-0">
                  <v-text-field v-model="newLicence.kpi_management_not_urgent" type="number" min="1"
                  :rules="[licenceRules.kpiOverZero, licenceRules.newKpiManNotUrgent]"
                  label="Not urgent (days)*" required underlined></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="pt-0">
                  <v-text-field v-model="newLicence.kpi_management_urgent" type="number" min="1"
                  :rules="[licenceRules.kpiOverZero]"
                  label="Urgent (days)*" required underlined></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0"><h3>Approval</h3></v-col>
                <v-col cols="12" sm="6" class="pt-0">
                  <v-text-field v-model="newLicence.kpi_approval_not_urgent" type="number" min="1"
                  :rules="[licenceRules.kpiOverZero, licenceRules.newKpiAppNotUrgent]"
                  label="Not urgent (days)*" required underlined></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="pt-0">
                  <v-text-field v-model="newLicence.kpi_approval_urgent" type="number" min="1"
                  :rules="[licenceRules.kpiOverZero]"
                  label="Urgent (days)*" required underlined></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0"><h3>Total</h3></v-col>
                <v-col cols="12" class="pt-0">
                  <v-text-field v-model="newLicence.kpi_total_time" type="number" min="1" label="Total time (days)*"
                  :rules="[licenceRules.kpiOverZero, licenceRules.newKpiTotal]" required underlined></v-text-field>
                  <small><strong>Total time must be higher or equal to the sum of all highers values</strong></small>
                </v-col>
              </v-row>
              <v-row>
                <v-col><small>*indicates required field</small></v-col>
              </v-row>
            </v-container>
          </form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="newLicenceDialog=false" color="error">Close</v-btn>
          <v-btn outlined rounded @click="saveNewLicence()" color="primary">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteLicenceDialog" max-width="900" :retain-focus="false" v-if="roleManager.isStaff()">
      <v-card class="dialog-style">
        <v-card-title class="text-h6 justify-center">Are you sure to delete {{ selectedLicence.name }}?</v-card-title>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined rounded @click="deleteLicenceDialog=false" color="error">No</v-btn>
        <v-btn outlined rounded @click="deleteLicence()" color="primary">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updateLicenceDialog" max-width="900" :retain-focus="false" v-if="roleManager.isStaff()">
      <v-card class="dialog-style">
        <v-card-title><span class="text-h5">Licence</span></v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="selectedLicence.name" label="Name*" required underlined></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-checkbox v-model="selectedLicence.is_active"
                hide-details label="Licence active" required></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="pt-6">
                  <h3 class="tfe-black-color">KPI parameters</h3>
                </v-col>
                <v-col cols="12">
                  <img src="../assets/img/kpi_diagram_blank.png" class="mw-100">
                </v-col>
                <v-col cols="12" class="pt-0"><h3>Pre-Tmfy</h3></v-col>
                <v-col cols="12" class="pt-0">
                  <v-text-field v-model="selectedLicence.kpi_pre_tmfy" type="number" min="1" label="Pre-tmfy (days)*"
                  :rules="[licenceRules.kpiOverZero]" required underlined></v-text-field>
                </v-col>
                <v-col cols="12" class="pb  -0"><h3>Management</h3></v-col>
                <v-col cols="12" sm="6" class="pt-0">
                  <v-text-field v-model="selectedLicence.kpi_management_not_urgent" type="number" min="1"
                  :rules="[licenceRules.kpiOverZero, licenceRules.kpiManNotUrgent]"
                  label="Not urgent (days)*" required underlined></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="pt-0">
                  <v-text-field v-model="selectedLicence.kpi_management_urgent" type="number" min="1"
                  :rules="[licenceRules.kpiOverZero]"
                  label="Urgent (days)*" required underlined></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0"><h3>Approval</h3></v-col>
                <v-col cols="12" sm="6" class="pt-0">
                  <v-text-field v-model="selectedLicence.kpi_approval_not_urgent" type="number" min="1"
                  :rules="[licenceRules.kpiOverZero, licenceRules.kpiAppNotUrgent]"
                  label="Not urgent (days)*" required underlined></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="pt-0">
                  <v-text-field v-model="selectedLicence.kpi_approval_urgent" type="number" min="1"
                  :rules="[licenceRules.kpiOverZero]"
                  label="Urgent (days)*" required underlined></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0"><h3>Total</h3></v-col>
                <v-col cols="12" class="pt-0">
                  <v-text-field v-model="selectedLicence.kpi_total_time" type="number" min="1"
                  label="Total time (days)*" :rules="[licenceRules.kpiOverZero, licenceRules.kpiTotal]"
                  required underlined></v-text-field>
                  <small><strong>Total time must be higher or equal to the sum of all highers values</strong></small>
                </v-col>
              </v-row>
              <v-row>
                <v-col><small>*indicates required field</small></v-col>
              </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="updateLicenceDialog=false" color="error">Close</v-btn>
          <v-btn outlined rounded @click="saveUpdateLicence()" color="primary">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import pbkdf2 from 'pbkdf2';
import OverlayLoader from '@/components/generic/OverlayLoader.vue';
import AppHeader from '@/components/generic/AppHeader.vue';
import NavBar from '@/components/generic/NavBar.vue';
import { accountServices } from '@/services/accountServices';
import { masterdataServices } from '@/services/masterdataServices';
import { fileServices } from '../services/fileServices';
import { passInfo } from '../utils/exportConsts';
import { roleManager } from '@/store/roleManager';

export default {
  name: 'ControlPanel',
  components: {
    AppHeader,
    NavBar,
    OverlayLoader,
  },
  data() {
    return {
      roleManager,
      userSearch: '',
      users: [],
      updateUserDialog: false,
      deleteUserDialog: false,
      newUserDialog: false,
      showUsers: false,
      currentUser: '',
      userSelected: {},
      userCreated: {},
      userHeaders: [
        { text: 'Email', value: 'email' },
        { text: 'First Name', value: 'first_name' },
        { text: 'Last name', value: 'last_name' },
        { text: 'Licence', value: 'licence.name' },
        { text: 'Role', value: 'role' },
        { text: 'Is active', value: 'is_active' },
        { text: 'Actions', value: 'actions', align: 'right' },
      ],
      studies: [],
      countries: [],
      selectedCountry: {},
      studyCountryCreated: {},
      studyCountrySearch: '',
      newStudyCountryDialog: false,
      updateStudyCountryDialog: false,
      deleteStudyCountryDialog: false,
      showStudyCountries: false,
      studyCountryHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Licence', value: 'licence.name' },
        { text: 'Actions', value: 'actions', align: 'right' },
      ],
      showStudySites: false,
      selectedSite: {},
      studySiteCreated: {},
      newStudySiteDialog: false,
      updateStudySiteDialog: false,
      deleteStudySiteDialog: false,
      sites: [],
      siteSearch: '',
      siteHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Country name', value: 'study_country.name' },
        { text: 'Study name', value: 'study.name' },
        { text: 'Licence', value: 'licence.name' },
        { text: 'Actions', value: 'actions', align: 'right' },
      ],
      showLicences: false,
      availableLicenses: [],
      selectedLicence: {},
      updateLicenceDialog: false,
      deleteLicenceDialog: false,
      newLicenceDialog: false,
      licenceSearch: '',
      newLicence: {},
      licenceHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Is active', value: 'is_active' },
        { text: 'KPI Pre-Tmfy', value: 'kpi_pre_tmfy' },
        { text: 'KPI Man. not urgent', value: 'kpi_management_not_urgent' },
        { text: 'KPI Man. urgent', value: 'kpi_management_urgent' },
        { text: 'KPI App. not urgent', value: 'kpi_approval_not_urgent' },
        { text: 'KPI App. urgent', value: 'kpi_approval_urgent' },
        { text: 'KPI total', value: 'kpi_total_time' },
        { text: 'Actions', value: 'actions', align: 'right' },
      ],
      defaultKpiDays: null,
      licenceRules: {
        kpiOverZero: (value) => value > 0 || 'Only positive numbers',
        newKpiManNotUrgent: (value) => value >= this.newLicence.kpi_management_urgent
        || 'Not urgent must be higher than urgent',
        newKpiAppNotUrgent: (value) => value >= this.newLicence.kpi_approval_urgent
        || 'Not urgent must be higher than urgent',
        newKpiTotal: (value) => value >= this.formLicenceKpiTotal,
        kpiManNotUrgent: (value) => value >= this.selectedLicence.kpi_management_urgent
        || 'Not urgent must be higher than urgent',
        kpiAppNotUrgent: (value) => value >= this.selectedLicence.kpi_approval_urgent
        || 'Not urgent must be higher than urgent',
        kpiTotal: (value) => value >= this.formLicenceKpiTotal,
      },
      error: '',
      alert: false,
      typeAlert: 'error',
      nameStudyCountry: '',
      loading: false,
    };
  },
  computed: {
    userHeadersRoleFiltered() {
      if (roleManager.isStaff()) return this.userHeaders;
      return this.userHeaders.filter((item) => item.text.toLowerCase() !== 'licence');
    },
    studyCountryHeadersRoleFiltered() {
      if (roleManager.isStaff()) return this.studyCountryHeaders;
      return this.studyCountryHeaders.filter((item) => item.text.toLowerCase() !== 'licence');
    },
    siteHeadersRoleFiltered() {
      if (roleManager.isStaff()) return this.siteHeaders;
      return this.siteHeaders.filter((item) => item.text.toLowerCase() !== 'licence');
    },
    userCreatedFormValid() {
      return !(
        'email' in this.userCreated
        && this.userCreated.email !== ''
        && this.userCreated.email !== null
        && 'first_name' in this.userCreated
        && this.userCreated.first_name !== ''
        && this.userCreated.first_name !== null
        && 'last_name' in this.userCreated
        && this.userCreated.last_name !== ''
        && this.userCreated.last_name !== null
        && 'role' in this.userCreated
        && this.userCreated.role !== ''
        && this.userCreated.role !== null
        && (
          (roleManager.isStaff() && this.userCreated.license_id !== null)
          || roleManager.isAdmin()
        )
      );
    },
    formStudyCountryValid() {
      return !(this.nameStudyCountry !== '');
    },
    formStudySiteValid() {
      return !(this.studySiteCreated.name !== '' && this.studySiteCreated.study_country !== '');
    },
    isNewLicenceFormValid() {
      return !(
        this.newLicence.name
        && this.newFormLicenceKpiTotal
      );
    },
    newFormLicenceKpiTotal() {
      const p = parseInt(this.newLicence.kpi_pre_tmfy, 10);
      const m = parseInt(this.newLicence.kpi_management_not_urgent, 10);
      const a = parseInt(this.newLicence.kpi_approval_not_urgent, 10);
      if (p === null || m === null || p === null) return 0;
      return p + m + a;
    },
    formLicenceKpiTotal() {
      const p = parseInt(this.selectedLicence.kpi_pre_tmfy, 10);
      const m = parseInt(this.selectedLicence.kpi_management_not_urgent, 10);
      const a = parseInt(this.selectedLicence.kpi_approval_not_urgent, 10);
      if (p === null || m === null || p === null) return 0;
      return p + m + a;
    },
  },
  async mounted() {
    await accountServices.checkUserData()
      .then((response) => {
        if (response.data.role !== 'Admin') {
          this.$router.push('/home');
        }
      })
      .catch(() => {});
    await Promise.all([
      this.getUsers(),
      this.getStudies(),
      this.getStudiesCountries(),
    ]);
    await this.getStudySites();
    if (roleManager.isStaff()) {
      this.getLicenses();
    }
  },
  methods: {
    showErrorAlert(typeAlert, msg) {
      this.alert = true;
      this.loading = false;
      this.typeAlert = typeAlert;
      this.error = msg;
      setTimeout(() => {
        this.alert = false;
      }, 3000);
    },
    showElementsInToolbar(elementsToShow) {
      if (elementsToShow === 'users') {
        this.showUsers = !this.showUsers;
      } else if (elementsToShow === 'studyCountries') {
        this.showStudyCountries = !this.showStudyCountries;
      } else if (elementsToShow === 'studySites') {
        this.showStudySites = !this.showStudySites;
      } else if (elementsToShow === 'licences') {
        this.showLicences = !this.showLicences;
      }
    },
    async getStudies() {
      await fileServices.getStudies()
        .then((response) => { this.studies = response.data; })
        .catch(() => {});
    },
    async getLicenses() {
      await accountServices.getLicences()
        .then((response) => { this.availableLicenses = response.data; })
        .catch(() => {});
    },
    newUser() {
      this.userCreated = {};
      this.newUserDialog = true;
    },
    newStudyCountry() {
      this.nameStudyCountry = '';
      this.newStudyCountryDialog = true;
    },
    /** Show new study site dialog */
    newStudySite() {
      this.studySiteCreated = {};
      this.newStudySiteDialog = true;
    },
    createUser() {
      this.loading = true;
      pbkdf2.pbkdf2(this.userCreated.pass, passInfo.salt, passInfo.iterations, 32, (err) => {
        if (err) throw err;
        accountServices.createUser(this.userCreated)
          .then((response) => {
            this.showErrorAlert('success', `User ${response.data.email} created`);
            this.users.push(response.data);
            this.loading = false;
            this.newUserDialog = false;
            this.userCreated = {};
          })
          .catch(() => { this.showErrorAlert('error', 'Error creating new user'); this.loading = false; });
      });
    },
    /** Create a new study country */
    createStudyCountry() {
      this.loading = true;
      fileServices.setNewStudyCountry({ name: this.nameStudyCountry })
        .then((response) => {
          this.countries.push(response.data);
          this.loading = false;
          this.newStudyCountryDialog = false;
          this.nameStudyCountry = '';
          this.showErrorAlert('success', `Study country ${response.data.name} created`);
        })
        .catch(() => { this.showErrorAlert('error', 'Error creating new country'); this.loading = false; });
    },
    /** Create study site */
    createStudySite() {
      this.loading = true;
      fileServices.setNewStudySite(this.studySiteCreated)
        .then((response) => {
          this.sites.push(response.data);
          this.loading = false;
          this.newStudySiteDialog = false;
          this.studySiteCreated = {};
          this.showErrorAlert('success', `Study site ${response.data.name} created`);
        })
        .catch(() => {
          this.newStudySiteDialog = false;
          this.loading = false;
          this.showErrorAlert('error', 'Error creating study site');
        });
    },
    async getUsers() {
      const response = await accountServices.getUsers();
      /* eslint-disable no-param-reassign */
      this.users = response.data;
      /* eslint-enable no-param-reassign */
      this.users.sort((a, b) => {
        const firstName = a.last_name.toLowerCase();
        const secondName = b.last_name.toLowerCase();
        // eslint-disable-next-line
        return firstName < secondName ? -1 : (firstName > secondName ? 1 : 0);
      });
    },
    async getStudiesCountries() {
      const response = await fileServices.getStudyCountryList();
      this.countries = response.data;
      this.countries.sort((a, b) => {
        const firstCountry = a.name.toLowerCase();
        const secondCountry = b.name.toLowerCase();
        // eslint-disable-next-line
        return firstCountry < secondCountry ? -1 : (firstCountry > secondCountry ? 1 : 0);
      });
    },
    async getStudySites() {
      const response = await fileServices.getStudySites();
      this.sites = response.data;
      this.sites.sort((a, b) => {
        const firstSite = a.name.toLowerCase();
        const secondSite = b.name.toLowerCase();
        // eslint-disable-next-line
        return firstSite < secondSite ? -1 : (firstSite > secondSite ? 1 : 0);
      });
    },
    /** Show delete dialog.
     * @param {Object} user Selected user object
     */
    deleteDialog(user) {
      this.currentUser = { ...user };
      this.deleteUserDialog = true;
    },
    /** Delete user.
     * @param {int} userId User id to delete
     */
    deleteUser(userId, userEmail) {
      accountServices.deleteUser(userId)
        .then(() => {
          this.showErrorAlert('success', `User ${userEmail} deleted`);
          this.deleteUserDialog = false;
          this.users = this.users.filter((user) => user.id !== userId);
        })
        .catch(() => {
          this.showErrorAlert('error', `User ${userEmail} can not be deleted`);
        });
    },
    /** Show delete study country dialog
     * @param {Object} studyCountry Object to delete
     */
    showDeleteStudyCountry(studyCountry) {
      this.deleteStudyCountryDialog = true;
      this.selectedCountry = { ...studyCountry };
    },
    /** Delete selected study country. */
    deleteStudyCountry() {
      fileServices.deleteStudyCountry(this.selectedCountry.id)
        .then(() => {
          this.deleteStudyCountryDialog = false;
          this.countries = this.countries.filter(
            (country) => country.id !== this.selectedCountry.id,
          );
          this.showErrorAlert('success', `Study country ${this.selectedCountry.name} deleted`);
          this.selectedCountry = {};
        })
        .catch(() => {
          this.showErrorAlert('error', 'Study country can not be deleted');
        });
    },
    /** Show delete site dialog
     * @param {Object} site Object to delete
     */
    showDeleteStudySite(site) {
      this.deleteStudySiteDialog = true;
      const s = { ...site };
      // eslint-disable-next-line
      s.study = s.study.id;
      // eslint-disable-next-line
      s.study_country = s.study_country.id;
      this.selectedSite = s;
    },
    /** Delete selected study site */
    deleteStudySite() {
      fileServices.deleteStudySite(this.selectedSite.id)
        .then(() => {
          this.deleteStudySiteDialog = false;
          this.sites = this.sites.filter((site) => site.id !== this.selectedSite.id);
          this.showErrorAlert('success', `Study site ${this.selectedSite.name} deleted`);
          this.selectedSite = {};
        })
        .catch(() => {
          this.showErrorAlert('error', 'Study site can not be deleted');
          this.deleteStudySiteDialog = false;
        });
    },
    /** Show update user dialog
     * @param {Object} user Objectto update
     */
    updateUser(user) {
      this.userSelected = { ...user };
      this.updateUserDialog = true;
    },
    /** Save updated selected user */
    saveDataUser() {
      this.userSelected.licence_id = this.userSelected.licence.id;
      delete this.userSelected.licence;
      accountServices.updateUser(this.userSelected.id, this.userSelected)
        .then((response) => {
          const foundIndex = this.users.findIndex((user) => user.id === this.userSelected.id);
          this.users.splice(foundIndex, 1, { ...response.data });
          this.userSelected = {};
          this.showErrorAlert('success', `User ${response.data.email} updated`);
          this.updateUserDialog = false;
        })
        .catch(() => {
          this.showErrorAlert('error', `Error updating user ${this.userSelected.email}`);
        });
      this.updateUserDialog = false;
    },
    removeStudyCountry(country) {
      this.deleteStudyCountryDialog = true;
      this.studyCountryCreated = country;
    },
    updateStudyCountry(country) {
      this.updateStudyCountryDialog = true;
      this.selectedCountry = { ...country };
    },
    saveDataStudyCountry() {
      fileServices.updateStudyCountry(
        this.selectedCountry.id, this.selectedCountry.name,
      )
        .then((response) => {
          const foundIndex = this.countries.findIndex(
            (country) => country.id === this.selectedCountry.id,
          );
          this.countries.splice(foundIndex, 1, { ...this.selectedCountry });
          this.showErrorAlert('success', `Study country ${response.data.name} updated`);
          this.updateStudyCountryDialog = false;
        }).catch(() => {
          this.updateStudyCountryDialog = false;
          this.showErrorAlert('error', 'Error updating study country');
        });
    },
    showUpdateStudySite(site) {
      this.updateStudySiteDialog = true;
      this.selectedSite = { ...site };
    },
    saveDataStudySite() {
      this.loading = true;
      // eslint-disable-next-line
      this.selectedSite.study_country = this.selectedSite.study_country.id;
      this.selectedSite.study = this.selectedSite.study.id;
      fileServices.updateStudySite(this.selectedSite.id, this.selectedSite)
        .then((response) => {
          this.loading = false;
          const foundIndex = this.sites.findIndex(
            (site) => site.id === this.selectedSite.id,
          );
          this.sites.splice(foundIndex, 1, { ...response.data });
          this.showErrorAlert('success', `Study site ${response.data.name} updated`);
          this.updateStudySiteDialog = false;
        }).catch(() => {
          this.loading = false;
          this.updateStudySiteDialog = false;
          this.showErrorAlert('error', 'Error updating study site');
        });
    },
    /** Show new licence dialog */
    createNewLicence() {
      // Set by default some values
      this.newLicence = { is_active: true };
      this.newLicenceDialog = true;
      if (this.defaultKpiDays === null) {
        this.loading = true;
        masterdataServices.getDefaultKpiDays()
          .then((response) => {
            this.loading = false;
            this.defaultKpiDays = response.data;
            this.setDefaultKpiValues();
          })
          .catch(() => { this.loading = false; });
      } else {
        this.setDefaultKpiValues();
      }
    },
    setDefaultKpiValues() {
      this.newLicence = { ...this.newLicence, ...this.defaultKpiDays };
    },
    /** Save new licence */
    saveNewLicence() {
      this.loading = true;
      this.newLicenceDialog = false;
      accountServices.createNewLicence(this.newLicence)
        .then((response) => {
          this.showErrorAlert('success', `Licence ${response.data.name} created`);
          this.availableLicenses.push(response.data);
          this.loading = false;
        })
        .catch(() => {
          this.showErrorAlert('error', 'Error creating licence');
        });
    },
    /** Save updated selected licence */
    saveUpdateLicence() {
      this.loading = true;
      accountServices.updateLicence(this.selectedLicence.id, this.selectedLicence)
        .then((response) => {
          const foundIndex = this.availableLicenses.findIndex(
            (licence) => licence.id === this.selectedLicence.id,
          );
          this.availableLicenses.splice(foundIndex, 1, { ...this.selectedLicence });
          this.loading = false;
          this.showErrorAlert('success', `Licence ${response.data.name} updated`);
          this.updateLicenceDialog = false;
        })
        .catch(() => { this.loading = false; this.showErrorAlert('error', 'Error updating licence'); });
    },
    showUpdateLicence(item) {
      this.selectedLicence = { ...item };
      this.updateLicenceDialog = true;
    },
    showDeleteLicence(item) {
      this.selectedLicence = { ...item };
      this.deleteLicenceDialog = true;
    },
    /** Delete selected licence */
    deleteLicence() {
      accountServices.deleteLicence(this.selectedLicence.id)
        .then(() => {
          this.deleteLicenceDialog = false;
          this.showErrorAlert('success', `Licence ${this.selectedLicence.name} deleted`);
          this.availableLicenses = this.availableLicenses.filter(
            (licence) => licence.id !== this.selectedLicence.id,
          );
          this.selectedLicence = {};
        })
        .catch(() => {
          this.showErrorAlert('error', 'Licence can not be deleted');
        });
    },
  },
};
</script>
<style scoped>
  .delete-icon {
    margin-top: 5px;
    font-size: 14px !important;
  }
  .mw-100 {
    max-width: 100%;
  }
</style>
