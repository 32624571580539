import axios from 'axios';
import Cookies from 'js-cookie';

export const coreServices = {
  async get(url, header, params) {
    return axios.get(url, { headers: header, params: params });
  },
  async downloadBlob(url, data, header) {
    return axios.post(url, data, { headers: header, responseType: 'blob' });
  },
  async downloadGetBlob(url, header) {
    return axios.get(url, { headers: header, responseType: 'blob' });
  },
  async post(url, data, header) {
    return axios.post(url, data, { headers: header });
  },
  async put(url, data, header) {
    return axios.put(url, data, { headers: header });
  },
  async patch(url, data, header) {
    return axios.patch(url, data, { headers: header });
  },
  async delete(url, header, data) {
    return axios.delete(url, { headers: header, data: data });
  },
  /**
   * Get set of headers for an API request
   * @param {object} extraItemsToPush Extra key-value dictionary to add to the result
   * @return {object} Returns a dictionary object with all the needed values to add to the header of the API request,
   * such as: Token, Content-type, etc.
   */
  getRequestHeader(extraItemsToPush = null) {
    const headers = {
      Authorization: `Bearer ${Cookies.get('Authorization')}`,
      'Content-Type': 'application/json',
    };
    if (extraItemsToPush !== null) {
      Object.assign(headers, extraItemsToPush);
    }
    return headers;
  },
};
