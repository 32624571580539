<template>
  <div>
    <overlay-loader :loading="loading"></overlay-loader>
    <v-row id="row-download-documents" justify="end">
        <v-col id="col-download-documents" class="text-righ">
            <v-btn
            outlined
            block
            rounded
            id="export-Studies-btn"
            @click="exportStudiesFile"
            style="width:100%">
              <v-icon class="mr-2">mdi-cloud-download</v-icon>
              Download table
            </v-btn>
        </v-col>
    </v-row>
  </div>
</template>

<script>
import * as Excel from 'exceljs/dist/exceljs';
import { saveAs } from 'file-saver';
import OverlayLoader from '@/components/generic/OverlayLoader.vue';

export default {
  name: 'DownloadDocuments',
  components: {
    OverlayLoader,
  },
  props: {
    documents: Array,
  },
  computed: {
    tableFiltered() {
      return this.documents;
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    exportStudiesFile() {
      this.loading = true;
      const documentsFiltered = this.tableFiltered;
      try {
        this.loading = false;
        this.exportXlsNew(documentsFiltered);
      } catch (error) {
        console.error('export error');
      }
    },
    async exportXlsNew(documentsFiltered) {
      const wb = new Excel.Workbook();

      const worksheet = wb.addWorksheet('My Sheet');

      worksheet.columns = [
        { header: 'title', key: 'title', width: 32 },
        { header: 'study', key: 'study', width: 10 },
        { header: 'zone_name', key: 'zone_name', width: 32 },
        { header: 'section_name', key: 'section_name', width: 20 },
        { header: 'artifact_name', key: 'artifact_name', width: 10 },
        { header: 'owner', key: 'owner', width: 10 },
        { header: 'status_name', key: 'status_name', width: 10 },
        { header: 'upload_date', key: 'upload_date', width: 10 },
        { header: 'submit_for_approval', key: 'submit_for_approval', width: 10 },
        { header: 'last_modified', key: 'last_modified', width: 10 },
        { header: 'approver_user_email', key: 'approver_user_email', width: 10 },
        { header: 'last_user_email', key: 'last_user_email', width: 10 },
        { header: 'doc_date', key: 'doc_date', width: 20 },
        { header: 'deadline', key: 'deadline', width: 20 },
        { header: 'approval_date', key: 'approval_date', width: 20 },
        { header: 'received_date', key: 'received_date', width: 20 },
        { header: 'isf', key: 'isf', width: 5 },
        { header: 'required_verified_copy', key: 'required_verified_copy', width: 5 },
      ];
      for (let i = 0; i < documentsFiltered.length; i += 1) {
        const documentCurrent = documentsFiltered[i];
        const element = {
          title: documentCurrent.title,
          study: documentCurrent.study_name,
          zone_name: documentCurrent.zone_name,
          section_name: documentCurrent.section_name,
          artifact_name: documentCurrent.artifact_name,
          owner: documentCurrent.owner,
          status_name: documentCurrent.status_name,
          upload_date: documentCurrent.upload_date_format,
          submit_for_approval: documentCurrent.submit_for_approval_format,
          last_modified: documentCurrent.last_modified_format,
          approver_user_email: documentCurrent.approver_user_email,
          last_user_email: documentCurrent.last_user_email,
          doc_date: documentCurrent.doc_date_format,
          deadline: documentCurrent.deadline_format,
          approval_date: documentCurrent.approval_date_format,
          received_date: documentCurrent.received_date_format,
          required_verified_copy: documentCurrent.required_verified_copy,
          isf: documentCurrent.isf,
        };

        worksheet.addRow(element);
        worksheet.getCell(`A${i + 2}`).value = {
          text: element.title,
          hyperlink: this.createLink(documentCurrent),
          tooltip: this.createLink(documentCurrent),
        };
      }
      const buf = await wb.xlsx.writeBuffer();
      saveAs(new Blob([buf]), 'Library.xlsx');
    },
    createLink(item) {
      const url = window.location.origin;
      return `${url}/library/edit/${item.id}/`;
    },
  },
};
</script>
