<template>
  <div>
    <v-card id="matrix-folder-modal-card">
      <overlay-loader :loading="loading"></overlay-loader>
      <v-alert v-if="alert" :type="typeError" transition="scale-transition">{{ error }}</v-alert>
      <v-card-title id="matrix-folder-modal-card-title">
        <span id="matrix-folder-modal-card-span" class="text-h5">Milestone matrix</span>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details class="mr-2">
        </v-text-field>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-container fluid class="pb-0">
          <v-row>
            <v-col cols="12">
              <v-data-table
                dense
                height="67vh"
                fixed-header
                :headers="matrixTableHeader"
                :items="matrixTableItems"
                :hide-default-footer="false"
                :search="search"
                :footer-props="{'items-per-page-options': [50, 100, 300, -1]}">
                <template v-slot:item.expected_document_count="{ item }" style="font-size: 10px;">
                  <div style="display: none;">{{ item.documents_list }}</div>
                  <div v-if="item.doc_exp_type === 'auto'" class="tfe-gray-color">
                    {{ item.document_count }}/{{ item.expected_document_count }}
                  </div>
                  <v-text-field
                    v-else-if="item.doc_exp_type"
                    class="pt-0 mt-0 mb-1 centered-input"
                    v-model="item.expected_document_count"
                    type="number"
                    :min="0"
                    placeholder="0"
                    :disabled="!item.doc_exp_type || !roleManager.isAnyAdmin()"
                    :prefix="`>${item.document_count || 0}`"
                    hide-details
                    single-line
                    underlined>
                  </v-text-field>
                  <div v-else></div>
                </template>
                <template v-for="(h, i) in matrixTableHeaderMileNames" v-slot:[`item.${h}`]="{ item }">
                  <div :key="i" v-if="item[h] && !['', null].includes(item[h].document_count)"
                    style="font-size: 10px;">
                    <v-text-field
                      class="pt-0 mt-0 mb-1 centered-input"
                      v-model="item[h].expected_document_count"
                      single-line
                      type="number"
                      :min="0"
                      placeholder="0"
                      hide-details
                      :disabled="item[h].is_closed || !roleManager.isAnyAdmin()"
                      :prefix="`>${item[h].document_count}`"
                      @change="item.doc_exp_type === 'auto'
                      ? onStudyMileReqChange(item.zone_id, item.section_id, item.artifact_id) : null">
                    </v-text-field>
                  </div>
                  <!-- <div v-else :key="i" class="table-empty-cell-container" :id="getStudyMileReqCellDictName(
                      item.zone_id, item.section_id, item.artifact_id, h.replace('mile_', ''))">
                    <div class="placeholder"
                      @click="addInputEmptyCell(item.zone_id, item.section_id, item.artifact_id, h, index)">
                      <span>Add value</span>
                    </div>
                    <div class="input-container" v-if="item[h]
                      && !['', null].includes(item[h].expected_document_count)">
                      <v-icon class="delete-icon"
                      @click="removeCell(item.zone_id, item.section_id, item.artifact_id, h, index)"
                      color="error">mdi-delete</v-icon>
                      <v-text-field
                        v-if="h in item"
                        class="pt-0 mt-0 mb-1 centered-input"
                        v-model="item[h].expected_document_count"
                        single-line
                        type="number"
                        placeholder="1"
                        hide-details
                        prefix=">1"
                        @change="item.doc_exp_type === 'auto'
                        ? onStudyMileReqChange(item.zone_id, item.section_id, item.artifact_id) : null">
                      </v-text-field>
                    </div>
                  </div> -->
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn id="cancel-matrix-folder" outlined rounded color="error" @click="cancel()">Close</v-btn>
        <v-btn id="matrix-folder-btn" @click="saveMatrix" outlined rounded color="primary"
        v-if="roleManager.isAnyAdmin()">Save</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="showInputErrorDialog" max-width="900" :retain-focus="false">
      <v-card class="dialog-style expected-documents-dialog">
        <v-card-title>
          <span class="text-h5">Expected document too low</span>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col>
                <p>
                  The expected document value <u>must be higher or equal</u> to the documents saved.
                </p>
                <p><strong>Cell</strong>:</p>
                <ul>
                  <li><strong>Zone</strong>: {{ errorInput.zone }}</li>
                  <li><strong>Section</strong>: {{ errorInput.section }}</li>
                  <li><strong>Artifact</strong>: {{ errorInput.artifact }}</li>
                  <li><strong>Milestone</strong>: {{ errorInput.milestone }}</li>
                  <li><strong>Documents saved</strong>: {{ errorInput.documents }}</li>
                  <li><strong>Expected documents</strong>: {{ errorInput.documentsExp }}</li>
                </ul>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="showInputErrorDialog=false" color="primary">close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import OverlayLoader from '@/components/generic/OverlayLoader.vue';
import { milestonesService } from '@/services/milestonesService';
import { roleManager } from '@/store/roleManager';

export default {
  name: 'MatrixFolder',
  props: {
    zoneItems: {
      type: Array,
      required: true,
    },
    sectionItems: {
      type: Array,
      required: true,
    },
    artifactItems: {
      type: Array,
      required: true,
    },
    studyMilestonesItems: {
      type: Array,
      required: true,
    },
    studyExpectedDocuments: {
      type: Array,
      required: true,
    },
    studyMilestoneRequirements: {
      type: Array,
      required: true,
    },
    studyId: {
      type: Number,
      required: true,
    },
  },
  components: {
    OverlayLoader,
  },
  data() {
    return {
      roleManager,
      alert: false,
      error: '',
      typeError: 'error',
      loading: false,
      search: '',
      //
      showInputErrorDialog: false,
      errorInput: {
        zone: '',
        section: '',
        artifact: '',
        milestone: '',
        documents: 0,
        documentsExp: 0,
      },
      //
      zoneItemsCopy: [],
      sectionItemsCopy: [],
      artifactItemsCopy: [],
      //
      matrixTableHeader: [],
      matrixTableHeaderMileNames: [],
      matrixTableItems: [],
    };
  },
  mounted() {
    this.zoneItemsCopy = [...this.zoneItems];
    this.sectionItemsCopy = [...this.sectionItems];
    this.artifactItemsCopy = [...this.artifactItems];
    this.generateMatrixTableHeader();
    this.generateMatrixTableItems();
  },
  methods: {
    showErrorAlert(msg) {
      this.alert = true;
      this.loading = false;
      this.error = msg;
      console.log(msg);
      setTimeout(() => {
        this.alert = false;
      }, 3000);
    },
    // Generators and gets for init the table
    generateMatrixTableHeader() {
      const rows = [
        // eslint-disable-next-line
        { text: 'Zone', value: 'zone_name', cellClass: 'with-divider', class: 'with-divider' },
        // eslint-disable-next-line
        { text: 'Section', value: 'section_name', cellClass: 'with-divider', class: 'with-divider' },
        // eslint-disable-next-line
        { text: 'Artifact', value: 'artifact_name', cellClass: 'with-divider', class: 'with-divider' },
        // eslint-disable-next-line
        { text: 'Doc. expected', value: 'expected_document_count', cellClass: 'with-divider', class: 'with-divider' },
      ];
      // Add headers
      for (let index = 0; index < this.studyMilestonesItems.length; index += 1) {
        const mile = this.studyMilestonesItems[index];
        const name = mile.milestone_type_name
          ? `${mile.milestone_name} ${mile.milestone_type_name}`
          : `${mile.milestone_name}`;
        // eslint-disable-next-line
        rows.push({ text: name, value: `mile_${mile.id}`, cellClass: 'with-divider', class: 'with-divider' });
        this.matrixTableHeaderMileNames.push(`mile_${mile.id}`);
      }
      this.matrixTableHeader = rows;
    },
    /** Get study expected document object received from backend */
    getDocumentExpectedCell(zoneId, sectionId, artifactId) {
      const item = this.studyExpectedDocuments.filter(
        (se) => se.zone_id === zoneId && se.section_id === sectionId && se.artifact_id === artifactId,
      );
      if (item.length > 0) return item[0];
      return null;
    },
    /** Get study expected document ID received from backend */
    getDocumentExpectedCellId(zoneId, sectionId, artifactId) {
      const item = this.getDocumentExpectedCell(zoneId, sectionId, artifactId);
      if (item) return item.id;
      return null;
    },
    /** Get study milestone cell object received from backend */
    getStudyMilestoneReqCell(zoneId, sectionId, artifactId, sMileId) {
      // eslint-disable-next-line
      zoneId = parseInt(zoneId, 10);
      // eslint-disable-next-line
      sectionId = parseInt(sectionId, 10);
      // eslint-disable-next-line
      artifactId = parseInt(artifactId, 10);
      // eslint-disable-next-line
      sMileId = parseInt(sMileId, 10);
      const item = this.studyMilestoneRequirements.filter(
        (se) => se.zone_id === zoneId
        && parseInt(se.section_id, 10) === sectionId
        && parseInt(se.artifact_id, 10) === artifactId
        && parseInt(se.study_milestone_id, 10) === sMileId,
      );
      return item.length ? item[0] : null;
    },
    getStudyMilestoneReqCellId(zoneId, sectionId, artifactId, sMileId) {
      const item = this.getStudyMilestoneReqCell(zoneId, sectionId, artifactId, sMileId);
      return item ? item.id : null;
    },
    // getStudyMilestoneReq(zoneId, sectionId, artifactId, sMileId) {
    //   // eslint-disable-next-line
    //   sMileId = parseInt(sMileId, 10);
    //   const item = this.studyMilestoneRequirements.filter(
    //     (se) => se.zone_id === zoneId && se.section_id === sectionId && se.artifact_id === artifactId
    //     && se.study_milestone_id === sMileId,
    //   );
    //   if (item.length > 0) return item[0];
    //   return null;
    // },
    getSectionsForZone(zoneId) {
      return this.sectionItemsCopy.filter((section) => section.zone_id === zoneId);
    },
    getArtifactsForSection(sectionId) {
      return this.artifactItemsCopy.filter((artifact) => artifact.section_id === sectionId);
    },
    /** Get all dictionary keys that start with 'mile_' */
    getMilestoneKeysFromDictionaryItemCell(itemCell) {
      return Object.keys(itemCell).filter((key) => key.startsWith('mile_'));
    },
    /** Get the total sum of all milestones in a row */
    getExpectedDocumentTotal(itemCell) {
      let total = 0;
      this.getMilestoneKeysFromDictionaryItemCell(itemCell)
        .forEach((key) => { total += parseInt(itemCell[key].expected_document_count, 10); });
      return total;
    },
    generateMatrixTableItems() {
      const rows = [];
      let rowId = 0;
      for (let zindex = 0; zindex < this.zoneItemsCopy.length; zindex += 1) {
        const zone = this.zoneItemsCopy[zindex];
        const sections = this.getSectionsForZone(zone.id);
        for (let sindex = 0; sindex < sections.length; sindex += 1) {
          const section = sections[sindex];
          const artifacts = this.getArtifactsForSection(section.id);
          for (let aindex = 0; aindex < artifacts.length; aindex += 1) {
            const artifact = artifacts[aindex];
            const docExpCell = this.getDocumentExpectedCell(zone.id, section.id, artifact.id);
            const row = {
              zone_name: zone.name,
              zone_id: zone.id,
              section_name: section.name,
              section_id: section.id,
              artifact_name: artifact.name,
              artifact_id: artifact.id,
              documents_list: docExpCell ? docExpCell.documents_list : null,
              document_count: docExpCell ? docExpCell.document_count : null,
              expected_document_count: docExpCell ? docExpCell.expected_document_count : null,
            };
            let mileExists = false;
            for (let mindex = 0; mindex < this.studyMilestonesItems.length; mindex += 1) {
              const mile = { ...this.studyMilestonesItems[mindex] };
              const mileReq = this.getStudyMilestoneReqCell(zone.id, section.id, artifact.id, mile.id);
              if (mileReq) {
                row[`mile_${mile.id}`] = mile;
                mile.document_count = parseInt(mileReq.document_count, 10);
                mile.expected_document_count = parseInt(mileReq.expected_document_count, 10);
                mile.documents_list = mileReq.documents_list;
                row[this.getStudyMileReqCellDictName(zone.id, section.id, artifact.id, mile.id)] = mileReq.id;
                mileExists = true;
              } /* else {
                row[`mile_${mile.id}`] = {};
              } */
            }
            if (!mileExists) {
              row.doc_exp_type = 'manual';
            } else if (docExpCell) {
              row.doc_exp_type = 'auto';
              row.expected_document_count = this.getExpectedDocumentTotal(row);
            } else {
              row.doc_exp_type = null;
            }
            row.row_id = rowId;
            rows.push(row);
            rowId += 1;
          }
        }
      }
      this.matrixTableItems = rows;
    },
    // Generators, getters and setters for the table
    /** Get name for study milestone in item cell */
    // getStudyMileCellDictName(zoneId, sectionId, artifactId, sMileId) {
    //   const item = this.getStudyMilestoneReqCell(zoneId, sectionId, artifactId, sMileId);
    //   if (item) return `study_mile_cell_${item.id}`;
    //   return null;
    // },
    /** Get name for study milestone in item cell. Values are stored in a more simple dictionary */
    getStudyMileReqCellDictName(zoneId, sectionId, artifactId, sMileId) {
      return `cell_${zoneId}_${sectionId}_${artifactId}_${sMileId}`;
    },
    /** Get name for study doc exp cell. Values are stored in a more simple dictionary */
    /* getStudyDocExpRowDictName(zoneId, sectionId, artifactId) {
      return `cell_${zoneId}_${sectionId}_${artifactId}`;
    }, */
    /** A expected document row can be: auto-add, manual or none */
    // getStudyDocExpRowType(zoneId, sectionId, artifactId) {
    // },
    //
    onStudyMileReqChange(zoneId, sectionId, artifactId) {
      const tableItem = this.matrixTableItems
        .find((item) => item.zone_id === zoneId && item.section_id === sectionId && item.artifact_id === artifactId);
      tableItem.expected_document_count = this.getExpectedDocumentTotal(tableItem);
    },
    checkCellValue(row, key) {
      if (
        (row.expected_document_count < row.document_count)
        || (row[key].document_count !== null && row[key].document_count > 0
        && row[key].document_count > row[key].expected_document_count)
        || (row[key].expected_document_count !== null && row[key].expected_document_count < 0)
      ) {
        this.errorInput.zone = row.zone_name;
        this.errorInput.section = row.section_name;
        this.errorInput.artifact = row.artifact_name;
        if (row.expected_document_count < row.document_count) {
          this.errorInput.documents = row.document_count;
          this.errorInput.documentsExp = row.expected_document_count;
        } else if (key) {
          this.errorInput.documents = row[key].document_count;
          this.errorInput.documentsExp = row[key].expected_document_count;
          this.errorInput.milestone = `${row[key].milestone_name} ${row[key].milestone_type_name || ''}`;
        }
        this.showInputErrorDialog = true;
        return true;
      }
      return false;
    },
    saveMatrix() {
      let err = false;
      const studyExpectedDocumentsList = [];
      const studyMilestoneRequirementCells = [];
      this.matrixTableItems.forEach((row) => {
        if (err) return;
        if (![null, ''].includes(row.expected_document_count)) {
          studyExpectedDocumentsList.push({
            zone_id: row.zone_id,
            section_id: row.section_id,
            artifact_id: row.artifact_id,
            expected_document_count: row.expected_document_count,
          });
        }
        this.getMilestoneKeysFromDictionaryItemCell(row).forEach((key) => {
          if (err) return;
          err = this.checkCellValue(row, key);
          if (![null, ''].includes(row[key].expected_document_count)) {
            studyMilestoneRequirementCells.push({
              zone_id: row.zone_id,
              section_id: row.section_id,
              artifact_id: row.artifact_id,
              study_milestone_id: row[key].id,
              expected_document_count: row[key].expected_document_count,
            });
          }
        });
      });
      if (err) return;
      const data = {
        study_expected_documents: studyExpectedDocumentsList,
        study_milestone_requirements: studyMilestoneRequirementCells,
      };
      this.loading = true;
      milestonesService.updateExpectedDocuments(this.studyId, data)
        .then((response) => {
          console.log(response.data);
          this.loading = false;
          this.$emit('finished');
        })
        .catch((error) => {
          this.loading = false;
          this.showErrorAlert(error.response.data.detail);
        });
    },
    /* addInputEmptyCell(zoneId, sectionId, artifactId, mileId, rowIndex) {
      const cellId = this.getStudyMileReqCellDictName(
        zoneId, sectionId, artifactId, mileId,
      );
      const mid = parseInt(mileId.replace('mile_', ''), 10);
      console.log(zoneId, sectionId, artifactId, mileId, mid, rowIndex, cellId);
      this.matrixTableItems[rowIndex][mileId] = { id: mid, expected_document_count: 1 };
    },
    removeCell(zoneId, sectionId, artifactId, mileId, rowIndex) {
      const cellId = this.getStudyMileReqCellDictName(
        zoneId, sectionId, artifactId, mileId,
      );
      const mid = parseInt(mileId.replace('mile_', ''), 10);
      console.log('R:', zoneId, sectionId, artifactId, mileId, mid, rowIndex, cellId);
      delete this.matrixTableItems[rowIndex][mileId];
    }, */
    // saveForm() {
    //   this.$emit('startLoading');
    // const data = {
    // };
    // fileServices.createStudy(data)
    //   .then((response) => {
    //     this.newStudyResponse = response.data;
    //     this.sendStudyUsers(response.data.id);
    //   })
    //   .catch((error) => {
    //     this.$emit('endLoading');
    //     const k = Object.keys(error.response.data)[0];
    //     const msg = error.response.data[k][0];
    //     this.$emit('errorStudyCreate', msg);
    //   });
    // },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>
<style scoped>
  /deep/ .with-divider {
    border-right: thin solid rgba(0,0,0,0.12);
  }
  /deep/ table {
    tbody {
      tr:nth-child(odd) {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
    .table-empty-cell-container {
      display: table;
      min-height: 23px;
      min-width: 40px;
      .placeholder {
        display: table-cell;
        vertical-align: middle;
        span {
          display: none;
        }
      }
      &:hover {
        .placeholder {
          cursor: pointer;
          span {
            display: block;
          }
        }
    }
  }
}
</style>
