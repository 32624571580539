<template>
  <div>
    <v-list-group :value="true">
      <template v-slot:activator>
          <v-list-item-title>Quality Control Finding</v-list-item-title>
      </template>
      <v-list-item-content>
        <v-list-item>
          <p><strong>Total issues:</strong> {{numberTotalIssues}}</p>
        </v-list-item>
        <v-list-item>
          <p><strong>Closed issues:</strong> {{numberClosedIssues}}</p>
        </v-list-item>
        <v-list-item>
          <p><strong>Open issues:</strong> {{numberOpenIssues}}</p>
        </v-list-item>
        <v-list-item v-if="currentIssues && currentIssues.length > 0 && currentIssues[0] !== ''" class="d-block">
          <p><strong>Current quality issues:</strong></p>
          <div>
            <ul>
              <li v-for="qi in currentIssues" :key="qi">
                {{ parseIdToIssueName(qi) }}
              </li>
            </ul>
          </div>
        </v-list-item>
        <v-list-item v-if="versionsIssues && versionsIssues.length > 0">
          <div class="mt-5">
            <strong>Historic issues by versions:</strong>
            <div class="row">
              <div class="col-12"
                v-for="(issues, version) in versionsIssues" :key="version">
                <p>Version: <strong>{{ version }}</strong></p>
                <div v-if="issues">
                  <p>Issues</p>
                  <ul>
                    <li v-for="qi of issues.split(',')" :key="qi">
                    {{ parseIdToIssueName(qi) }}
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <p>No Issues in this version</p>
                </div>
              </div>
            </div>
          </div>
        </v-list-item>
      </v-list-item-content>
    </v-list-group>
  </div>
</template>
<script>
import { MainIssues } from '@/utils/parseIssues';

export default {
  name: 'QualityIssues',
  data() {
    return {
      numberClosedIssues: 0,
      numberTotalIssues: 0,
      numberOpenIssues: 0,
      versionsIssues: [],
      currentIssues: [],
    };
  },
  props: {
    qcIssueType: String,
    historyDocumentsApproved: Array,
  },
  created() {},
  mounted() {},
  watch: {
    qcIssueType() {
      this.calculateTotalAndOpenIssues();
    },
    historyDocumentsApproved() {
      this.calculateTotalAndOpenIssues();
    },
  },
  methods: {
    calculateTotalAndOpenIssues() {
      this.calculateClosedIssuesFromHistory();
      if (this.qcIssueType === undefined || this.qcIssueType === '' || this.qcIssueType === null) {
        this.currentIssues = [];
      } else {
        this.currentIssues = this.qcIssueType.split(',');
        this.numberOpenIssues = this.currentIssues.length;
      }
      this.numberTotalIssues = this.numberClosedIssues + this.numberOpenIssues;
    },
    calculateClosedIssuesFromHistory() {
      this.versionsIssues = [];
      this.numberClosedIssues = 0;
      if (this.historyDocumentsApproved) {
        this.historyDocumentsApproved.forEach((item) => {
          const issues = item.qc_issue_type;
          if (issues && issues.length > 0) {
            this.versionsIssues[item.version] = issues;
            this.numberClosedIssues += issues ? issues.split(',').length : 0;
          }
        });
      }
    },
    /** Search from issue number to name */
    parseIdToIssueName(id) {
      // eslint-disable-next-line
      return MainIssues.issueNumberToName(parseInt(id));
    },
  },
};
</script>
