<template>
  <v-chip :class="getStatusColorClass()">
    <span>{{ status_name || getStatusName() }}</span>
  </v-chip>
</template>

<script>
import { Constants } from '@/store/constants';

export default {
  name: 'DataStatusLabel',
  data() {
    return {
      Constants,
    };
  },
  props: {
    status_id: {
      type: Number,
      required: false,
    },
    status_name: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    getStatusColorClass() {
      if (this.status_id === Constants.STATUS_IN_PROGRESS_ID
      || this.status_name === Constants.STATUS_IN_PROGRESS) {
        return 'status-label__in-progress';
      }
      if (this.status_id === Constants.STATUS_QUALITY_CHECK_ID
      || this.status_name === Constants.STATUS_QUALITY_CHECK) {
        return 'status-label__quality-check';
      }
      if (this.status_id === Constants.STATUS_PENDING_REVIEW_ID
      || this.status_name === Constants.STATUS_PENDING_REVIEW) {
        return 'status-label__pending-review';
      }
      if (this.status_id === Constants.STATUS_REJECTED_ID
      || this.status_name === Constants.STATUS_REJECTED) {
        return 'status-label__rejected';
      }
      if (this.status_id === Constants.STATUS_APPROVED_ID
      || this.status_name === Constants.STATUS_APPROVED) {
        return 'status-label__approved';
      }
      if (this.status_id === Constants.STATUS_DELETED_ID
      || this.status_name === Constants.STATUS_DELETED) {
        return 'status-label__deleted';
      }
      return 'tfe-gray-light-bg';
    },
    getStatusName() {
      if (this.status_id === Constants.STATUS_UPLOADED_ID) {
        return Constants.STATUS_UPLOADED;
      }
      if (this.status_id === Constants.STATUS_IN_PROGRESS_ID) {
        return Constants.STATUS_IN_PROGRESS;
      }
      if (this.status_id === Constants.STATUS_QUALITY_CHECK_ID) {
        return Constants.STATUS_QUALITY_CHECK;
      }
      if (this.status_id === Constants.STATUS_PENDING_REVIEW_ID) {
        return Constants.STATUS_PENDING_REVIEW;
      }
      if (this.status_id === Constants.STATUS_REJECTED_ID) {
        return Constants.STATUS_REJECTED;
      }
      if (this.status_id === Constants.STATUS_APPROVED_ID) {
        return Constants.STATUS_APPROVED;
      }
      if (this.status_id === Constants.STATUS_DELETED_ID) {
        return Constants.STATUS_DELETED;
      }
      return '--';
    },
  },
};
</script>
