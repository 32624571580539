import Cookies from 'js-cookie';
import { coreServices } from '@/services/coreServices';

export const fileServices = {
  /*
    Inbox
    --------------------------
  */
  getInboxData(onlyCount) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/inbox/?count=${onlyCount ? 1 : 0}`;
    return coreServices.get(url, coreServices.getRequestHeader(), '');
  },
  /*
    UP DL
    --------------------------
  */
  uploadFile(archive) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/`;
    const headers = coreServices.getRequestHeader({
      'Content-Type': 'multipart/form-data',
      'X-CSRFToken': Cookies.get('csrftoken'),
    });
    const responses = [];
    Array.from(archive.files).forEach((element) => {
      const data = new FormData();
      data.append('title', element.name);
      data.append('document_type', element.type);
      data.append('file_location', element); // REVISAR
      data.append('name', element.name);
      data.append('data_created', new Date().toLocaleString('es-ES', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).replace(',', ''));
      data.append('attached_files', archive.files.length);
      responses.push(coreServices.post(url, data, headers));
    });
    return responses;
  },
  downloadTreeFiles(studyId, filePaths) {
    console.log(filePaths.length);
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/studies/${studyId}/download-tree-files/`;
    const headers = coreServices.getRequestHeader({ 'X-CSRFToken': Cookies.get('csrftoken') });
    return coreServices.downloadGetBlob(url, headers);
  },
  /*
    Study
    --------------------------
  */
  getStudies() {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/studies/`;
    return coreServices.get(url, coreServices.getRequestHeader(), '');
  },
  getStudyList() {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/studies/study-list/`;
    return coreServices.get(url, coreServices.getRequestHeader(), '');
  },
  createStudy(data) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/studies/`;
    return coreServices.post(url, data, coreServices.getRequestHeader());
  },
  getStudy(studyId) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/studies/${studyId}/`;
    return coreServices.get(url, coreServices.getRequestHeader(), {});
  },
  updateStudy(studyId, data) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/studies/${studyId}/`;
    return coreServices.put(url, data, coreServices.getRequestHeader());
  },
  deleteStudy(studyId, deleteStudyComment) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/studies/${studyId}/delete/`;
    return coreServices.post(url, { comment: deleteStudyComment }, coreServices.getRequestHeader());
  },
  /*
    Study site
    --------------------------
  */
  getStudySites(studyCountryId = null, studyId = null) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/study-sites/`;
    const params = {};
    if (studyCountryId) {
      params.study_country_id = studyCountryId;
    }
    if (studyId) {
      params.study_id = studyId;
    }
    return coreServices.get(url, coreServices.getRequestHeader(), params);
  },
  setNewStudySite(data) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/study-sites/`;
    return coreServices.post(url, data, coreServices.getRequestHeader());
  },
  deleteStudySite(studySiteId) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/study-sites/${studySiteId}/`;
    return coreServices.delete(url, coreServices.getRequestHeader());
  },
  updateStudySite(studySiteId, data) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/study-sites/${studySiteId}/`;
    return coreServices.put(url, data, coreServices.getRequestHeader());
  },
  /*
    Study country
    --------------------------
  */
  setNewStudyCountry(data) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/study-countries/`;
    return coreServices.post(url, data, coreServices.getRequestHeader());
  },
  getStudyCountryList() {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/study-countries/`;
    return coreServices.get(url, coreServices.getRequestHeader(), '');
  },
  getStudyCountryListFiltered(studyId) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/study-countries/filtered/?study_id=${studyId}`;
    return coreServices.get(url, coreServices.getRequestHeader(), '');
  },
  deleteStudyCountry(studyCountryId) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/study-countries/${studyCountryId}/`;
    return coreServices.delete(url, coreServices.getRequestHeader());
  },
  updateStudyCountry(id, name) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/study-countries/${id}/`;
    return coreServices.put(url, { name: name }, coreServices.getRequestHeader());
  },
  /*
    Study users
    --------------------------
  */
  getStudyUserList(studyId) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/study-users/?study_id=${studyId}`;
    return coreServices.get(url, coreServices.getRequestHeader(), {});
  },
  updateStudyUser(studyId, data) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/study-users/?study_id=${studyId}`;
    return coreServices.post(url, data, coreServices.getRequestHeader());
  },
  /*
    Files
    --------------------------
  */
  /** Get a list of all users that are owners of at least 1 document */
  getKpiOwners() {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/owners/`;
    return coreServices.get(url, coreServices.getRequestHeader(), '');
  },
  /**
   * Get the history of all Data or a specific one
   * @param {int} fileId Id for the Data to filter
   */
  getHistoric(fileId) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/history/`;
    const params = { id: fileId };
    return coreServices.get(url, coreServices.getRequestHeader(), params);
  },
  classifyFile(data) {
    const header = coreServices.getRequestHeader();
    const responses = [];
    data.forEach((element) => {
      const url = `${process.env.VUE_APP_BASE_URL}/api/files/${element.file_id}/classify/`;
      responses.push(coreServices.post(url, element, header));
    });
    return responses;
  },
  getFilesPendingClassify() {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/pending-classify/`;
    return coreServices.get(url, coreServices.getRequestHeader());
  },
  /** Get file library */
  getLibraryData(params) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/`;
    return coreServices.get(url, coreServices.getRequestHeader(), params);
  },
  getFileDates() {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/dates/`;
    return coreServices.get(url, coreServices.getRequestHeader(), '');
  },
  /**
   * Get the list of users that is owner of at least 1 Data object
   * @return axios object
   */
  getFile(fileId) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/${fileId}/`;
    return coreServices.get(url, coreServices.getRequestHeader(), {});
  },
  updateFile(fileId, data) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/${fileId}/update-file/`;
    const headers = coreServices.getRequestHeader({ 'Content-Type': 'multipart/form-data' });
    return coreServices.post(url, data, headers);
  },
  deleteFile(fileId, comment) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/${fileId}/`;
    return coreServices.delete(url, coreServices.getRequestHeader(), { comment });
  },
  // getValidateData(fileId) {
  //   const url = `${process.env.VUE_APP_BASE_URL}/api/files/${fileId}/validated-data/`;
  //   const params = { id: fileId };
  //   return coreServices.get(url, coreServices.getRequestHeader(), params);
  // },
  approveUpdate(fileId) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/${fileId}/approve/`;
    return coreServices.post(url, {}, coreServices.getRequestHeader());
  },
  noApproveUpdate(fileId, data) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/${fileId}/reject/`;
    return coreServices.post(url, data, coreServices.getRequestHeader());
  },
  dataUpdateClassification(fileId, data) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/${fileId}/data-update-classification/`;
    return coreServices.post(url, data, coreServices.getRequestHeader());
  },
  passApproveToOther(fileId, data) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/${fileId}/pass-approve-to-other/`;
    return coreServices.post(url, data, coreServices.getRequestHeader());
  },
  passApproveToOtherList(data) {
    const responses = [];
    data.forEach((element) => {
      responses.push(
        this.passApproveToOther(
          element.file_id,
          { user_rms1_id: element.new_approver_user_id, commentary: element.commentary },
        ),
      );
    });
    return responses;
  },
  getMetadataFiles(folderId) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/metadata-files/`;
    const data = { id: folderId };
    return coreServices.get(url, coreServices.getRequestHeader(), data);
  },
  getFileListStatus(studyId, filters = null) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/file-list-status/`;
    let params = { study_id: studyId };
    if (filters !== null) params = { ...params, ...filters };
    return coreServices.get(url, coreServices.getRequestHeader(), params);
  },
  /*
    Feedback
    --------------------------
  */
  sendEmailFeedback(archive, feedbackComment) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/files/send-email-feedback/`;
    const headers = coreServices.getRequestHeader({
      'Content-Type': 'multipart/form-data',
      'X-CSRFToken': Cookies.get('csrftoken'),
    });
    const data = new FormData();
    data.append('feedback', feedbackComment);
    if (archive) {
      data.append('title', archive.name);
      data.append('file_data', archive);
      data.append('file_type', archive.type);
    }
    return coreServices.post(url, data, headers);
  },
};
