<template>
  <div>
    <app-header/>
    <nav-bar />
    <overlay-loader :loading="loading"></overlay-loader>
    <v-alert class="mt-4" v-if="alert" type="error" transition="scale-transition">{{ error }}</v-alert>
    <div class="content">
      <v-card class="upload-style" @dragover="dragover" @dragleave="dragleave" @drop="drop">
        <v-card-text>
          <v-file-input
            v-model="files"
            id="file"
            ref="file"
            counter
            multiple
            name="fileToUpload"
            placeholder="Select your file"
            prepend-icon="mdi-paperclip"
            outlined
            enctype="multipart/form-data"
            :show-size="1000"
            class="upload-input"
            @change="checkFile"
            @click:clear="onFileClear"
            :accept="Constants.ACCEPTED_FILE_EXTENSIONS.join(', ')">
            <template v-slot:selection="{ index, text }">
              <v-chip v-if="index < 2" dark label small>{{ text }}</v-chip>
              <span v-else-if="index === 2" class="overline grey--text text--darken-3 mx-2">
                +{{ files.length - 2 }} File(s)
              </span>
            </template>
          </v-file-input>
        </v-card-text>
        <v-card-text>
          <AlertMessage
            :colorTextClass="!uploadButtonFail ? '' : 'red--text'"
            :colorIcon="!uploadButtonFail ? 'blue' : 'red'"
            :icon="'info'"
            :message="acceptedFileExtensionInfoMessage"></AlertMessage>
        </v-card-text>
        <v-card-text>
          <pdf-viewer
            v-if="filesEncoded.length > 0" :filesEncoded="filesEncoded">
          </pdf-viewer>
          <div v-if="urls.length > 0">
            <div v-for="url in filesToString" :key="url">
              <img
                draggable="false"
                class="preview-img-visualizate"
                v-show="url"
                type="image/png"
                :src="url"/>
            </div>
          </div>
        </v-card-text>
        <v-card-text>
          <v-checkbox v-model="agree">
            <template v-slot:label >
              <div>
                I agree with the
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      target="_blank"
                      :href="getTermsAndConditionsUrl()"
                      @click.stop
                      v-on="on">
                      terms and conditions
                    </a>
                  </template>
                  Terms and conditions
                </v-tooltip>
              </div>
            </template>
          </v-checkbox>
          <AlertMessage
            v-if="uploadButtonFail"
            :klass="'text-center'"
            :colorTextClass="'red--text'"
            :colorIcon="'red'"
            :icon="'error'"
            :message="acceptedFileExtensionInfoMessage"></AlertMessage>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="text-right">
            <v-btn
              outlined
              rounded
              class="upload-btn"
              :disabled="isUploadButtonDisabled()"
              @click="submitUpload">
              Upload
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>
<script>
import OverlayLoader from '@/components/generic/OverlayLoader.vue';
import AppHeader from '@/components/generic/AppHeader.vue';
import NavBar from '@/components/generic/NavBar.vue';
import PdfViewer from '../components/PreviewPdfFile.vue';
import { fileServices } from '../services/fileServices';
import { accountServices } from '@/services/accountServices';
import { environmentService } from '../services/environmentService';
import AlertMessage from '@/components/generic/AlertMessage.vue';
import { Constants } from '../store/constants';

export default {
  name: 'uploadFiles',
  components: {
    AppHeader,
    NavBar,
    OverlayLoader,
    PdfViewer,
    AlertMessage,
  },
  data() {
    return {
      Constants,
      files: null,
      base64Files: [],
      data: '',
      loading: false,
      agree: false,
      alert: false,
      error: '',
      fileAttachments: [],
      fileExists: true,
      extensions: [],
      urls: [],
      uploadButtonFail: false,
    };
  },
  computed: {
    filesEncoded() {
      return JSON.parse(JSON.stringify(this.base64Files));
    },
    filesToString() {
      const imageFile = [];
      for (let index = 0; index < this.urls.length; index += 1) {
        const file = this.urls[index];
        if (file !== undefined) {
          imageFile.push(`data:image/png;base64,${file}`);
        }
      }
      return imageFile;
    },
    acceptedFileExtensionInfoMessage() {
      return `Only files with extension:
      ${Constants.ACCEPTED_FILE_EXTENSIONS.slice(0, -1).join(', ')}
      or ${Constants.ACCEPTED_FILE_EXTENSIONS.slice(-1)}`;
    },
  },
  methods: {
    isUploadButtonDisabled() {
      if (this.uploadButtonFail) return true;
      if (!this.agree) return true;
      if (this.files === null || this.files.length === 0) return true;
      return false;
    },
    restartFiles() {
      this.extensions = [];
      this.urls = [];
      this.base64Files = [];
      this.$refs.file.files = [];
    },
    onFileClear() {
      this.files = null;
      this.uploadButtonFail = false;
      this.restartFiles();
    },
    async checkFile(files) {
      /* Check that uploaded files are accepted extensions and process them */
      let fail = false;
      // Restart list for new input
      this.restartFiles();
      if (files.length === 0) {
        fail = false;
      }
      files.forEach((element) => {
        const ext = element.name.split('.').pop();
        if (!Constants.ACCEPTED_FILE_EXTENSIONS.includes(`.${ext}`)) {
          fail = true;
          return;
        }
        this.extensions.push(ext);
      });
      this.uploadButtonFail = fail;
      if (fail) {
        /*
        If any file has a not accepted extension, stop here and mark as red the info input file.
        and restart */
        this.restartFiles();
        return;
      }
      const extesionsParsed = JSON.parse(JSON.stringify(this.extensions));
      if (files.length > 0) {
        const promises = [];
        for (let i = 0; i < files.length; i += 1) {
          const file = files[i];
          promises.push(this.fileToBase64(file));
        }
        await Promise.all(promises).then((res) => {
          for (let i = 0; i < res.length; i += 1) {
            if (extesionsParsed[i] === 'pdf') {
              this.base64Files.push(res[i][0]);
            } else {
              this.urls.push(res[i][0]);
            }
          }
        });
      } else {
        this.restartFiles();
      }
    },
    fileToBase64(file) {
      const fileReader = new FileReader();
      const base64String = [];
      return new Promise((resolve) => {
        fileReader.onload = () => {
          const base64 = fileReader.result.replace('data:', '').replace(/^.+,/, '');
          base64String.push(base64);
          resolve(base64String);
        };
        fileReader.readAsDataURL(file);
      });
    },
    submitUpload() {
      const archive = document.querySelector('#file');
      this.loading = true;
      const responses = fileServices.uploadFile(archive);
      Promise.all(responses).then((results) => {
        results.forEach((res) => {
          this.data = res.data.state;
          if (this.data === 'uploadfile') {
            this.loading = false;
          } else {
            this.loading = false;
            accountServices.logout();
          }
        });
        this.$router.push('/library/preclassification');
      }).catch((error) => {
        this.loading = false;
        if (error.response.status === 413) {
          this.error = 'File size is too big, max 32 Mb';
        } else {
          this.error = error.response.data.detail;
        }
        this.alert = true;
        setTimeout(() => {
          this.alert = false;
        }, 5000);
      });
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      document.querySelector('#file').files = event.dataTransfer.files;
      this.files = [...event.dataTransfer.files];
      this.checkFile(this.files);
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    getTermsAndConditionsUrl() {
      const environment = environmentService.getEnvironment();
      if (environment === 'DEVELOP') {
        return 'https://dev.tmfy.sermescro.ai/termsandconditions';
      }
      return 'https://tmfy.sermescro.ai/termsandconditions';
    },
  },
};
</script>
<style scoped>
  .upload-input {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
</style>
