<template lang="">
  <div>
    <overlay-loader :loading="loading"></overlay-loader>
    <v-dialog @click:outside="/*selectedFileVersion = []*/" scrollable max-width="900px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn block outlined v-bind="attrs" v-on="on" rounded>
          <v-icon class="mr-2 mb-1 mdi-19px">mdi-autorenew</v-icon>
          Update document
        </v-btn>
      </template>
      <v-card class="dialog-style" @dragover="dragover" @dragleave="dragleave" @drop="drop">
        <v-card-title>Update document</v-card-title>
        <v-card-text>
          <div class ="update-file">
            <v-file-input
              v-model="file"
              id="uploadFile"
              ref="file"
              name="fileToUpload"
              label="Update file"
              placeholder="Select your file to update document"
              prepend-icon="mdi-autorenew"
              enctype="multipart/form-data"
              class="upload-input"
              @click:clear="onFileClear"
              @change="checkFile"
              underlined
              :accept="Constants.ACCEPTED_FILE_EXTENSIONS.join(', ')"
            ></v-file-input>
          </div>
          <div class="update-file">
            <AlertMessage
            :colorTextClass="!uploadButtonFail ? '' : 'red--text'"
            :colorIcon="!uploadButtonFail ? 'blue' : 'red'"
            :icon="'info'"
            :message="acceptedFileExtensionInfoMessage"
            ></AlertMessage>
          </div>
          <div class="update-file">
              <v-text-field
                v-model="documentExpirationDate"
                label="Expiration date"
                type="date"
                prepend-icon="mdi-calendar-range"
                underlined
              ></v-text-field>
          </div>
          <!-- <div class ="file-history">
            <v-select
              v-model="selectedFileVersion"
              :items="this.fileHistory"
              item-text="fileVersionName"
              item-value="generation"
              return-object
              label="File history"
              prepend-icon="mdi-text-box-search"
              underlined
              :disabled="!(parseInt(version) > 1)"
              @change="getHistoryFileInformation">
              <template v-slot:item="{ item }" >
                <span class="mdi mdi-file-document" style="color:rgb(101,123,219);"></span>
                &nbsp;&nbsp;{{item.fileVersionName}}
              </template>
            </v-select>
          </div>
          <div v-if="Object.keys(selectedFileVersion).length !== 0
            || selectedFileVersion.length !== 0"
            class="preview-file-btn"
            style="margin-left: 160px; margin-bottom: 20px;">
            <v-btn block outlined color="rgb(101,123,219)" rounded @click="previewFile">
              <v-icon class="icons-style">mdi-magnify</v-icon>
              Preview File
            </v-btn>
          </div> -->
        </v-card-text>
        <v-card-actions class="d-block">
          <div style="margin-bottom: 50px;" class="text-center">
            <v-btn
              v-if="!uploadedSuccessFully"
              @click="updateDocument"
              style="margin-left:auto;margin-right:auto;"
              outlined
              rounded>
              Update document
            </v-btn>
            <AlertMessage
              v-else
              :colorTextClass="'dark--text'"
              :colorIcon="'#53BE8C'"
              :icon="'check'"
              :message="'Updated successfully'"
            ></AlertMessage>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import OverlayLoader from '@/components/generic/OverlayLoader.vue';
import { fileServices } from '../services/fileServices';
import AlertMessage from '@/components/generic/AlertMessage.vue';
import { Constants } from '../store/constants';

export default {
  name: 'UpdateDocument',
  components: {
    AlertMessage,
    OverlayLoader,
  },
  props: {
    docId: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: false,
    },
    fileName: {
      type: String,
      required: true,
    },
    docExpired: {
      type: Boolean,
      required: true,
    },
    version: {
      type: String,
      required: true,
    },
    zone: {
      type: String,
      required: true,
    },
    section: {
      type: String,
      required: true,
    },
    artifact: {
      type: String,
      required: true,
    },
    study_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      Constants,
      documentExpirationDate: '',
      // selectedFileVersion: '',
      // fileHistory: [],
      file: null,
      // ancientFile: null,
      uploadButtonFail: false,
      uploadedSuccessFully: false,
      loading: false,
    };
  },
  mounted() {
    // if (parseInt(this.version, 10) > 1) this.getHistoryFileVersion();
  },
  computed: {
    acceptedFileExtensionInfoMessage() {
      return `Only files with extension:
      ${Constants.ACCEPTED_FILE_EXTENSIONS.slice(0, -1).join(', ')}
      or ${Constants.ACCEPTED_FILE_EXTENSIONS.slice(-1)}`;
    },
  },
  methods: {
    async checkFile(file) {
      if (file) {
        const ext = file.name.split('.').pop();
        if (!Constants.ACCEPTED_FILE_EXTENSIONS.includes(`.${ext}`)) {
          this.uploadButtonFail = true;
        }
      }
    },
    onFileClear() {
      this.uploadButtonFail = false;
    },
    updateDocument() {
      this.loading = true;
      const archive = document.querySelector('#uploadFile');
      const formData = new FormData();
      if (!archive.files[0]) return;
      const ext = archive.files[0].name.split('.').pop();
      if (!Constants.ACCEPTED_FILE_EXTENSIONS.includes(`.${ext}`)) {
        this.uploadButtonFail = true;
        return;
      }
      this.uploadButtonFail = false;
      formData.append('version', parseInt(this.version, 10) + 1);
      formData.append('doc_id', this.docId);
      formData.append('file_id', this.id);
      formData.append('file_name', this.fileName);
      formData.append('zone', this.zone);
      formData.append('section', this.section);
      formData.append('artifact', this.artifact);
      formData.append('study_id', this.study_id);
      formData.append('expiration_date', this.documentExpirationDate);
      formData.append('file_location', archive.files[0]);
      formData.append('title', archive.files[0].name);
      formData.append('document_type', archive.files[0].type);
      formData.append('name', archive.files[0].name);
      fileServices.updateFile(this.id, formData)
        .then(() => {
          this.$emit('updateVersion');
          this.uploadedSuccessFully = true;
          this.loading = false;
          setTimeout(() => {
            this.$router.go();
          }, 500);
        })
        .catch((error) => {
          this.loading = false;
          console.log('error updateFile', error);
        });
    },
    // getHistoryFileVersion() {
    //   const pathParams = {
    //     studyId: this.study_id,
    //     zone: this.zone,
    //     section: this.section,
    //     artifact: this.artifact,
    //     fileId: this.$route.params.id,
    //     docId: this.docId,
    //   };
    //   fileServices.getHistoryFileVersion(this.$route.params.id, pathParams)
    //     .then((response) => {
    //       if (response.status === 200) {
    //         this.fileHistory = response.data.map((file) => ({
    //           version: file.version,
    //           name: file.name,
    //           fileVersionName: `Version ${file.version} - ${file.name}`,
    //           generation: file.generation,
    //         }));
    //       }
    //     })
    //     .catch((error) => { console.log('error historyFile', error); });
    // },
    // getHistoryFileInformation() {
    //   const pathParams = {
    //     studyId: this.study_id,
    //     zone: this.zone,
    //     section: this.section,
    //     artifact: this.artifact,
    //     fileId: this.$route.params.id,
    //     docId: this.docId,
    //     generation: this.selectedFileVersion.generation,
    //   };
    //   fileServices.getFileVersion(this.$route.params.id, pathParams)
    //     .then((response) => { this.ancientFile = response.data; })
    //     .catch((error) => { console.log('error historyFile', error); });
    // },
    dragover(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    dragleave(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    drop(e) {
      e.preventDefault();
      e.stopPropagation();
      const dt = e.dataTransfer;
      const { files } = dt;
      [this.file] = files;
    },
    // previewFile() {
    //   const file = new Blob([this.ancientFile], { type: 'application/pdf' });
    //   const fileURL = URL.createObjectURL(file);
    //   window.open(fileURL);
    // },
  },
};
</script>
