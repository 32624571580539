<template>
    <div>
      <app-header />
      <nav-bar />
        <div class="main-container">
          <div class="content">
            <v-col>
              <v-row>
                <v-card v-for="route in routeTabs" :key="route.name" class="home-style-cards">
                  <v-list-item :to="route.path" :class="'title-box ' + route.name">
                    <v-icon>{{ route.icon }}</v-icon>
                    <v-card-title class="card-title-style">{{ route.name }}</v-card-title>
                  </v-list-item>
                  <v-list-item class="mt-1 justify-center notifications-box" v-if="route.name === 'Notifications'">
                    <home-notifications/>
                  </v-list-item>
                  <v-list-item class="mt-0 justify-center calendar-box" v-else-if="route.name === 'Calendar'">
                    <home-calendar id="calendar-id" />
                  </v-list-item>
                  <v-list-item class="mt-0 justify-center" v-else>
                    <kpi-component />
                  </v-list-item>
                  <router-view>{{ route.component }}</router-view>
                </v-card>
              </v-row>
            </v-col>
          </div>
        </div>
    </div>
</template>
<script>
import AppHeader from '@/components/generic/AppHeader.vue';
import HomeCalendar from '@/components/home/HomeCalendar.vue';
import NavBar from '@/components/generic/NavBar.vue';
import HomeNotifications from '@/components/home/HomeNotifications.vue';
import KpiComponent from '@/components/home/KpiComponent.vue';

export default {
  name: 'Home',
  components: {
    AppHeader,
    NavBar,
    HomeNotifications,
    HomeCalendar,
    KpiComponent,
  },
  data() {
    return {
      urgentAlerts: [],
      loading: false,
      routeTabs: [
        {
          name: 'My dashboard', path: '/dashboards', icon: 'mdi-monitor-dashboard',
        },
        {
          name: 'Notifications', path: '/inbox', icon: 'mdi-email',
        },
        {
          name: 'Calendar', path: '/home', icon: 'mdi-calendar',
        },
      ],
    };
  },
};
</script>
<style scoped>
  .main-container {
    margin-top: 15px;
  }
  .home-style-cards {
    flex: 1;
    margin: 10px;
    min-width: 300px;
    flex-wrap: wrap;
    padding: 16px 10px;
    min-height: 800px;
    justify-content: center;
    margin-top: 0 !important;
  }
  .card-title-style {
    margin: 10px;
    color: rgba(0, 0, 0, 0.87);
  }
  .title-box.Calendar {
    &:before {
      background-color: transparent !important;
    }
  }
  #calendar-id {
    width: 100%;
  }
</style>
