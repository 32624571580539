<template>
  <div :class="klass">
    <p :class="colorTextClass">
      <v-icon :color="colorIcon"
      >{{ getIcon() }}</v-icon>
      {{ message }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'AlertMessage',
  props: {
    colorTextClass: {
      type: String,
      default: '',
    },
    colorIcon: {
      type: String,
      default: 'gray',
    },
    message: String,
    icon: String,
    klass: String,
  },
  methods: {
    getIcon() {
      if (this.icon === 'info') {
        return 'mdi-information';
      }
      if (this.icon === 'error') {
        return 'mdi-alert-octagon-outline';
      }
      if (this.icon === 'check') {
        return 'mdi-check-circle-outline';
      }
      return this.icon;
    },
  },
};
</script>
