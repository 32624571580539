import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/Login.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import ForgotPasswordConfirm from '@/views/ForgotPasswordConfirm.vue';
import CheckEmail from '@/views/CheckEmail.vue';
import UpdateExpiredPassword from '@/views/UpdateExpiredPassword.vue';
import Home from '../views/Home.vue';
import Dashboards from '../views/Dashboards.vue';
import Study from '../views/Study.vue';
import AuditTrial from '../views/AuditTrial.vue';
import AuditUsers from '../views/AuditUsers.vue';
import Inbox from '../views/Inbox.vue';
import Library from '../views/Library.vue';
import EditData from '../views/EditData.vue';
import UploadFiles from '../views/UploadFiles.vue';
import Preclassification from '../views/Preclassification.vue';
import Milestones from '../views/Milestones.vue';
import ControlPanel from '../views/ControlPanel.vue';
import UserProfile from '../views/UserProfile.vue';
import VisualizeStudyTree from '../views/VisualizeStudyTree.vue';
import { cookieAsObject } from '../utils/cookieToObject';

Vue.use(VueRouter);
/*
add here guards to disable accesess to routers if user is not logged in
example here https://medium.com/js-dojo/how-to-implement-route-guard-in-vue-js-9929c93a13db
*/
function requireCookie(to, from, next) {
  const cookieObject = cookieAsObject(document.cookie);
  const authorization = cookieObject.Authorization;
  if (authorization == null) {
    next({ name: 'Login' });
  } else {
    next();
  }
}
const routes = [
  {
    path: '/',
    name: 'Start',
    component: Login,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/update-expired-password',
    name: 'UpdateExpiredPassword',
    component: UpdateExpiredPassword,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/check-email',
    name: 'CheckEmail',
    component: CheckEmail,
  },
  {
    path: '/forgot-password/:token/',
    name: 'ForgotPasswordConfirm',
    component: ForgotPasswordConfirm,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    beforeEnter: requireCookie,
  },
  {
    path: '/user-profile',
    name: 'UserProfile',
    component: UserProfile,
    beforeEnter: requireCookie,
  },
  {
    path: '/control-panel',
    name: 'ControlPanel',
    component: ControlPanel,
    beforeEnter: requireCookie,
  },
  {
    path: '/dashboards',
    name: 'Dashboards',
    component: Dashboards,
    beforeEnter: requireCookie,
  },
  {
    path: '/studies',
    name: 'Study',
    component: Study,
    beforeEnter: requireCookie,
  },
  {
    path: '/studies/:id',
    name: 'VisualizeStudyTree',
    component: VisualizeStudyTree,
    beforeEnter: requireCookie,
  },
  {
    path: '/audit-trail',
    name: 'AuditTrial',
    component: AuditTrial,
    beforeEnter: requireCookie,
  },
  {
    path: '/audit-users',
    name: 'AuditUsers',
    component: AuditUsers,
    beforeEnter: requireCookie,
  },
  {
    path: '/inbox',
    name: 'Inbox',
    component: Inbox,
    beforeEnter: requireCookie,
  },
  {
    path: '/library',
    name: 'Library',
    component: Library,
    beforeEnter: requireCookie,
  },
  /* {
    path: '/library/visualize/:id',
    name: 'VisualizeData',
    component: VisualizeData,
    beforeEnter: forbidenVisualize,
  }, */
  {
    path: '/library/edit/:id',
    name: 'EditData',
    component: EditData,
    beforeEnter: requireCookie,
  },
  /* {
    path: '/library/validate/:id',
    name: 'ValidateData',
    component: ValidateData,
    beforeEnter: forbidenValidate,
  }, */
  {
    path: '/library/upload-files',
    name: 'UploadFiles',
    component: UploadFiles,
    beforeEnter: requireCookie,
  },
  {
    path: '/library/preclassification',
    name: 'Preclassification',
    component: Preclassification,
    beforeEnter: requireCookie,
  },
  {
    path: '/milestones',
    name: 'milestones',
    component: Milestones,
    beforeEnter: requireCookie,
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;
