<template>
  <v-card id="create-study-modal-card" class="mt-0">
    <v-alert v-if="alert" class="mt-4" type="error" transition="scale-transition">{{ error }}</v-alert>
    <v-card-title id="create-study-modal-card-title">
      <span id="create-study-modal-card-span" class="text-h5">Create study</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              id="field-study-name"
              v-model="studyNameModel"
              label="Study name*"
              required
              underlined/>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              id="field-study-country"
              v-model="studyCountry_model"
              :items="country"
              item-text="name"
              item-value="id"
              label="Study Country*"
              required
              persistent-hint
              multiple
              underlined/>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              id="milestones"
              v-model="milestonesSelected"
              :items="milestones"
              item-text="name"
              return-object
              label="Select Milestones"
              hint="Pick required milestones in the study"
              persistent-hint
              multiple
              underlined>
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">
                  <span>{{ milestonesSelected.map((m) => m.name).join(', ') }}</span>
                </span>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <study-user ref="studyUserComp"></study-user>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn id="cancel-create-study" outlined rounded color="error" @click="cancel()">Close</v-btn>
      <v-btn id="create-study-btn" @click="createStudy" :disabled="formValid" outlined rounded>Create</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import StudyUser from '@/components/study/StudyUser.vue';
import { fileServices } from '@/services/fileServices';
import { milestonesService } from '@/services/milestonesService';

export default {
  name: 'CreateStudy',
  components: {
    StudyUser,
  },
  data() {
    return {
      alert: false,
      error: '',
      milestones: [],
      milestonesSelected: [],
      search: '',
      studyNameModel: '',
      documentos: [],
      studyCountry_files: [],
      studyCountry_model: [],
      country: [],
      headers: [
        { text: ' ', value: 'actions', sortable: false },
        { text: 'Title', value: 'title_modified' },
        { text: 'Type', value: 'zone_name' },
      ],
      newStudyResponse: {},
    };
  },
  mounted() {
    this.getStudyCountries();
    this.getMilestones();
  },
  methods: {
    getStudyCountries() {
      fileServices.getStudyCountryList()
        .then((response) => { this.country = response.data; })
        .catch((error) => { console.log('error getStudyCountry', error); });
    },
    getMilestones() {
      milestonesService.getMilestones()
        .then((response) => { this.milestones = response.data; })
        .catch((error) => { console.log('error getStudyMilestones', error); });
    },
    /** Return only the selected users */
    filterUsersSelected() {
      return this.$refs.studyUserComp.studyUsers.filter((usr) => usr.is_selected === true);
    },
    createStudy() {
      this.$emit('startLoading');
      const data = {
        name: this.studyNameModel,
        countries: this.studyCountry_model,
        milestones: this.milestonesSelected.map((m) => m.id),
      };
      fileServices.createStudy(data)
        .then((response) => {
          this.newStudyResponse = response.data;
          this.sendStudyUsers(response.data.id);
        })
        .catch((error) => {
          this.$emit('endLoading');
          const k = Object.keys(error.response.data)[0];
          const msg = error.response.data[k][0];
          this.$emit('errorStudyCreate', msg);
        });
    },
    sendStudyUsers(studyId) {
      const data = {
        study_id: studyId,
        users: this.filterUsersSelected().map((s) => s.id),
      };
      fileServices.updateStudyUser(studyId, data).then(() => {
        this.$emit('confirm');
        this.$emit('endLoading');
        this.$emit('newStudyCreate', this.newStudyResponse);
      }).catch((error) => {
        console.log('error createStudy', error);
        this.$emit('endLoading');
      });
    },
    cancel() {
      this.studyNameModel = '';
      this.studyCountry_model = '';
      this.$emit('cancel');
    },
  },
  computed: {
    formValid() {
      return !(
        this.studyNameModel !== ''
        && this.studyCountry_model.length !== 0
        && this.milestonesSelected.length !== 0);
    },
  },
};
</script>
<style scoped>
  .create-study-style {
    display: flex;
    justify-content: flex-end;
    margin-right: 26px;
    padding-bottom: 16px;
  }
</style>
