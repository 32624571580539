<template lang="">
  <div class="main-container">
    <app-header/>
    <nav-bar/>
    <div class="content">
      <overlay-loader :loading="loading"></overlay-loader>
      <v-alert v-if="alert" :type="typeAlert" transition="scale-transition">{{error}}</v-alert>
      <v-card class="card-library-content">
        <v-container fluid>
          <v-row class="col-md-4 d-flex flex-column justify-content-center text-center">
            <!-- <v-card-title class="title_doc">Documents</v-card-title> -->
          </v-row>
          <v-row>
            <v-col cols="8" sm="2" md="2">
              <v-select
                v-model="selectedFilters"
                :items="filters"
                label="Filter"
                prepend-inner-icon="mdi-filter-variant"
                item-text="headerText"
                item-value="headerValue"
                multiple
                underlined></v-select>
            </v-col>
            <v-col>
              <study-filter
                v-if="filterValues.length > 0"
                :studyNameList="filterValues"
                @selectedStudy="sendStudyValueToParent"></study-filter>
            </v-col>
            <v-col cols="8" sm="2" md="4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                hide-details
                underlined></v-text-field>
            </v-col>
            <v-col md="2">
              <download-documents :documents="filterValues"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :sort-by="['upload_date_format']"
                :sort-desc="true"
                :headers="headers"
                :items="filterValues"
                :search="search"
                multi-sort>
                <template v-slot:item.required_verified_copy="{ item }">
                  <boolean-label v-bind:active="item.required_verified_copy"/>
                </template>
                <template v-slot:item.exists_qi="{ item }">
                  <boolean-label v-bind:active="item.exists_qi"/>
                </template>
                <template v-slot:item.isf="{ item }">
                  <boolean-label v-bind:active="item.isf"/>
                </template>
                <template v-slot:item.submit_for_approval_format="{ item }">
                  <span>{{ parseDateToString(item.submit_for_approval_format) }}</span>
                </template>
                <template v-slot:item.doc_date_format="{ item }">
                  <span>{{ parseDateToString(item.doc_date_format) }}</span>
                </template>
                <template v-slot:item.received_date_format="{ item }">
                  <span>{{ parseDateToString(item.received_date_format) }}</span>
                </template>
                <template v-slot:item.upload_date_format="{ item }">
                  <span>{{ parseDateToString(item.upload_date_format) }}</span>
                </template>
                <template v-slot:item.last_modified_format="{ item }">
                  <span>{{ parseDateToString(item.last_modified_format) }}</span>
                </template>
                <template v-slot:item.approval_date_format="{ item }">
                  <span>{{ parseDateToString(item.approval_date_format).split(',')[0] }}</span>
                </template>
                <template v-slot:item.id="{ item }">
                    <div>
                      <router-link class="tab-router-link" :to="'/library/edit/'+item.id+'/'">
                      {{item.id}}
                      </router-link>
                    </div>
                </template>
                <template v-slot:item.title="{ item }">
                    <div>
                      <v-subheader v-if="item.docExpired === true">Expired</v-subheader>
                      <router-link class="tab-router-link" :to="'/library/edit/'+item.id">
                      {{item.title}}
                      </router-link>
                    </div>
                </template>
                <template v-slot:item.deadline_format="{ item }">
                  <v-chip
                    v-if="item.is_urgent && item.status_id !== Constants.STATUS_APPROVED_ID
                    && item.deltaTime < 1" class="red lighten-3">
                    <span>{{ parseDateToString(item.deadline_format).split(',')[0] }}</span>
                  </v-chip>
                  <v-chip v-else-if="item.status_id === Constants.STATUS_APPROVED_ID" class="blue lighten-3">
                    <span>{{ parseDateToString(item.deadline_format).split(',')[0] }}</span>
                  </v-chip>
                  <v-chip
                    v-else-if="item.is_urgent && item.status_id !== Constants.STATUS_APPROVED_ID
                    && item.deltaTime < 2" class="orange lighten-3">
                    <span>{{ parseDateToString(item.deadline_format).split(',')[0] }}</span>
                  </v-chip>
                  <v-chip
                    v-else-if="!item.is_urgent && item.status_id !== Constants.STATUS_APPROVED_ID
                    && item.deltaTime <= 3" class="red lighten-3">
                    <span>{{ parseDateToString(item.deadline_format).split(',')[0] }}</span>
                  </v-chip>
                  <v-chip
                    v-else-if="!item.is_urgent
                    && item.status_id !== Constants.STATUS_APPROVED_ID && item.deltaTime <= 6"
                    class="orange lighten-3">
                    <span>{{ parseDateToString(item.deadline_format).split(',')[0] }}</span>
                  </v-chip>
                  <v-chip v-else>
                    <span>{{ parseDateToString(item.deadline_format).split(',')[0] }}</span>
                  </v-chip>
                </template>
                <template v-slot:item.status_name="{ item }">
                  <data-status-label :status_id="item.status_id"></data-status-label>
                </template>
                <template v-slot:item.actions="{ item }" v-if="roleManager.isAnyAdmin()">
                  <div>
                    <v-icon small @click="deleteItem(item)" color="error">mdi-delete</v-icon>
                  </div>
                </template>
                <template v-slot:item.owner="{ item }">
                  <span v-if="item.owner && item.owner.includes('@anonymize.com')"><i>User deleted</i></span>
                  <span v-else>{{item.owner}}</span>
                </template>
                <template v-slot:item.approver_user_email="{ item }">
                  <span v-if="item.approver_user_email && item.approver_user_email.includes('@anonymize.com')">
                    <i>User deleted</i></span>
                  <span v-else>{{item.approver_user_email}}</span>
                </template>
                <template v-slot:item.last_user_email="{ item }">
                  <span v-if="item.last_user_email && item.last_user_email.includes('@anonymize.com')">
                    <i>User deleted</i></span>
                  <span v-else>{{item.last_user_email}}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
    <v-dialog v-model="deleteDocumentDialog" max-width="900" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title class="tfe-black-color"><strong>Are you sure to delete this document?</strong></v-card-title>
        <v-card-text>
          <p class="tfe-black-color">
            <strong>Document</strong>: #{{ deleteFileItem.id }} {{ deleteFileItem.title }}
          </p>
          <v-text-field v-model="deleteDocumentComment" label="Add a commentary*" underlined clearable required>
        </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="deleteDocumentDialog=false" color="error">Cancel</v-btn>
          <v-btn outlined rounded @click="deleteDocument()" color="primary"
          :disabled="deleteDocumentComment === '' || deleteDocumentComment.length < 1">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import OverlayLoader from '@/components/generic/OverlayLoader.vue';
import DataStatusLabel from '@/components/generic/DataStatusLabel.vue';
import AppHeader from '@/components/generic/AppHeader.vue';
import NavBar from '@/components/generic/NavBar.vue';
import BooleanLabel from '@/components/generic/BooleanLabel.vue';
import DownloadDocuments from '../components/DownloadDocuments.vue';
import StudyFilter from '../components/StudyFilter.vue';
import { fileServices } from '@/services/fileServices';
import { Constants } from '@/store/constants';
import { roleManager } from '@/store/roleManager';

export default {
  name: 'Library',
  components: {
    AppHeader,
    NavBar,
    DownloadDocuments,
    OverlayLoader,
    DataStatusLabel,
    StudyFilter,
    BooleanLabel,
  },
  data() {
    return {
      roleManager,
      Constants,
      actualDate: 0,
      search: '',
      archive: '',
      error: '',
      alert: false,
      typeAlert: 'error',
      loading: false,
      dealtaTime: 0,
      headers: [
        { text: ' ', value: 'actions', sortable: false },
        { text: 'File ID', value: 'id' },
        { text: 'File name', value: 'title', align: 'start' },
        { text: 'Study', value: 'study_name' },
        { text: 'Zone', value: 'zone_name' },
        { text: 'Section', value: 'section_name' },
        { text: 'Artifact', value: 'artifact_name' },
        { text: 'Owner', value: 'owner' },
        { text: 'Status', value: 'status_name' },
        { text: 'Upload date', value: 'upload_date_format' },
        { text: 'Submit for approval', value: 'submit_for_approval_format' },
        { text: 'Last modified', value: 'last_modified_format' },
        { text: 'Approver user', value: 'approver_user_email' },
        { text: 'Last user', value: 'last_user_email' },
        { text: 'Doc date', value: 'doc_date_format' },
        { text: 'Received date', value: 'received_date_format' },
        { text: 'Deadline', value: 'deadline_format' },
        { text: 'Approval date', value: 'approval_date_format' },
        { text: 'ISF', value: 'isf' },
        { text: 'Require Verified', value: 'required_verified_copy' },
        { text: 'Exists QI', value: 'exists_qi' },
      ],
      documents: [],
      select: '',
      filters: [],
      selectedFilters: [],
      documentsFiltered: [],
      selectedStudy: '',
      deleteDocumentDialog: false,
      deleteDocumentComment: '',
      deleteFileItem: {},
    };
  },
  mounted() {
    this.getData();
    this.getHeaders();
  },
  computed: {
    filterValues() {
      const filteredId = [];
      if (this.selectedFilters.length > 0) {
        this.selectedFilters.forEach((filter) => {
          // function to refactor theese lines
          // filteredId = this.getFilteredDocs(filter);
          this.documents.forEach((doc) => {
            if (([null, undefined].includes(doc[filter]) || [null, undefined].includes(this.search === undefined))
            || doc[filter].toString().toLowerCase().includes(this.search.toString().toLowerCase())) {
              filteredId.push(doc.id);
            }
          });
        });
      } else {
        this.headers.forEach((header) => {
          if (header.text !== ' ') {
            // function to refactor theese lines
            // filteredId = this.getFilteredDocs(header);
            this.documents.forEach((doc) => {
              const v = [null, undefined].includes(doc[header.value]) ? '' : doc[header.value];
              if (v.toString().toLowerCase().indexOf(this.search.toLowerCase()) === 0) {
                filteredId.push(doc.id);
              }
            });
          }
        });
      }
      const documentsFiltered = this.documents.filter((doc) => filteredId.includes(doc.id));
      if (this.selectedStudy.length > 0) {
        let documentsFilteredWithStudy = [];
        documentsFilteredWithStudy = documentsFiltered.filter(
          (document) => this.selectedStudy.includes(document.study_name),
        );
        return documentsFilteredWithStudy;
      }
      return documentsFiltered;
    },
  },
  methods: {
    sendStudyValueToParent(selectedStudy) {
      this.selectedStudy = selectedStudy;
    },
    /** Parse date object to string. ES format */
    parseDateToString(obj) {
      if (obj === '--' || obj === undefined) {
        return '--';
      }
      return obj.toLocaleString('es-ES', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    },
    getData() {
      this.loading = true;
      fileServices.getLibraryData(this.archive)
        .then((response) => {
          this.documents = response.data.map((doc) => {
            /* eslint-disable */
            doc.required_verified_copy = doc.required_verified_copy;
            /* eslint-disable */
            doc.isf = doc.isf;
            /* eslint-disable */
            doc.docExpired = (doc.expiration_date !== '' && doc.expiration_date !== null)
            ? new Date() > new Date(doc.expiration_date) : false;
            const sfa = new Date(doc.submit_for_approval);
            /* eslint-disable */
            doc.submit_for_approval_format = this.parseDateToString(sfa).split('T')[0] === 'Invalid Date' ? '--' : sfa;
            /* eslint-disable */
            let docDate = new Date(doc.document_date);
            /* eslint-disable */
            doc.doc_date_format = this.parseDateToString(docDate).split(',')[0] === 'Invalid Date' ? '--' : docDate;
            let receivedDate = new Date(doc.received_date);
            /* eslint-disable */
            doc.received_date_format = this.parseDateToString(receivedDate).split(',')[0] === 'Invalid Date' ?
            '--' : receivedDate;
            /* eslint-disable */
            doc.upload_date_format = new Date(doc.upload_date);
            let approvalDate = new Date(doc.approval_date);
            /* eslint-disable */
            doc.approval_date_format = this.parseDateToString(approvalDate) === 'Invalid Date' ? '--' : approvalDate;
            /* eslint-disable */
            doc.last_modified_format = new Date(doc.last_modified);
            /* eslint-disable */
            doc.deadline_format = new Date(doc.deadline);
            /* eslint-disable */
            doc.last_modified = this.parseDateToString(new Date(doc.timestamp));
            const remainingDays = new Date(doc.deadline) - new Date();
            doc.deltaTime = Math.round(remainingDays / (1000 * 60 * 60 * 24));
            return doc;
          });

          this.loading = false;
        });
    },
    getHeaders() {
      this.headers.map((header) => header.title);
      this.headers.forEach((header) => {
        if (header.text !== ' ') {
          this.filters.push({ headerText: header.text, headerValue: header.value });
        }
      });
    },
    deleteDocument() {
      this.loading = true;
      fileServices.deleteFile(this.deleteFileItem.id, this.deleteDocumentComment)
        .then(() => {
          this.loading = false;
          this.deleteDocumentDialog = false;
          this.alert = true;
          this.typeAlert = 'success';
          this.documents = this.documents.filter((doc) => doc.id !== this.deleteFileItem.id);
          this.error = 'Document deleted succesfully';
          this.deleteFileItem = {};
          setTimeout(() => {
            this.alert = false;
            this.error = '';
          }, 2500);
        })
        .catch(() => {
          this.loading = false;
          this.deleteDocumentDialog = false;
          this.typeAlert = 'error';
          this.error = 'Error deleting document';
          this.alert = true;
          this.deleteFileItem = {};
          setTimeout(() => {
              this.alert = false;
              this.error = '';
          }, 2500);
        });
    },
    deleteItem(item) {
      this.deleteFileItem = item;
      this.deleteDocumentDialog = true;
    },
  },
};
</script>
<style scoped>
  .v-application .tab-router-link {
    text-decoration: none;
  }
  .v-application .tab-router-link:hover{
    text-decoration: underline !important;
    cursor: pointer !important;
  }
</style>
