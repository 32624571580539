<template>
  <v-card align='left' class='information-style'>
    <v-toolbar>
      <v-toolbar-title>Audit Trial</v-toolbar-title>
    </v-toolbar>
    <v-data-table :headers="headers" :items="documents" :search="search" class="mt-4">
      <template v-slot:item.last_user="{ item }">
        <span v-if="item.last_user && item.last_user.includes('@anonymize.com')"><i>User deleted</i></span>
        <span v-else>{{item.last_user}}</span>
      </template>
      <template v-slot:item.approver_user="{ item }">
        <span v-if="item.approver_user && item.approver_user.includes('@anonymize.com')"><i>User deleted</i></span>
        <span v-else>{{item.approver_user}}</span>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>

export default {
  name: 'AuditTrailDocument',
  props: {
    documents: Array,
    headers: Array,
    search: String,
  },
};
</script>
