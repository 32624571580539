<template>
  <v-select
    v-model="selectedStudy"
    :items="processedStudyList"
    label="Filter by study"
    prepend-inner-icon="mdi-filter-variant"
    item-text="headerText"
    item-value="headerValue"
    multiple
    underlined
    @change="sendStudyValueToParent">
  </v-select>
</template>
<script>
export default {
  name: 'StudyFilter',
  components: {},
  props: {
    studyNameList: [],
  },
  data() {
    return {
      studyList: [],
      selectedStudy: '',
      processedStudyList: [],
    };
  },
  created() {
    this.studyNameList.forEach((study) => {
      this.checkIsStudyNameAndAdd(study);
    });
    this.processedStudyList = this.clearListFromDupeNameStudies(this.studyList);
  },
  methods: {
    clearListFromDupeNameStudies(studyNameList) {
      return [...new Set(studyNameList)];
    },
    checkIsStudyNameAndAdd(study) {
      if (study.study_name) {
        this.studyList.push(study.study_name);
      }
    },
    sendStudyValueToParent() {
      this.$emit('selectedStudy', this.selectedStudy);
    },
  },
};
</script>
