<template>
  <div>
    <overlay-loader :loading="loading"></overlay-loader>
    <v-alert v-if="alert" class="mt-4" :type="typeAlert">{{ error }}</v-alert>
    <div id="app-header-div">
      <v-app-bar id="app-header-bar" app class="tfe-bg-gradient" flat extension-height="100%">
        <router-link :to="'/home'">
          <img src='../../assets/img/logo_white.png' alt="" height="100%" class="logo-large" width="110px">
        </router-link>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialogFeedback" width="500">
          <v-card>
            <v-card-title>Send a feedback</v-card-title>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="fullName" underlined readonly required label="From*"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="feedback" underlined clearable required label="Description*"></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-file-input
                    v-model="files" id="file" large enctype="multipart/form-data" underlined label="Attachment">
                    <v-icon large size="50" color="indigo" class="mdi-rotate-90">mdi-paperclip</v-icon>
                    <template v-slot:selection="{ index, text }">
                      <v-chip v-if="index < 2" color="rgb(101,123,219)" dark label small>{{ text }}</v-chip>
                      <span v-else-if="index === 2" class="overline grey--text text--darken-3 mx-2">
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="4">
                  <v-btn outlined @click="sendEmailFeedback" class="mt-4 w-100" rounded>Send</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
        <v-tooltip bottom v-if="this.$route.name !== 'UploadFiles' && uploadEnable">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              rounded
              color="white"
              id="upload-file-app-header-btn"
              class="appbar-style"
              to="/library/upload-files"
              plain
            >
              <v-icon class="icons-style" v-bind="attrs" v-on="on">mdi-upload</v-icon>
            </v-btn>
          </template>
          <span>Upload</span>
        </v-tooltip>
        <v-tooltip bottom v-if="this.$route.name !== 'Preclassification' && uploadEnable">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              rounded
              color="white"
              id="classify-file-app-header-btn"
              class="appbar-style"
              to="/library/preclassification"
              plain>
              <v-icon class="icons-style" v-bind="attrs" v-on="on">mdi-file-document-arrow-right-outline</v-icon>
            </v-btn>
          </template>
          <span>Classify</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              rounded
              dark
              color="white"
              id="user-profile-app-header-btn"
              class="appbar-style"
              plain
              to="/user-profile">
              <v-icon class="icons-style" v-bind="attrs" v-on="on">mdi-account-circle</v-icon>
            </v-btn>
          </template>
          <span>{{register_name}} {{register_last_name}}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="inboxNotifications > 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab rounded dark color="white" to='/inbox' plain class="appbar-style">
              <v-badge color="red accent-5" overlap>
                <span slot="badge">{{ inboxNotifications }}</span>
                <v-icon class="icons-style" v-bind="attrs" v-on="on">mdi-email</v-icon>
              </v-badge>
            </v-btn>
          </template>
            <span>Notifications</span>
        </v-tooltip>
        <v-tooltip v-else bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab dark color="white" id="notifications-app-header-btn" to='/inbox' plain class="appbar-style">
              <v-badge overlap color="transparent">
                <v-icon class="icons-style" v-bind="attrs" v-on="on">mdi-email</v-icon>
              </v-badge>
            </v-btn>
          </template>
            <span>Notifications</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              color="white"
              id="send-feedback-app-header-btn"
              plain
              class="appbar-style"
              v-bind="attrs"
              v-on="on"
              @click="dialogFeedback = true">
              <v-icon class="icons-style mdi-rotate-315">mdi-send-circle</v-icon>
            </v-btn>
          </template>
          <span>Send FeedBack</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              color="white"
              id="logout-app-header-btn"
              @click="logout"
              plain
              class="icons-style appbar-style">
              <v-icon v-bind="attrs" v-on="on">mdi-logout</v-icon>
            </v-btn>
          </template>
          <span>Logout</span>
        </v-tooltip>
      </v-app-bar >
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import OverlayLoader from '@/components/generic/OverlayLoader.vue';
import { accountServices } from '@/services/accountServices';
import { fileServices } from '@/services/fileServices';
import { roleManager } from '@/store/roleManager';

export default {
  name: 'Header',
  components: {
    OverlayLoader,
  },
  data() {
    return {
      roleManager,
      files: null,
      dialogFeedback: false,
      register_name: ' ',
      register_last_name: null,
      inboxNotifications: 0,
      fullName: '',
      feedback: '',
      uploadEnable: false,
      loading: false,
      error: '',
      typeAlert: 'error',
      alert: false,
    };
  },
  mounted() {
    this.checkUserData();
    this.getInboxData();
  },
  methods: {
    sendEmailFeedback() {
      this.loading = true;
      this.dialogFeedback = false;
      const archive = document.querySelector('#file');
      fileServices.sendEmailFeedback(archive.files[0], this.feedback)
        .then(() => {
          this.loading = false;
          this.feedback = '';
          this.files = null;
          this.typeAlert = 'success';
          this.error = 'Email sent successfully';
          this.alert = true;
          setTimeout(() => {
            this.alert = false;
          }, 3000);
        })
        .catch(() => {
          this.loading = false;
          this.typeAlert = 'error';
          this.alert = true;
          this.error = 'error on sending email';
          setTimeout(() => {
            this.alert = false;
          }, 3000);
        });
    },
    getDocuments(e) {
      this.posts = e;
    },
    checkUserData() {
      this.register_name = localStorage.getItem('first_name');
      this.register_last_name = localStorage.getItem('last_name');
      this.fullName = `${this.register_name} ${this.register_last_name}`;
      this.uploadEnable = roleManager.canUpload();
    },
    logout() {
      axios.defaults.withCredentials = false;
      accountServices.logout()
        .then((res) => {
          if (res.request.status === 200) {
            document.cookie.split(';').forEach((c) => {
              document.cookie = `${c.trim().split('=')[0]}=; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
            });
            localStorage.clear();
            this.$router.push('/');
          }
        })
        .catch((err) => { console.log(err); });
    },
    getInboxData() {
      axios.defaults.withCredentials = true;
      fileServices.getInboxData(true)
        .then((response) => { this.inboxNotifications = response.data.count; });
    },
  },
};
</script>
<style scoped>
  .registered-user{
    pointer-events: none;
  }
  .upload-input-feedback {
    margin: 0;
    padding: 0;
  }
  .v-card__subtitle {
    padding: 0;
  }
  .subtitle-description {
    padding-bottom: 16px !important;
  }
</style>
