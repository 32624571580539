<template>
  <div>
    <app-header />
    <nav-bar />
    <div class="content">
      <overlay-loader :loading="loading"></overlay-loader>
      <v-alert v-if="alert" :type="typeAlert" transition="scale-transition">{{ error }}</v-alert>
      <v-container>
          <v-card class="mt-2" v-show="items.length === 0">
            <v-card-text>
              <p class="text-center pt-3">
                <strong>You don't have documents to classify</strong>
              </p>
            </v-card-text>
          </v-card>
        <v-card
          v-show="items.length > 1"
          outlined
          class="rounded-xl"
          elevation="13">
          <v-toolbar flat>
            <v-toolbar-title>Same configuration on all files?</v-toolbar-title>
            <v-spacer></v-spacer>
            <div>
              <v-switch
              v-model="files_w_same_configuration"
              label="Same Configuration"
              hide-details
              ></v-switch>
            </div>
          </v-toolbar>
        </v-card>
        <div v-for="(file, index) in items" :key="file.id">
          <v-card :id="'file-'+file.id" outlined elevation="5" v-show="canShowFileCard(index)">
            <v-card-title>File title:</v-card-title>
            <v-card-text>
              <v-row v-for="(title, titleIndex) in getTileTitles(index)" :key="title.id">
                <v-col cols="1" class="mt-1">
                  <v-btn color="error" outlined rounded @click="deleteItemDialog(title)">
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                  v-model="title.title"
                  underlined
                  v-on:keyup="onKeyup(files_w_same_configuration ? titleIndex : index)"
                  :label="'File name '+(files_w_same_configuration ? titleIndex+1 : index+1)"
                  ></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-text-field
                  v-model="title.extension"
                  underlined
                  readonly
                  :label="'Ext. '+(files_w_same_configuration ? titleIndex+1 : index+1)"
                  class="no-border"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-progress-circular v-if="title.file_exists === null"
                  color="primary" indeterminate size="20" width="3" style="float: left;">
                  </v-progress-circular>
                  <AlertMessage
                  :klass="'mt-1'"
                  :colorIcon="isIndexFileOk(files_w_same_configuration ? titleIndex : index) ? 'green' : 'red'"
                  :icon="isIndexFileOk(files_w_same_configuration ? titleIndex : index) ? 'check' : 'error'"
                  :message="getErrorMessage(files_w_same_configuration ? titleIndex : index)"
                  ></AlertMessage>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <p class="v-card__title p-0 tfe-black-color">File configuration:</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <div class ="select-style">
                    <strong>Study *</strong>
                    <v-autocomplete
                      v-model="items[index].study"
                      :items="study_items"
                      item-text="name"
                      label="Select*"
                      required
                      return-object
                      underlined
                      @change="studyChange(index)"
                      @click:clear="studyClear(index)">
                    </v-autocomplete>
                  </div>
                  <div class ="select-style">
                    <strong>Study Country</strong>
                    <v-autocomplete
                      v-model="items[index].study_country"
                      :items="getStudyCountryFiltered(index)"
                      item-text="name"
                      label="Select"
                      :disabled="!items[index].study"
                      :error-messages="items[index].study ? [] : ['Study must be selected']"
                      return-object
                      underlined
                      clearable
                      @change="studyCountryChange(index)"
                      @click:clear="studyCountryClear(index)">
                    </v-autocomplete>
                  </div>
                  <div class ="select-style">
                    <strong>Study Site</strong>
                    <v-autocomplete
                      v-model="items[index].study_site"
                      :items="getStudySiteFiltered(index)"
                      item-text="name"
                      label="Select"
                      :disabled="!items[index].study_country"
                      :error-messages="items[index].study_country ? [] : ['Study country must be selected']"
                      return-object
                      underlined
                      clearable
                      @click:clear="items[index].study_site = null">
                    </v-autocomplete>
                  </div>
                  <div class="check-style">
                    <v-checkbox label="Urgent" v-model="items[index].urgent"></v-checkbox>
                    <v-checkbox label="ISF" v-model="items[index].isf"></v-checkbox>
                    <v-checkbox label="Require verified copy"
                    v-model="items[index].required_verified_copy"></v-checkbox>
                    <v-checkbox label="Document expire?"
                      v-model="items[index].does_it_expire"
                      @change="items[index].expiration_date = null">
                    </v-checkbox>
                    <div v-if="items[index].does_it_expire">
                      <v-text-field
                        v-model="items[index].expiration_date"
                        label="Expiration date*"
                        type="date"
                        underlined
                        required>
                      </v-text-field>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <div class ="select-style">
                    <strong>Zone*</strong>
                    <v-select
                      v-model="items[index].zone"
                      :items="zone_items"
                      :disabled="!items[index].study"
                      :error-messages="items[index].study ? [] : ['Study must be selected']"
                      item-text="name"
                      menu-props="auto"
                      label="Select"
                      underlined
                      required
                      return-object
                      @change="zoneChange(index)">
                      <template v-slot:item="{ item }" >{{ item.number }} {{ item.name }}</template>
                    </v-select>
                  </div>
                  <div class ="select-style">
                    <strong>Section*</strong>
                    <v-autocomplete
                      v-model="items[index].section"
                      :items="filterSectionsByZoneSelected(index)"
                      :disabled="!items[index].study"
                      :error-messages="items[index].study ? [] : ['Study must be selected']"
                      item-text="name"
                      menu-props="auto"
                      return-object
                      required
                      label="Select"
                      underlined
                      @change="sectionChange(index)">
                      <template v-slot:item="{ item }" >{{ item.number }} {{ item.name }}</template>
                    </v-autocomplete>
                  </div>
                  <div class="select-style">
                    <strong font-family>Artifact*</strong>
                    <v-autocomplete
                      v-model="items[index].artifact"
                      :items="filterArtifactsBySectionSelected(index)"
                      :disabled="!items[index].study"
                      :error-messages="items[index].study ? [] : ['Study must be selected']"
                      item-text="name"
                      menu-props="auto"
                      return-object
                      label="Select"
                      required
                      underlined
                      @change="artifactChange(index)">
                      <template v-slot:item="{ item }" >{{ item.number }} {{ item.name }}</template>
                    </v-autocomplete>
                  </div>
                  <div>
                    <div class ="save-style">
                      <!-- File already exists in the folder -->
                      <!-- <v-btn
                        :id="'file-btn-' + index"
                        :disabled="checkDisabledBtn"
                        outlined
                        rounded
                        @click="submitFile(file.id)">
                        Save File Configuration
                      </v-btn> -->
                    </div>
                  </div>
                  <div class="check-style">
                    <v-checkbox label="Amendment" v-model="items[index].is_amendment"></v-checkbox>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <br/>
        <!-- </div> -->
        </div>
        <div class="text-right" v-if="items.length > 0">
          <v-btn
            :id="'file-btn-save'"
            :disabled="checkDisabledBtn"
            outlined
            rounded
            @click="submitSave()">
            Save file Configurations
          </v-btn>
        </div>
      </v-container>
    </div>
    <v-dialog v-model="deleteDocumentDialogModel" max-width="900" :retain-focus="false">
      <v-card class="">
        <v-card-title class="tfe-black-color"><strong>Are you sure to delete this document?</strong></v-card-title>
        <v-card-text>
          <p class="tfe-black-color">
            <strong>Document</strong>: #{{ deleteFileItem.id }} {{ deleteFileItem.original_title }}
          </p>
          <v-text-field v-model="deleteDocumentComment" label="Add a commentary*" underlined clearable required>
        </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="deleteDocumentDialogModel=false" color="error">Cancel</v-btn>
          <v-btn outlined rounded @click="deleteDocument()" color="primary"
          :disabled="deleteDocumentComment === '' || deleteDocumentComment.length < 1">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import OverlayLoader from '@/components/generic/OverlayLoader.vue';
import AppHeader from '@/components/generic/AppHeader.vue';
import NavBar from '@/components/generic/NavBar.vue';
import { masterdataServices } from '@/services/masterdataServices';
import { fileServices } from '@/services/fileServices';
import AlertMessage from '@/components/generic/AlertMessage.vue';
import { Constants } from '../store/constants';

export default {
  name: 'preclassification',
  components: {
    AppHeader,
    NavBar,
    OverlayLoader,
    AlertMessage,
  },
  data() {
    return {
      alert: false,
      error: '',
      typeAlert: 'error',
      loading: false,
      files_w_same_configuration: true,
      items: [],
      study_items: [],
      study_country_items: [],
      study_site_items: [],
      zone_items: [],
      section_items: [],
      artifact_items: [],
      inputIndex: -1,
      typingTimer: null,
      doneTypingInterval: 1000,
      deleteDocumentDialogModel: false,
      deleteDocumentComment: '',
      deleteFileItem: {},
    };
  },
  computed: {
    checkDisabledBtn() { return this.getWrongFilesList().length > 0 || this.items.length === 0; },
  },
  created() {
  },
  mounted() {
    this.getStudies();
    this.getMasterdata();
    this.getCountries();
    this.getSites();
    this.getFilesToClassify();
  },
  methods: {
    // Get init data
    getMasterdata() {
      masterdataServices.getMasterdata()
        .then((response) => {
          this.zone_items = response.data.zones;
          this.section_items = response.data.sections;
          this.artifact_items = response.data.artifacts;
        })
        .catch((error) => { console.log('error', error); });
    },
    getCountries() {
      fileServices.getStudyCountryList()
        .then((response) => { this.study_country_items = response.data; })
        .catch(() => { });
    },
    getSites() {
      fileServices.getStudySites()
        .then((response) => { this.study_site_items = response.data; })
        .catch(() => { });
    },
    /** Get all files that required to classify from back for this user */
    getFilesToClassify() {
      fileServices.getFilesPendingClassify()
        .then((response) => {
          this.items = response.data.map((file) => {
            const item = { ...file };
            item.original_title = item.title;
            const pieces = item.title.split('.');
            item.extension = `.${pieces.pop()}`;
            item.title = pieces.join();
            item.study = null;
            item.study_country = null;
            item.study_site = null;
            item.zone = null;
            item.section = null;
            item.artifact = null;
            item.urgent = false;
            item.isf = false;
            item.required_verified_copy = false;
            item.does_it_expire = false;
            item.expiration_date = null;
            item.file_exists = true;
            item.is_amendment = false;
            return item;
          });
          this.initValues();
        })
        .catch(() => {});
    },
    initValues() {
      if (this.items.length < 1 || this.study_items.length < 1) return;
      // eslint-disable-next-line
      this.items[0].study = this.study_items[0];
      this.studyChange(0);
    },
    getStudies() {
      fileServices.getStudies()
        .then((response) => { this.initValues(); this.study_items = response.data; })
        .catch(() => {});
    },
    // END Get init data
    /*
     *
      -- -- Functions for rendering the items list -- --
     *
    */
    /** Returns a bool indicating if this card with the file info can be displayed.
     * If there is more than 1, and same config is on, only show 1 card, else all.
     */
    canShowFileCard(index) {
      return index === 0 ? true : !this.files_w_same_configuration;
    },
    /** check if index file is ok to save */
    isIndexFileOk(index) {
      const fe = this.items[index].file_exists;
      const item = this.files_w_same_configuration ? this.items[0] : this.items[index];
      return !fe && item.zone !== null && item.section !== null && item.artifact !== null;
    },
    /** Get all files that are != ok to save */
    getWrongFilesList() {
      const items = [];
      // eslint-disable-next-line
      for (let i = 0; i < this.items.length; i++) {
        if (!this.isIndexFileOk(i)) {
          items.push({ ...this.items[i] });
        }
      }
      return items;
    },
    /** Get all titles to show  */
    getTileTitles(index) {
      if (this.items.length === 0) return [];
      if (this.files_w_same_configuration) return this.items;
      return [this.items[index]];
    },
    /** Get study countries filtered by study selected for index */
    getStudyCountryFiltered(index) {
      if (this.items[index].study === null) return [];
      return this.study_country_items.filter((item) => this.items[index].study.study_country.includes(item.id));
    },
    /** Get study sites filtered by study selected for index */
    getStudySiteFiltered(index) {
      if (this.items[index].study_country === null) return [];
      return this.study_site_items.filter(
        (site) => site.study_country.id === this.items[index].study_country.id
        && site.study.id === this.items[index].study.id
        && this.items[index].study.sites.includes(site.id),
      );
    },
    /** On zone change for index */
    zoneChange(index) {
      this.items[index].section = null;
      this.items[index].artifact = null;
    },
    /** Return sections filtered by the selected zone for index */
    filterSectionsByZoneSelected(index) {
      if (this.items[index].zone === null) return [];
      const zoneId = this.items[index].zone.id;
      return this.section_items.filter((section) => zoneId === section.zone_id);
    },
    /** On section change for index */
    sectionChange(index) {
      this.items[index].artifact = null;
    },
    /** Return artifacts filtered by the selected section for index */
    filterArtifactsBySectionSelected(index) {
      if (this.items[index].section === null) return [];
      const sectionId = this.items[index].section.id;
      return this.artifact_items.filter((artifact) => sectionId === artifact.section_id);
    },
    /** On artifact change for index */
    artifactChange(index) {
      this.checkFileExists(
        this.files_w_same_configuration ? null : index,
      );
    },
    studyChange(index) {
      this.items[index].study_country = null;
      this.items[index].study_site = null;
    },
    studyClear(index) {
      this.items[index].study = null;
      this.items[index].study_country = null;
      this.items[index].study_site = null;
    },
    studyCountryChange(index) {
      this.items[index].study_site = null;
    },
    studyCountryClear(index) {
      this.items[index].study_country = null;
      this.items[index].study_site = null;
    },
    /** Get error message for file */
    getErrorMessage(index) {
      if (this.items.length === 0) return '';
      let item = null;
      if (this.files_w_same_configuration) {
        item = this.items[0]; //eslint-disable-line
      } else {
        item = this.items[index];
      }
      if (item.study === null || item.zone === null || item.section === null || item.artifact === null) {
        return 'You need to fill all the required fields in "File configuration"';
      }
      if (this.items[index].file_exists === true) {
        return 'This file name exists. Please change it by writing one in the text field';
      }
      if (this.items[index].file_exists === null) return '';
      return 'This file is valid';
    },
    /*
     *
      -- --END Functions for rendering the items list -- --
     *
    */
    /** Check if this file exists in this folder for index or all if same config. */
    checkFileExists(index) {
      const responses = [];
      this.loading = true;
      this.items.forEach((file) => {
        if (index !== null) {
          // if this is true, is not the one to check
          if (file.id !== this.items[index].id) return;
        }
        const item = this.files_w_same_configuration ? this.items[0] : file;
        const folder = `${item.study.id}/${item.zone.id}/${item.section.id}/${item.artifact.id}`;
        // --
        // eslint-disable-next-line
        file.file_exists = null;
        fileServices.getMetadataFiles(folder).then((response) => {
          // eslint-disable-next-line
          file.file_exists = response.data.includes(file.title+file.extension);
          responses.push(response);
        }).catch(() => {
          // eslint-disable-next-line
          file.file_exists = true;
        });
      });
      Promise.all(responses).then((results) => {
        console.log('Promise results =>', results);
      });
      this.loading = false;
    },
    submitSave() {
      this.loading = true;
      const fileDataConfig = [];
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      this.items.forEach((file) => {
        const uploadedDate = new Date(file.uploaded_date);
        const daysGap = file.urgent
          ? Constants.DATA_DEADLINE_URGENT_DAYS_GAP : Constants.DATA_DEADLINE_NOT_URGENT_DAYS_GAP;
        const addDeadline = uploadedDate.setDate(uploadedDate.getDate() + daysGap);
        // eslint-disable-next-line
        file.deadline = new Date(addDeadline).toLocaleString('fr-CA', options);
      });

      for (let i = 0; i < this.items.length; i += 1) {
        const formData = {};
        const item = this.files_w_same_configuration ? this.items[0] : this.items[i];
        formData.file_id = this.items[i].id;
        formData.title = this.items[i].title + this.items[i].extension;
        formData.artifact = item.artifact.id;
        formData.section = item.section.id;
        formData.zone = item.zone.id;
        formData.urgent = item.urgent;
        formData.isf = item.isf;
        formData.is_amendment = item.is_amendment;
        formData.require_verified_copy = item.required_verified_copy;
        if (item.study_country) formData.study_country = item.study_country.id;
        if (item.study_site) formData.study_site = item.study_site.id;
        formData.study = item.study.id;
        formData.deadline = item.deadline;
        if (item.does_it_expire) {
          formData.expiration_date = item.expiration_date.toLocaleString('fr-CA', options);
        }
        fileDataConfig.push(formData);
      }
      const responses = fileServices.classifyFile(fileDataConfig);
      Promise.all(responses)
        .then((vals) => {
          if (vals.length === 1) {
            this.$router.push(`/library/edit/${vals[0].data.id}`);
          } else {
            this.$router.push('/library');
          }
        })
        .catch(() => { this.loading = false; });
    },
    // --------------
    /** After editing manually the file name, check for existing name */
    checkNameAfterEdit() {
      const index = this.files_w_same_configuration ? 0 : this.inputIndex;
      if (this.items[index].study != null
      && this.items[index].zone != null
      && this.items[index].section !== null
      && this.items[index].artifact != null) {
        this.checkFileExists(this.inputIndex);
      }
    },
    onKeyup(index) {
      clearTimeout(this.typingTimer);
      this.inputIndex = index;
      this.typingTimer = setTimeout(this.checkNameAfterEdit, this.doneTypingInterval);
    },
    deleteItemDialog(item) {
      this.deleteFileItem = item;
      this.deleteDocumentDialogModel = true;
    },
    deleteDocument() {
      this.loading = true;
      fileServices.deleteFile(this.deleteFileItem.id, this.deleteDocumentComment)
        .then(() => {
          this.loading = false;
          this.deleteDocumentDialogModel = false;
          this.alert = true;
          this.typeAlert = 'success';
          this.items = this.items.filter((doc) => doc.id !== this.deleteFileItem.id);
          this.error = 'Document deleted succesfully';
          this.deleteFileItem = {};
          setTimeout(() => {
            this.alert = false;
            this.error = '';
          }, 5000);
        })
        .catch(() => {
          this.loading = false;
          this.deleteDocumentDialogModel = false;
          this.typeAlert = 'error';
          this.error = 'Error deleting document';
          this.alert = true;
          this.deleteFileItem = null;
          setTimeout(() => {
            this.alert = false;
            this.error = '';
          }, 5000);
        });
    },
  },
};
</script>
<style scoped>
  .select-style {
    margin:  auto;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 120px;
  }
  .save-style {
    width: 250px;
    margin-left: 0px;
    padding-left: 30px;
    margin-top: 6px;
  }
  .check-style {
    margin-left: 15px;
  }
</style>
