<template>
  <div>
    <app-header />
    <nav-bar />
    <div class="content">
      <v-alert v-if="alert" :value="alert" :type="typeAlert" transition="scale-transition">{{error}}</v-alert>
      <overlay-loader :loading="loading"></overlay-loader>
      <v-card id="show-study-tree-card">
        <v-card-title class="indigo white--text text-h5 card-tree-title" id="study-tree-card-title">
          Study Tree Directory
          <v-spacer></v-spacer>
          <v-btn outlined rounded color="white" @click="downloadTree" id="download-tree-btn">
            <v-icon class="mr-2">mdi-cloud-download</v-icon>
            <span class="mr-2 download-tree-style" id="download-tree-files">Download tree files</span>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <form  @submit.prevent="submit">
                  <v-row>
                    <v-col cols="5">
                      <v-select
                        underlined
                        v-model="filters.country_id"
                        item-text="name"
                        item-value="id"
                        clearable
                        @click:clear="filters.site = null"
                        @change="filters.site = null"
                        prepend-inner-icon="mdi-filter-variant"
                        :items="countries"
                        label="Filter by countries"></v-select>
                    </v-col>
                    <v-col cols="5">
                      <v-select
                        v-model="filters.site_id"
                        :items="sitesFiltered"
                        underlined
                        clearable
                        prepend-inner-icon="mdi-filter-variant"
                        item-text="name"
                        item-value="id"
                        label="Filter by Sites"></v-select>
                    </v-col>
                    <v-col cols="2" class="pt-6 ">
                      <v-btn outlined rounded block @click="submitFilters"><v-icon>mdi-magnify</v-icon> Filter</v-btn>
                    </v-col>
                  </v-row>
                </form>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" class="pt-6">
                <v-treeview
                  dense
                  id="study-tree-treeview"
                  :open.sync="initiallyOpenSync"
                  :items="studyItems"
                  item-key="displayName"
                  item-children="children"
                  item-text="displayName"
                  :active.sync="activeTreeObject"
                  hoverable
                  activatable
                  transition
                  ref="tree"
                  color="primary">
                  <template v-slot:label="{ item }"><span>{{item.label || item.displayName}}</span></template>
                  <template v-slot:prepend="{ item, open }">
                    <v-icon v-if="isFile(item.name)">{{ files[item.name.split('.').pop()] }}</v-icon>
                    <v-icon v-else>{{ open ? 'mdi-folder-open' : 'mdi-folder' }}</v-icon>
                  </template>
                </v-treeview>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="7" class="text-center pt-2">
                <v-scroll-y-transition mode="out-in">
                  <div v-if="!fileSelected" class="text-h6 font-weight-light pt-6">Select file to show information</div>
                  <v-card v-else :key="fileSelected.id" class="preview-studytree" flat>
                    <div class="embed-container">
                      <object v-if="fileSelected.title.includes('pdf') === true" v-bind:data="fileView"
                        type="application/pdf">
                      </object>
                      <img class="preview-img-study-tree" v-show="fileSelected.title.includes('pdf') !== true"
                        type="image/png" :src="fileView"/>
                    </div>
                    <v-divider></v-divider>
                    <v-row class="text-left pt-4">
                      <v-col cols="12"><strong>Document ID</strong>: {{ fileSelected.id }}</v-col>
                      <v-col cols="12" class="pt-0"><strong>File name</strong>: {{ fileSelected.title }}</v-col>
                      <v-col cols="12" class="pt-0"><strong>Status</strong>:
                        <data-status-label :status_name="fileSelected.status_name"></data-status-label></v-col>
                      <v-col cols="12" class="text-center">
                        <v-btn
                          class="mx-auto mb-4"
                          :href="fileView"
                          outlined
                          rounded
                          color="primary"
                          @click="$emit('download')"
                          target="_blank">
                          <v-icon>mdi-download</v-icon> Download File
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-scroll-y-transition>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import AppHeader from '@/components/generic/AppHeader.vue';
import NavBar from '@/components/generic/NavBar.vue';
import OverlayLoader from '@/components/generic/OverlayLoader.vue';
import { fileServices } from '@/services/fileServices';
import DataStatusLabel from '@/components/generic/DataStatusLabel.vue';
import { masterdataServices } from '@/services/masterdataServices';

export default {
  name: 'VisualizeStudyTree',
  components: {
    AppHeader,
    NavBar,
    OverlayLoader,
    DataStatusLabel,
  },
  data() {
    return {
      studyId: this.$route.params.id,
      files: {
        pdf: 'mdi-file-pdf-box',
        png: 'mdi-file-png-box',
        jpg: 'mdi-file-jpg-box',
        jpeg: 'mdi-file-jpg-box',
      },
      loading: false,
      studyName: '',
      countries: [],
      sites: [],
      filters: { country_id: null, site_id: null },
      // licenceName: '',
      zoneItems: [],
      sectionItems: [],
      artifactItems: [],
      fileItems: [],
      /* eslint-disable-next-line */
      studyItems: [{ children: [], name: '', id: 0, displayName: '' }],
      activeTreeObject: [],
      fileView: '',
      filePathsOnlyFiles: [],
      treeLoaded: false,
      initiallyOpen: [],
      fileSelected: null,
      alert: false,
      typeAlert: 'success',
      error: '',
    };
  },
  created() {
    this.treeLoaded = false;
    this.getStudyName();
    this.getFilterData();
  },
  watch: {
    activeTreeObject: {
      handler(newItem, oldItem) {
        if (newItem !== oldItem) {
          this.fileSelected = this.fileItems.find((item) => newItem[0] === item.displayName) || null;
          if (this.fileSelected) this.checkFile(this.fileSelected.bucket_file_name);
        }
      },
    },
  },
  computed: {
    sitesFiltered() {
      if (this.filters.country_id !== null) {
        return this.sites.filter((item) => item.study_country.id === this.filters.country_id);
      }
      return this.sites;
    },
    initiallyOpenSync: {
      get() {
        return this.treeLoaded ? this.initiallyOpen : [];
      },
      set(n, o) {
        if (n !== o && this.treeLoaded) {
          this.initiallyOpen = n;
        }
      },
    },
  },
  methods: {
    showErrorAlert(typeAlert, msg) {
      this.alert = true;
      this.loading = false;
      this.typeAlert = typeAlert;
      this.error = msg;
      setTimeout(() => {
        this.alert = false;
      }, 3000);
    },
    isFile(name) {
      return name && (name.includes('pdf') || name.includes('jpg')
      || name.includes('png') || name.includes('PNG'));
    },
    getFilterData() {
      fileServices.getStudyCountryListFiltered(this.studyId)
        .then((response) => { this.countries = response.data; })
        .catch(() => { this.showErrorAlert('error', 'Error getting filters data'); });
      fileServices.getStudySites(null, this.studyId)
        .then((response) => { this.sites = response.data; })
        .catch(() => { this.showErrorAlert('error', 'Error getting filters data'); });
    },
    getStudyName() {
      this.loading = true;
      fileServices.getStudy(this.studyId)
        .then((response) => {
          this.loading = false;
          this.studyName = response.data.name;
          // this.licenceName = response.data.licence.name;
          this.studyItems[0].id = response.data.id;
          this.studyItems[0].name = this.studyName;
          this.studyItems[0].displayName = this.studyName;
          this.studyItems[0].path = `${this.studyName}`;
          this.getMasterdata();
        })
        .catch(() => { this.showErrorAlert('error', 'Error getting study info'); });
    },
    getFileItems() {
      this.loading = true;
      this.filePathsOnlyFiles = [];
      fileServices.getFileListStatus(this.studyId, this.filters)
        .then((response) => {
          this.loading = false;
          this.fileItems = response.data.map((fitem) => {
            const label = `#${fitem.id} ${fitem.title}`;
            /* eslint-disable-next-line */
            fitem.displayName = label;
            /* eslint-disable-next-line */
            fitem.label = label;            
            this.filePathsOnlyFiles.push(
              `${fitem.tree_id_path}/${fitem.bucket_file_name}`,
            );
            return fitem;
          });
          this.generateFolderPaths();
        })
        .catch(() => { this.showErrorAlert('error', 'Error getting file list'); });
    },
    getMasterdata() {
      this.loading = true;
      masterdataServices.getMasterdata().then((response) => {
        this.loading = false;
        this.zoneItems = response.data.zones.map((zitem) => {
          /* eslint-disable-next-line */
          zitem.displayName = `${zitem.number}.${zitem.name} #${zitem.id}`;
          /* eslint-disable-next-line */
          zitem.label = `${zitem.number}.${zitem.name}`;
          return zitem;
        });
        this.sectionItems = response.data.sections.map((sitem) => {
          /* eslint-disable-next-line */
          sitem.displayName = `${sitem.number}.${sitem.name} #${sitem.id}`;
          /* eslint-disable-next-line */
          sitem.label = `${sitem.number}.${sitem.name}`;
          return sitem;
        });
        this.artifactItems = response.data.artifacts.map((aitem) => {
          /* eslint-disable-next-line */
          aitem.displayName = `${aitem.number}.${aitem.name} #${aitem.id}`;
          /* eslint-disable-next-line */
          aitem.label = `${aitem.number}.${aitem.name}`;
          return aitem;
        });
        this.getFileItems();
      })
        .catch(() => { this.showErrorAlert('error', 'Error getting main folders'); });
    },
    getFileForArtefact(artifactId) {
      const self = this;
      const items = this.fileItems.filter((item) => item.artifact_id === artifactId);
      items.forEach((item) => {
        const z = self.zoneItems.find((zitem) => zitem.id === item.zone_id).displayName;
        if (!(z in self.initiallyOpen)) self.initiallyOpen.push(z);
        const s = self.sectionItems.find((sitem) => sitem.id === item.section_id).displayName;
        if (!(s in self.initiallyOpen)) self.initiallyOpen.push(s);
        const a = self.artifactItems.find((aitem) => aitem.id === item.artifact_id).displayName;
        if (!(a in self.initiallyOpen)) self.initiallyOpen.push(a);
      });
      return items;
    },
    getArtifactChildren(sectionId) {
      const self = this;
      return this.artifactItems.filter((aitem) => aitem.section_id === sectionId).map((aitem) => {
        /* eslint-disable-next-line */
        aitem.children = self.getFileForArtefact(aitem.id);
        return aitem;
      });
    },
    getSectionChildren(zoneId) {
      return this.sectionItems.filter((sitem) => sitem.zone_id === zoneId).map((sitem) => {
        /* eslint-disable-next-line */
        sitem.children = this.getArtifactChildren(sitem.id);
        return sitem;
      });
    },
    generateFolderPaths() {
      this.loading = true;
      const self = this;
      this.studyItems[0].children = [];
      this.initiallyOpen = [];
      this.$refs.tree.updateAll(true);
      this.studyItems[0].children = this.zoneItems.map((zitem) => {
        /* eslint-disable-next-line */
        zitem.children = self.getSectionChildren(zitem.id);
        return zitem;
      });
      this.loading = false;
      this.sortStudyItems(this.studyItems[0]);
      const index = this.initiallyOpen.indexOf(this.studyName);
      if (index > -1) this.initiallyOpen.splice(index, 1);
      this.treeLoaded = true;
    },
    checkFile(activeNodeTitle) {
      this.fileView = `${process.env.VUE_APP_BASE_URL}/api/files/get-file-by-name/${activeNodeTitle}/`;
      if (activeNodeTitle !== undefined && activeNodeTitle.includes('.pdf') === true) {
        this.fileView += '?#pagemode=none&zoom=50';
      }
    },
    downloadTree() {
      this.loading = true;
      fileServices.downloadTreeFiles(this.studyId, this.filePathsOnlyFiles)
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/zip' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `tree_${this.studyItems[0].name}.zip`;
          link.click();
          this.showErrorAlert('success', '.zip downloaded');
        }).catch(() => {
          this.showErrorAlert('error', 'Error getting .zip file');
        });
    },
    sortStudyItems(element) {
      if ('children' in element) {
        element.children.sort((a, b) => (a.number > b.number ? 1 : -1));
        element.children.forEach((item) => {
          this.sortStudyItems(item);
        });
      }
    },
    submitFilters() {
      this.treeLoaded = false;
      this.getFileItems();
    },
  },
};
</script>
<style scoped>
.embed-container {
  width: 100%;
  margin-bottom:10px;
  overflow-x: scroll;
  overflow-y: hidden;
  position:relative;
}
.embed-container object {
  min-height: 700px;
  width: 100%;
}
.card-tree-title {
  color: white !important;
  background: #2AB3BA !important;
}
.download-tree-style {
  font-size: 14px !important;
}
.preview-studytree {
  max-width: 100%;
  min-height: 600px;
  margin-left: 2rem;
  margin-top: 0 !important;
  overflow-y: auto;
}
.preview-img-study-tree {
  height: 100% !important;
  max-width: 100%;
  overflow-x: auto;
}
@media screen and (min-width: 1400px){
  .preview-studytree {
    min-height: 600px;
    overflow-y: auto;
  }
}
.menu-missing-files {
  margin-top: 200px!important;
}
.v-menu__content {
  margin-top: 70px!important;
}
</style>
