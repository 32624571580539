<template>
  <div>
    <overlay-loader :loading="loading"></overlay-loader>
    <template>
      <v-sheet id="home-calendar-sheet" height="64">
        <v-toolbar flat>
          <v-toolbar-title id="home-calendar-title" v-if="$refs.calendar">
              {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-btn id="home-calendar-prev-btn" fab text small rounded color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn id="home-calendar-next-btn" fab text small rounded color="grey darken-2" @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600" id="home-calendar-sheet-body">
          <v-calendar
          locale="en"
          id="home-calendar-v-calendar"
          v-model="value"
          ref="calendar"
          color="rgba(168,204,71,0.8)"
          :type="calendarType"
          :weekdays="weekday"
          :events="eventData"
          @change="getEventsData"
          ></v-calendar>
      </v-sheet>
    </template>
  </div>
</template>
<script>
import OverlayLoader from '@/components/generic/OverlayLoader.vue';
import { fileServices } from '@/services/fileServices';

export default {
  name: 'HomeCalendar',
  components: {
    OverlayLoader,
  },
  data() {
    return {
      value: '',
      colors: '#A8CC47',
      eventData: [],
      calendarType: 'month',
      weekday: [1, 2, 3, 4, 5],
      loading: false,
    };
  },
  methods: {
    getEventsData() {
      this.loading = true;
      fileServices.getLibraryData('')
        .then((response) => {
          this.eventData = response.data
            .filter((item) => item.approval_date === '--')
            .map((item) => {
              const uploadFileDate = item.last_modified;
              const deadlineData = item.deadline;
              const completeDate = uploadFileDate.split('T')[0];
              const year = completeDate.split('-')[0];
              const month = completeDate.split('-')[1];
              const day = completeDate.split('-')[2];
              const completeDateTime = uploadFileDate.split('T')[1];
              const hours = completeDateTime.split(':')[0];
              const minutes = completeDateTime.split(':')[1];
              const startDate = `${year}-${month}-${day} ${hours}:${minutes}`;
              // const completeDeadlineDate = deadlineData.split('T')[0];
              // const deadlineYear = completeDeadlineDate.split('-')[0];
              // const deadlineMonth = completeDeadlineDate.split('-')[1];
              // const deadlineDay = completeDeadlineDate.split('-')[2];
              // const deadlineDate = `${deadlineYear}-${deadlineMonth}-${deadlineDay}`;
              // let actualDate = new Date(deadlineDate).getTime() - new Date().getTime();
              // const fileId = response.data[i].id;
              // actualDate = Math.round(actualDate / (1000 * 60 * 60 * 24));
              return {
                name: item.title,
                start: new Date(startDate),
                end: new Date(deadlineData),
                color: this.colors,
                timed: false,
              };
            });
          this.loading = false;
        }).catch((error) => { console.log('error getEventsData', error); });
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
  },
};
</script>
<style>
#home-calendar-sheet {
  width: 100%;
}
</style>
