<template lang="">
  <v-app>
    <div class="split left tfe-bg-gradient">
      <div class="centered">
        <img
          class="responsive-img"
          alt="Tmfy logo"
          src="../assets/img/logo_white.png"
          height="45px"
          width="150px"
          />
      </div>
    </div>
    <div class="split right">
      <div class="forgotPassword">
        <v-form class="form-forgot-password">
          <div class="div-avatar-style">
            <v-avatar color="primary" class="change-font" size="100">
              <v-icon size="50" color="white">mdi-key-variant</v-icon>
            </v-avatar>
          </div>
          <v-row>
            <v-col class="forgot-pass-colstyle" md="6" offset-md="3">
              <span class="forgot-pass-title">Forgot Your Password?</span>
              <v-text-field
              v-model="email"
              label="E-mail*"
              class="form-input pt-5"
              id="email"
              placeholder="Email"
              prepend-icon="mdi-account"
              required
              underlined></v-text-field>
              <span class="muted-text">
                Enter your primary SermesCRO email address and we will send you a reset.
              </span>
              <div class="pt-5 center-text">
                <v-btn
                  outlined
                  rounded
                  @click="resetPassword"
                  :disabled="formValid">
                  Reset password
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
        <div class=" signin-text text-center">
          Already have login and password? <a class="signin-hyperlink" href="/login">Sign in</a>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>

import { accountServices } from '@/services/accountServices';

export default {
  data() {
    return {
      email: '',
    };
  },
  methods: {
    resetPassword() {
      const formData = { email: this.email };
      accountServices.resetPassword(formData);
      this.$router.push('/check-email');
    },
  },
  computed: {
    formValid() {
      return !(this.email !== '');
    },
  },

};
</script>
<style scoped>
  .form-forgot-password{
    width: auto !important;
  }
  .div-avatar-style{
    margin:  1em 0;
    width: 100% !important;
    display: flex;
  }
  .change-font{
    margin: auto !important;
  }
  .forgot-pass-title {
    font-size: 32px;
    margin-bottom: 20em !important;
  }
  .forgot-pass-colstyle{
    text-align: center !important;
    align-items: center !important;
  }
  .muted-text{
    font-size: 18px;
    color: black;
  }
  .signin-text {
    padding-top: 1em;
    text-decoration: none;
  }
  .reset-pass-btn{
    margin-top: 20em;
  }
  .color--white{
    color: white;
  }
</style>
