import { coreServices } from '@/services/coreServices';

export const masterdataServices = {
  getMasterdata() {
    const url = `${process.env.VUE_APP_BASE_URL}/api/masterdata/data/`;
    return coreServices.get(url, coreServices.getRequestHeader(), '');
  },
  getDefaultKpiDays() {
    const url = `${process.env.VUE_APP_BASE_URL}/api/masterdata/default-kpi/`;
    return coreServices.get(url, coreServices.getRequestHeader());
  },
};
