<template>
  <v-overlay :value="loading" z-index=1000>
    <scale-loader :loading="loading" :color="color" :size="size"></scale-loader>
  </v-overlay>
</template>

<script>
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';

export default {
  name: 'OverlayLoader',
  components: {
    ScaleLoader,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    size: {
      type: String,
      default: '45px',
    },
    color: {
      type: String,
      default: 'rgb(101,123,219)',
    },
  },
  methods: {},
};
</script>
