var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('overlay-loader',{attrs:{"loading":_vm.loading}}),(_vm.alert)?_c('v-alert',{staticClass:"mt-4",attrs:{"type":_vm.typeAlert}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('div',{attrs:{"id":"app-header-div"}},[_c('v-app-bar',{staticClass:"tfe-bg-gradient",attrs:{"id":"app-header-bar","app":"","flat":"","extension-height":"100%"}},[_c('router-link',{attrs:{"to":'/home'}},[_c('img',{staticClass:"logo-large",attrs:{"src":require("../../assets/img/logo_white.png"),"alt":"","height":"100%","width":"110px"}})]),_c('v-spacer'),_c('v-spacer'),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialogFeedback),callback:function ($$v) {_vm.dialogFeedback=$$v},expression:"dialogFeedback"}},[_c('v-card',[_c('v-card-title',[_vm._v("Send a feedback")]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"underlined":"","readonly":"","required":"","label":"From*"},model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=$$v},expression:"fullName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"underlined":"","clearable":"","required":"","label":"Description*"},model:{value:(_vm.feedback),callback:function ($$v) {_vm.feedback=$$v},expression:"feedback"}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-file-input',{attrs:{"id":"file","large":"","enctype":"multipart/form-data","underlined":"","label":"Attachment"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"rgb(101,123,219)","dark":"","label":"","small":""}},[_vm._v(_vm._s(text))]):(index === 2)?_c('span',{staticClass:"overline grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" File(s) ")]):_vm._e()]}}]),model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}},[_c('v-icon',{staticClass:"mdi-rotate-90",attrs:{"large":"","size":"50","color":"indigo"}},[_vm._v("mdi-paperclip")])],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"mt-4 w-100",attrs:{"outlined":"","rounded":""},on:{"click":_vm.sendEmailFeedback}},[_vm._v("Send")])],1)],1)],1)],1)],1),(this.$route.name !== 'UploadFiles' && _vm.uploadEnable)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"appbar-style",attrs:{"fab":"","dark":"","rounded":"","color":"white","id":"upload-file-app-header-btn","to":"/library/upload-files","plain":""}},[_c('v-icon',_vm._g(_vm._b({staticClass:"icons-style"},'v-icon',attrs,false),on),[_vm._v("mdi-upload")])],1)]}}],null,false,4246661787)},[_c('span',[_vm._v("Upload")])]):_vm._e(),(this.$route.name !== 'Preclassification' && _vm.uploadEnable)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"appbar-style",attrs:{"fab":"","dark":"","rounded":"","color":"white","id":"classify-file-app-header-btn","to":"/library/preclassification","plain":""}},[_c('v-icon',_vm._g(_vm._b({staticClass:"icons-style"},'v-icon',attrs,false),on),[_vm._v("mdi-file-document-arrow-right-outline")])],1)]}}],null,false,2693270841)},[_c('span',[_vm._v("Classify")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"appbar-style",attrs:{"fab":"","rounded":"","dark":"","color":"white","id":"user-profile-app-header-btn","plain":"","to":"/user-profile"}},[_c('v-icon',_vm._g(_vm._b({staticClass:"icons-style"},'v-icon',attrs,false),on),[_vm._v("mdi-account-circle")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.register_name)+" "+_vm._s(_vm.register_last_name))])]),(_vm.inboxNotifications > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"appbar-style",attrs:{"fab":"","rounded":"","dark":"","color":"white","to":"/inbox","plain":""}},[_c('v-badge',{attrs:{"color":"red accent-5","overlap":""}},[_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(_vm._s(_vm.inboxNotifications))]),_c('v-icon',_vm._g(_vm._b({staticClass:"icons-style"},'v-icon',attrs,false),on),[_vm._v("mdi-email")])],1)],1)]}}],null,false,1698621796)},[_c('span',[_vm._v("Notifications")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"appbar-style",attrs:{"fab":"","dark":"","color":"white","id":"notifications-app-header-btn","to":"/inbox","plain":""}},[_c('v-badge',{attrs:{"overlap":"","color":"transparent"}},[_c('v-icon',_vm._g(_vm._b({staticClass:"icons-style"},'v-icon',attrs,false),on),[_vm._v("mdi-email")])],1)],1)]}}])},[_c('span',[_vm._v("Notifications")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"appbar-style",attrs:{"fab":"","dark":"","color":"white","id":"send-feedback-app-header-btn","plain":""},on:{"click":function($event){_vm.dialogFeedback = true}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"icons-style mdi-rotate-315"},[_vm._v("mdi-send-circle")])],1)]}}])},[_c('span',[_vm._v("Send FeedBack")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"icons-style appbar-style",attrs:{"fab":"","dark":"","color":"white","id":"logout-app-header-btn","plain":""},on:{"click":_vm.logout}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-logout")])],1)]}}])},[_c('span',[_vm._v("Logout")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }