<template>
  <div>
    <app-header />
    <nav-bar />
    <v-container>
      <v-alert v-if="alert" class="mt-4" type="success">Successfully changed</v-alert>
      <v-card class="profile-style">
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  v-model="fullName"
                  append-outer-icon="mdi-pencil"
                  @click:append-outer=updateName
                  readonly
                  solo
                  underlined
                  flat
                  id="registerName"
                  class="register-name-style">
                </v-text-field>
              </v-col>
              <v-col class="1">
                <v-btn
                  outlined
                  rounded
                  @click="saveUsername"
                  class="save-username hide"
                  id="saveUsername">
                  <v-icon size="32">mdi-content-save</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="3" class="text-right">
                <v-avatar color="secondary">
                  <span class="white--text text-h5">{{ initials }}</span>
                </v-avatar>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-if="updateUsername"
                  v-model="register_name"
                  label="Name"
                  underlined
                  id="registerName">
                </v-text-field>
                <v-text-field
                  v-if="updateUsername"
                  v-model="register_last_name"
                  label="Last name"
                  underlined
                  id="registerLastName">
                </v-text-field>
                <v-text-field
                  underlined
                  readonly
                  v-model="role"
                  label="Role"
                  class="no-border">{{ role }}</v-text-field>
                <v-text-field
                  underlined
                  readonly
                  v-model="email"
                  class="no-border"
                  label="email">{{ email }}</v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <change-password class="mt-8"/>
      <Documentation-profile class="mt-8"/>
    </v-container>
  </div>
</template>
<script>
import AppHeader from '@/components/generic/AppHeader.vue';
import NavBar from '@/components/generic/NavBar.vue';
import { accountServices } from '@/services/accountServices';
import ChangePassword from '@/components/ChangePassword.vue';
import DocumentationProfile from '@/components/DocumentationProfile.vue';

export default {
  name: 'UserProfile',
  components: {
    AppHeader,
    NavBar,
    ChangePassword,
    DocumentationProfile,
  },
  data() {
    return {
      register_name: ' ',
      register_last_name: null,
      initials: ' ',
      role: '',
      email: '',
      fullName: '',
      updateUsername: false,
      alert: false,
    };
  },
  mounted() {
    this.checkUserData();
  },
  methods: {
    checkUserData() {
      accountServices
        .checkUserData()
        .then((response) => {
          this.initials = response.data.first_name.charAt(0) + response.data.last_name.charAt(0);
          this.register_name = response.data.first_name;
          this.register_last_name = response.data.last_name;
          this.fullName = `${this.register_name} ${this.register_last_name}`;
          this.role = response.data.role;
          this.email = response.data.email;
          this.id = response.data.id;
          this.initials = this.initials.toUpperCase();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateName() {
      const saveName = document.getElementById('saveUsername');
      saveName.classList.add('show');
      this.updateUsername = !this.updateUsername;
    },
    saveUsername() {
      const saveName = document.getElementById('saveUsername');
      saveName.classList.remove('show');
      this.data = {
        first_name: this.register_name,
        last_name: this.register_last_name,
        id: this.id,
      };
      accountServices.updateUsername(this.id, this.data)
        .then((response) => {
          this.alert = true;
          this.fullName = `${response.data.first_name} ${response.data.last_name}`;
          this.updateUsername = false;
          setTimeout(() => {
            this.alert = false;
          }, 2000);
        });
    },
  },
};
</script>
<style scoped>
  /* .profile-style {
    width: 900px;
    padding: 24px;
    margin: auto;
  } */
  .register-name-style {
    /* width: 30%; */
    font-size: 32px !important;
    /* margin-left: 24px; */
    text-decoration-color: white !important;
  }
  .save-username {
    /* margin-top: 6px; */
  }
  .show {
    display: flex !important;
  }
  .hide {
    display: none;
  }
  .v-btn__content {
    /* margin: 16px; */
  }
</style>
